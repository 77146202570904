import React from "react";
import firebase from "../../firebase";
import {Modal, Form, Input, Button, Icon } from "semantic-ui-react";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import md5 from "md5";



class AddEntrepreneur extends React.Component {
    state = {
        username : "",
        email : "",
        phoneNumber : "",
        loading : false
    }

    handleChange = event => this.setState({ [event.target.name] : event.target.value} );

    isFormEmpty = ({username, email, phoneNumber }) => {
        return !username.length || !email.length || !phoneNumber.length ;
    }

    isPhoneNumberValid = ({phoneNumber}) =>{
        if(phoneNumber.length !== 10){
            return false ;
        } else {
            return true ;
        }
    }

    isFormValid = () => {
        if(this.isFormEmpty(this.state)){
            alert("Tüm Bilgileri Doldurduğunuza Emin misiniz?");
            return false;
        } else if(!this.isPhoneNumberValid(this.state)){
            alert("İletişim Numarasını Doğru Yazdığınıza Emin misiniz? (Örn: 5351234567)");
            return false;
        } else {
            return true;
        }
    }

    handleSubmit = event => {
        event.preventDefault();
        let entrepreneur_id = Math.floor(Math.random() * 1000000000);
        const ID = entrepreneur_id;
        if(this.isFormValid()){
            this.setState({loading: true});
            firebase.database().ref("users/" + this.props.currentUser.uid + "/entrepreneurs/" + ID).set({
                name: this.state.username,
                avatar: "http://gravatar.com/avatar/" + md5(this.state.email) + "?d=identicon",
                phoneNumber : this.state.phoneNumber ,
                key: ID,
                email : this.state.email
            })
            .then(()=>{
                alert("Girişimci Eklendi...");
                this.props.closeModal();
                this.setState({loading: false , username : "" , email: "" , phoneNumber : ""});
            })
        }
    }

    render(){
        const {modal, closeModal} = this.props;
        const {username, email , phoneNumber } = this.state;
        return (
            <Modal basic open={modal} onClose={closeModal}>
                <Modal.Header style={{textAlign: "center"}} >Girişimci Ekle</Modal.Header>
                <Modal.Content>
                    <Form >
                        <Form.Field>
                            <Input
                                fluid
                                label = "İsim Soyisim"
                                type="text"
                                name = "username"
                                value = {username}
                                onChange={this.handleChange}
                            />
                        </Form.Field>
                        <Form.Field>
                            <Input
                                fluid
                                label = "E-Posta Adresi"
                                name = "email"
                                type="email"
                                value = {email}
                                onChange={this.handleChange}
                            />
                        </Form.Field>
                        <Form.Field>
                            <Input
                                fluid
                                label = "İletişim Numarası"
                                name = "phoneNumber"
                                type="number"
                                value = {phoneNumber}
                                onChange={this.handleChange}
                            />
                        </Form.Field>
                    </Form>
                </Modal.Content>
                <Modal.Actions>
                    <Button color="green" loading={this.state.loading} inverted onClick={this.handleSubmit}>
                        <Icon name="checkmark"/> Ekle
                    </Button>
                    <Button color="red" inverted onClick={closeModal}>
                        <Icon name="remove"/> Kapat
                    </Button>
                </Modal.Actions>
            </Modal>
        )
    }
}

const mapStateToProps = state => ({
    currentUser: state.user.currentUser
});
  
export default withRouter(connect(mapStateToProps)(AddEntrepreneur));