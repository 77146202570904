import React from "react";
import firebase from "../../firebase";
import {Modal, Form, Input, Button, Icon } from "semantic-ui-react";
import Spinner from "../Spinner";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import md5 from "md5";



class AddUser extends React.Component {
    state = {
        username : "",
        role : "" ,
        email : "",
        toogleIcon : true ,
        phoneNumber : "",
        referance : "" ,
        password : "",
        passwordConfirmation : "",
        roles: [],
        users: [] ,
        file : "" ,
        loading : false
    }

    componentDidMount(){
        firebase.database().ref("/roles").once("value").then((snapshot)=>{
            this.setState({roles: Object.values(snapshot.val())})
        });
        firebase.database().ref("/users").once("value").then((snapshot)=>{
            this.setState({users: Object.values(snapshot.val())})
        });
    }

    handleChange = event => this.setState({ [event.target.name] : event.target.value} );

    isFormEmpty = ({username, email, password, passwordConfirmation , role , phoneNumber ,referance }) => {
        return !username.length || !referance.length || !email.length || !password.length || !passwordConfirmation.length || !role.length || !phoneNumber.length ;
    }

    isPasswordValid = ({password, passwordConfirmation}) => {
        if(password.length < 6 || passwordConfirmation.length < 6 ){
            return false;
        } else if(password !== passwordConfirmation){
            return false;
        } else {
            return true;
        }
    }

    isPhoneNumberValid = ({phoneNumber}) =>{
        if(phoneNumber.length !== 10){
            return false ;
        } else {
            return true ;
        }
    }

    isFormValid = () => {
        if(this.isFormEmpty(this.state)){
            alert("Tüm Bilgileri Doldurduğunuza Emin misiniz?");
            return false;
        } else if(!this.isPasswordValid(this.state)){
            alert("Şifreyi Doğru Yazdığınıza Emin misiniz?")
            return false;
        } else if(!this.isPhoneNumberValid(this.state)){
            alert("İletişim Numarasını Doğru Yazdığınıza Emin misiniz? (Örn: 5351234567)");
            return false;
        } else {
            return true;
        }
    }

    handleSubmit = event => {
        event.preventDefault();
        if(this.isFormValid()){
            this.setState({loading: true});
            var mailAdress = this.props.currentUser.email.toString();
            var passsword = this.props.currentUser.password.toString();
            firebase.auth().createUserWithEmailAndPassword(this.state.email, this.state.password)
            .then(createduser => {
                createduser.user.updateProfile({
                    displayName : this.state.username
                })
                .then(() => {
                    this.saveUser(createduser)
                    .then(()=>{
                        firebase.auth().currentUser.sendEmailVerification()
                        .then(()=>{
                            firebase.auth().signOut()
                            .then(()=>{
                                firebase.auth().signInWithEmailAndPassword(mailAdress, passsword)
                                .then(()=>{
                                    setTimeout(()=>{
                                        this.props.history.push("/admin/users"); 
                                        alert(createduser.user.email + " adresine doğrulama mesajı gönderildi..."); 
                                    },3);                                                                                                 
                                })
                            })
                        })
                    });
                })
                .catch(err => {
                    console.log(err);
                })            
            })
            .catch(err => {
                alert("E-Posta Adresi Zaten Kullanılıyor...");
                console.error(err);
            });
        }
    }

    saveUser = createdUser => {
            const metaData = {
                contentType : "image"
            };
            const {file} = this.state;
            if(file){
                return firebase.storage().ref().child('users/' + file.lastModified.toString() + file.size.toString() + "." + file.type.replace("image/" , "") ).put(file, metaData)
                .then((snapshot)=>{
                    snapshot.ref.getDownloadURL()
                    .then((url)=>{
                        firebase.database().ref("users").child(createdUser.user.uid).set({
                            name: createdUser.user.displayName,
                            avatar: url,
                            phoneNumber : this.state.phoneNumber ,
                            referance : this.state.referance,
                            emailVerified : createdUser.user.emailVerified,
                            role: this.state.role ,
                            password: this.state.password + md5(createdUser.user.email),
                            key: createdUser.user.uid,
                            email : createdUser.user.email,
                            createdAt : new Date().toString(),
                            updatedAt : new Date().toString(), 
                            seen : true
                        });
                    })
                })
            } else {
                return firebase.database().ref("users").child(createdUser.user.uid).set({
                    name: createdUser.user.displayName,
                    avatar: "http://gravatar.com/avatar/" + md5(createdUser.user.email) + "?d=identicon",
                    emailVerified : createdUser.user.emailVerified,
                    role: this.state.role ,
                    phoneNumber : this.state.phoneNumber ,
                    referance : this.state.referance,
                    password: this.state.password + md5(createdUser.user.email),
                    key: createdUser.user.uid,
                    email : createdUser.user.email,
                    createdAt : new Date().toString(),
                    updatedAt : new Date().toString() ,
                    seen : true
                });
            }
    }

    render(){
        const {modal, closeModal} = this.props;
        const {username, role, email, password, passwordConfirmation, roles , phoneNumber , referance, users} = this.state;
        if(roles.length === 0) {
            return <Spinner/>
        }
        return (
            <Modal basic open={modal} onClose={closeModal}>
                <Modal.Header style={{textAlign: "center"}} >Kullanıcı Ekle</Modal.Header>
                <Modal.Content>
                    <Form onSubmit={this.handleSubmit}>
                        <Form.Field>
                            <Input
                                fluid
                                label = "İsim Soyisim"
                                type="text"
                                name = "username"
                                value = {username}
                                onChange={this.handleChange}
                            />
                        </Form.Field>
                        <Form.Field>
                            <Input
                                fluid
                                label = "E-Posta Adresi"
                                name = "email"
                                type="email"
                                value = {email}
                                onChange={this.handleChange}
                            />
                        </Form.Field>
                        <Form.Field>
                            <Input
                                fluid
                                label = "İletişim Numarası"
                                name = "phoneNumber"
                                type="number"
                                value = {phoneNumber}
                                onChange={this.handleChange}
                            />
                        </Form.Field>
                        <Form.Field>
                            <Input
                                fluid
                                onFocus = {()=> this.setState({toogleIcon : false})}
                                onBlur = {()=> this.setState({toogleIcon : true})}
                                type={this.state.toogleIcon ? "password" : "text"}
                                icon = {this.state.toogleIcon ? "eye slash" : "eye"}
                                label = "Şifre"
                                name = "password"
                                value = {password}
                                onChange={this.handleChange}
                            />
                        </Form.Field>
                        <Form.Field>
                            <Input
                                fluid
                                onFocus = {()=> this.setState({toogleIcon : false})}
                                onBlur = {()=> this.setState({toogleIcon : true})}
                                type={this.state.toogleIcon ? "password" : "text"}
                                icon = {this.state.toogleIcon ? "eye slash" : "eye"}
                                label = "Şifre Doğrula"
                                name = "passwordConfirmation"
                                value = {passwordConfirmation}
                                onChange={this.handleChange}
                            />
                        </Form.Field>
                        <Form.Field>
                            <select value={role} onChange={this.handleChange} name="role" >
                                <option value="" disabled>Rol Seçiniz</option>
                                {roles.map((role)=>{
                                    return <option value={role.role}>{role.displayName}</option>
                                })}
                            </select>
                        </Form.Field>
                        <Form.Field>
                            <select value={referance} onChange={this.handleChange} name="referance" >
                                <option value="" disabled>Referans Seçiniz</option>
                                <option value="Referans Yok" >Referans Yok</option>
                                <option value="Admin" >Admin</option>
                                {users.filter(child => child.role === "advisor" ).map((user)=>{
                                    return <option value={user.email}>{user.email}</option>
                                })}
                            </select>
                            { role === "admin" && referance === "" && <h5 style={{color:"aqua"}}>(Eklenen kullanıcının rolü "Yönetici" ise referans seçiminizi "Referans Yok" olarak yapınız.)</h5> }
                            { referance === "Referans Yok" && role === "advisor" && <h5 style={{color:"aqua"}}>(İş danışmanı'nın referansı var ise lütfen kendisine referans olan İş danışmanını seçiniz. Yok ise referans seçiminizi "Admin" olarak yapınız.)</h5>}
                            { referance === "Referans Yok" && role === "entrepreneur" && <h5 style={{color:"aqua"}}>(Girişimci rolündeki kullanıcıyı iş danışmanına atamak için girişimcinin referansını atamak istediğiniz İş danışmanı olarak belirleyiniz.)</h5>}
                        </Form.Field>
                        <Form.Field>
                            <Input 
                                fluid
                                type="file"   
                                accept="image/png, image/jpeg , image/webp" 
                                label="Resim Yükle" 
                                onChange={(e)=> this.setState({file : e.target.files[0]})} 
                            />
                        </Form.Field>
                    </Form>
                </Modal.Content>
                <Modal.Actions>
                    <Button color="green" loading={this.state.loading} inverted onClick={this.handleSubmit}>
                        <Icon name="checkmark"/> Ekle
                    </Button>
                    <Button color="red" inverted onClick={closeModal}>
                        <Icon name="remove"/> Kapat
                    </Button>
                </Modal.Actions>
            </Modal>
        )
    }
}

const mapStateToProps = state => ({
    currentUser: state.user.currentUser
});
  
export default withRouter(connect(mapStateToProps)(AddUser));