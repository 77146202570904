import React,{useState, useEffect} from  "react";
import {Grid, Card, Icon, Image , Button } from "semantic-ui-react";
import firebase from "../firebase";
import {Link, Route,} from "react-router-dom";
import SendMessage from "./SendMessageToAdvisor";

const Advisors = () => {
    const [advisors, setAdvisors] = useState([]);
    const [users, setUsers] = useState([]);
    const [roles, setRoles] = useState([]);

    useEffect(() => {
        firebase.database().ref("users").on("value" , (snapshot) => {
            if(snapshot.val()){
                let snap = Object.values( snapshot.val() )
                setAdvisors(snap.filter(advisor=>advisor.role === "advisor"));
                setUsers(snap);
            }
        })
    },[]);

    useEffect(() => {
        firebase.database().ref("roles").on("value", (snapshot) => {
            if(snapshot.val()){
                let snap = Object.values( snapshot.val() );
                setRoles(snap);
            }
        })
    },[]);
    
    return (
    <div className="height" style={{background: "#eee", overflowY:"auto", width:"100vw", overflowX:"hidden"}}>
      <Grid>
        <Grid.Column computer={16} tablet={16} mobile={16} textAlign="center" color="teal" style={{height:"75px"}}>
            <h1 style={{marginTop:"10px", textDecoration:"underline", textShadow:"0 0 2px black"}}>İş Danışmanlarımız</h1>
        </Grid.Column>
        {   
            advisors.map(advisor => {
                return (<Grid.Column computer={4} tablet={8} mobile={16} key={advisor.key}>
                    <Card style={{margin:"0 auto", padding:"10px"}}>
                        <Image src={advisor.avatar} wrapped ui={false} className="advisorImage" />
                        <Card.Content>
                        <Card.Header>{advisor.name}</Card.Header>
                        <Card.Meta>
                            <span >{ roles && roles.filter(child => child.role === advisor.role )[0] ? roles.filter(child => child.role === advisor.role )[0].displayName : ""}</span>
                        </Card.Meta>
                        <Card.Description>
                            <strong>Email: </strong>{advisor.email}
                            <br/>
                            <strong>Tel: </strong>{advisor.phoneNumber}
                        </Card.Description>
                        </Card.Content>
                        <Card.Content extra>
                            <Icon name='user' color="blue" />
                            {users.filter(user => user.referance === advisor.email).length} Referans
                            <Link to={"/advisors/" + advisor.key + "/sendmessage"}>
                                <Button floated="right" size="mini" color="olive">Mesaj Gönder</Button>
                            </Link>
                        </Card.Content>
                    </Card>
                </Grid.Column>);
            })
        }
        <Route path="/advisors/:advisorID/sendmessage" children={<SendMessage/>} />
      </Grid>  
    </div>
    )
}
  
export default Advisors;