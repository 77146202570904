import React,{useState, useEffect} from  "react";
import {connect} from "react-redux";
import {useHistory} from "react-router-dom";
import firebase from "../../firebase";
import {Modal, Form, Button, Icon, Input, TextArea} from "semantic-ui-react";

const FAQEdit = ({currentUser}) => {
    const history = useHistory();
    const [faqTitle, setFaqTitle] = useState("");
    const [faqContent, setFaqContent] = useState("");

    useEffect(()=>{
        if( currentUser.role !== "admin" ){
            history.push("/");
        }
    },[ currentUser.role, history ]);

    const closeModal = () => {
        history.push("/admin");
    }

    const isFormEmpty = ({ faqTitle, faqContent }) => {
        return !faqTitle || !faqContent ;
    }


    const isFormValid = () => {
        if(isFormEmpty({ faqTitle, faqContent })){
            alert("Tüm Gerekli Bilgileri Doldurduğunuza Emin misiniz?");
            return false;
        } else {
            return true;
        }
    }

    const handleSubmit = event => {
        event.preventDefault();
        if(isFormValid()){
            let faq_id = Math.floor(Math.random() * 10000000)
            firebase.database().ref("faq/" + faq_id).set({
                key: faq_id,
                question: faqTitle,
                answer : faqContent,
                createdBy : currentUser.email ,
                createdAt : new Date().toString()
            })
            .then(()=>{
                setFaqTitle("");
                setFaqContent("");
                alert("FAQ İçeriği Eklendi...");
            })
        }
    }


    return (
        <Modal basic open={true} onClose={closeModal}>
            <Modal.Header style={{textAlign: "center"}} >FAQ</Modal.Header>
            <Modal.Content>
                <Form autocomplete="off" onSubmit={handleSubmit}>
                    <Form.Field>
                        <Input
                            fluid
                            type="text"
                            name ="faqTitle"
                            placeholder='Soru...' 
                            onChange={e => setFaqTitle(e.target.value)}
                            value = {faqTitle}
                        >
                        </Input>  
                    </Form.Field>
                    <Form.Field>
                        <TextArea 
                            type="text"
                            name ="faqContent"
                            placeholder='Cevap...' 
                            onChange={e => setFaqContent(e.target.value)}
                            value = {faqContent}
                            style = {{height:"50vh", resize:"none", overflow:"auto"}}
                        >
                        </TextArea>  
                    </Form.Field>
                </Form>
            </Modal.Content>
            <Modal.Actions>
                <Button color="green" inverted onClick={handleSubmit}>
                    <Icon name="checkmark"/> Gönder
                </Button>
                <Button color="red" inverted onClick={closeModal}>
                    <Icon name="remove"/> Kapat
                </Button>
            </Modal.Actions>
        </Modal>
    )
}

const mapStateToProps = state => ({
    currentUser: state.user.currentUser
});
  
export default connect(mapStateToProps)(FAQEdit);