import React,{useState,useEffect} from "react";
import {connect} from "react-redux";
import {useHistory} from "react-router-dom";
import { Modal, Button, Icon, Form, Input, Header, Popup } from "semantic-ui-react";
import firebase from "../../firebase";



const DataTyping = ({currentUser}) => {
    const history = useHistory();
    const [projectMainName, setProjectMainName] = useState("");
    const [projectAuxiliaryName, setProjectAuxiliaryName] = useState("");
    const [projectSubAuxiliaryName, setProjectSubAuxiliaryName] = useState("");
    const [projectNames, setProjectNames] = useState([]);
    const [projectCountries, setProjectCountries] = useState("");
    const [projectStatus, setProjectStatus] = useState("");
    const [projectCurrency, setProjectCurrency] = useState("");
    const [projectPointCurrency, setProjectPointCurrency] = useState("");
    const [projectCategory, setProjectCategory] = useState("");
    const [projectStatusEdit, setProjectStatusEdit] = useState("");
    const [projectCurrencyEdit, setProjectCurrencyEdit] = useState("");
    const [projectPointCurrencyEdit, setProjectPointCurrencyEdit] = useState("");
    const [projectCategoryEdit, setProjectCategoryEdit] = useState("");
    const [countries, setCountries] = useState([]);
    const [category, setCategory] = useState([]);
    const [currency, setCurrency] = useState([]);
    const [pointCurrency, setPointCurrency] = useState([]);
    const [status, setStatus] = useState([]);
    const [projectMainNameEdit, setProjectMainNameEdit] = useState("");
    const [projectAuxiliaryNameEdit, setProjectAuxiliaryNameEdit] = useState("");
    const [projectSubAuxiliaryNameEdit, setProjectSubAuxiliaryNameEdit] = useState("");
    const [projectCountriesEdit , setProjectCountriesEdit] = useState("");

    useEffect(()=>{
        if(currentUser.role !== "admin"){
            history.push("/");
        }
    },[ currentUser.role , history ]);

    useEffect(()=>{
        firebase.database().ref("/projectNames").once("value", snapshot => {
            setProjectNames(snapshot.val());
        });
    },[projectMainName, projectAuxiliaryName, projectSubAuxiliaryName, projectMainNameEdit, projectAuxiliaryNameEdit, projectSubAuxiliaryNameEdit]);

    useEffect(()=>{
        firebase.database().ref("/countries").once("value", (snapshot)=>{
            setCountries(snapshot.val());
        });
    },[ projectCountries, projectCountriesEdit ]);

    useEffect(()=>{
        firebase.database().ref("/status").once("value", (snapshot)=>{
            setStatus(snapshot.val());
        });
    },[ projectStatus, projectStatusEdit ]);

    useEffect(()=>{
        firebase.database().ref("/currency").once("value", (snapshot)=>{
            setCurrency(snapshot.val());
        });
    },[ projectCurrency, projectCurrencyEdit ]);

    useEffect(()=>{
        firebase.database().ref("/pointCurrency").once("value", (snapshot)=>{
            setPointCurrency(snapshot.val());
        });
    },[ projectPointCurrency, projectPointCurrencyEdit ]);

    useEffect(()=>{
        firebase.database().ref("/category").once("value", (snapshot)=>{
            setCategory(snapshot.val());
        });
    },[ projectCategory, projectCategoryEdit ]);

    const closeModal = () => {
        history.push("/admin");
    }

    const isFormEmpty = ({ projectMainName, projectAuxiliaryName, projectSubAuxiliaryName}) => {
        return !projectMainName.length || !projectAuxiliaryName.length || !projectSubAuxiliaryName.length ;
    }


    const isFormValid = () => {
        if(isFormEmpty({  projectMainName, projectAuxiliaryName, projectSubAuxiliaryName })){
            alert("Tüm Gerekli Bilgileri Doldurduğunuza Emin misiniz?");
            return false;
        } else {
            return true;
        }
    }

    const handleSubmitNames = event => {
        event.preventDefault();
        if(isFormValid()){
            let projectMainNameID = Math.floor(Math.random() * 10000);
            let projectAuxiliaryNameID = Math.floor(Math.random() * 10000);
            let projectSubAuxiliaryNameID = Math.floor(Math.random() * 10000);
            firebase.database().ref("projectNames/" + projectMainName).update({
                key: projectMainNameID,
                projectMainName: projectMainName
            }).then(()=>{
                firebase.database().ref("projectNames/" + projectMainName + "/categories/" + projectAuxiliaryName).update({
                    key: projectAuxiliaryNameID,
                    projectAuxiliaryName: projectAuxiliaryName
                }).then(()=>{
                    firebase.database().ref("projectNames/" + projectMainName + "/categories/" + projectAuxiliaryName + "/subCategories/" + projectSubAuxiliaryName).update({
                        key: projectSubAuxiliaryNameID,
                        projectSubAuxiliaryName: projectSubAuxiliaryName
                    }).then(()=>{
                        setProjectMainName("");
                        setProjectAuxiliaryName("");
                        setProjectSubAuxiliaryName("");
                        setTimeout(()=>{
                            alert("Ada Göre Veri Girişi Yapıldı...");
                        },1000);
                    })
                })
            })
        }
    }

    const handleSubmitCountries = event => {
        event.preventDefault();
        if(projectCountries){
            firebase.database().ref("countries/" + projectCountries).update({
                key: Math.floor(Math.random() * 10000),
                text: projectCountries,
                value: projectCountries
            })
            .then(()=>{
                setProjectCountries("");
                alert("Geçerli Ülke Verisi Eklendi...");
            })
        } else {
            alert("Lütfen Geçerli Bir Ülke Verisi Giriniz...");
        }
    }

    const handleSubmitStatus = event => {
        event.preventDefault();
        if(projectStatus){
            firebase.database().ref("status/" + projectStatus).update({
                key: Math.floor(Math.random() * 10000),
                text: projectStatus,
                value: projectStatus
            })
            .then(()=>{
                setProjectStatus("");
                alert("Statü Verisi Eklendi...");
            })
        } else {
            alert("Lütfen Bir Statü Verisi Giriniz...");
        }
    }

    const handleSubmitCurrency = event => {
        event.preventDefault();
        if(projectCurrency){
            firebase.database().ref("currency/" + projectCurrency).update({
                key: Math.floor(Math.random() * 10000),
                value: projectCurrency
            })
            .then(()=>{
                setProjectCurrency("");
                alert("Para Birimi Verisi Eklendi...");
            })
        } else {
            alert("Lütfen Bir Para Birimi Verisi Giriniz...");
        }
    }

    const handleSubmitPointCurrency = event => {
        event.preventDefault();
        if(projectPointCurrency){
            firebase.database().ref("pointCurrency/" + projectPointCurrency).update({
                key: Math.floor(Math.random() * 10000),
                value: projectPointCurrency
            })
            .then(()=>{
                setProjectPointCurrency("");
                alert("Puan Birimi Verisi Eklendi...");
            })
        } else {
            alert("Lütfen Bir Puan Birimi Verisi Giriniz...");
        }
    }

    const handleSubmitCategory = event => {
        event.preventDefault();
        if(projectCategory){
            firebase.database().ref("category/" + projectCategory).update({
                key: Math.floor(Math.random() * 10000),
                value: projectCategory
            })
            .then(()=>{
                setProjectCategory("");
                alert("Kategori Verisi Eklendi...");
            })
        } else {
            alert("Lütfen Bir Kategori Verisi Giriniz...");
        }
    }

    const handleEditProjectMainName = () => {
        if(projectMainNameEdit){
            firebase.database().ref("projectNames/" + projectMainName).once("value", (snapshot)=>{
                if(snapshot.val()){
                    const projectMainNameValues = snapshot.val();
                    firebase.database().ref("projectNames/" + projectMainName).remove()
                    .then(()=>{
                        firebase.database().ref("projectNames/" + projectMainNameEdit).update(projectMainNameValues)
                        .then(()=>{
                            firebase.database().ref("projectNames/" + projectMainNameEdit).update({
                                projectMainName: projectMainNameEdit
                            })
                            .then(()=>{
                                firebase.database().ref("projects").orderByChild("projectMainName").equalTo(projectMainName).once("value", (snap)=>{
                                    snap.forEach(project =>{
                                        project.ref.update({
                                            projectMainName: projectMainNameEdit
                                        })
                                    })
                                })
                                .then(()=>{
                                    setProjectMainName(projectMainNameEdit);
                                    setProjectMainNameEdit("");
                                    alert("Proje Ana Ad Verisi Güncellendi...");
                                })
                            })
                        })
                    })
                }
            })
        } else {
            alert("Lütfen Güncellemek İstediğiniz Değeri Giriniz...");
        }
    }

    const handleEditProjectAuxiliaryName = () =>{
        if(projectAuxiliaryNameEdit){
            firebase.database().ref("projectNames/" + projectMainName + "/categories/" + projectAuxiliaryName).once("value", (snapshot)=>{
                if(snapshot.val()){
                    const projectAuxiliaryNameValues = snapshot.val();
                    firebase.database().ref("projectNames/" + projectMainName + "/categories/" + projectAuxiliaryName).remove()
                    .then(()=>{
                        firebase.database().ref("projectNames/" + projectMainName + "/categories/" + projectAuxiliaryNameEdit).update(projectAuxiliaryNameValues)
                        .then(()=>{
                            firebase.database().ref("projectNames/" + projectMainName + "/categories/" + projectAuxiliaryNameEdit).update({
                                projectAuxiliaryName : projectAuxiliaryNameEdit
                            })
                            .then(()=>{
                                firebase.database().ref("projects").orderByChild("projectAuxiliaryName").equalTo(projectAuxiliaryName).once("value", (snap)=>{
                                    snap.forEach(project =>{
                                        project.ref.update({
                                            projectAuxiliaryName: projectAuxiliaryNameEdit
                                        })
                                    })
                                })
                                .then(()=>{
                                    setProjectAuxiliaryName(projectAuxiliaryNameEdit);
                                    setProjectAuxiliaryNameEdit("");
                                    alert("Proje Yardımcı Ad Verisi Güncellendi...");
                                })
                            })
                        })
                    })
                }
            })
        } else {
            alert("Lütfen Güncellemek İstediğiniz Değeri Giriniz...");
        }
    }

    const handleEditProjectSubAuxiliaryName = () => {
        if(projectSubAuxiliaryNameEdit){
            firebase.database().ref("projectNames/" + projectMainName + "/categories/" + projectAuxiliaryName + "/subCategories/" + projectSubAuxiliaryName).once("value", (snapshot)=>{
                if(snapshot.val()){
                    const projectSubAuxiliaryNameValues = snapshot.val();
                    firebase.database().ref("projectNames/" + projectMainName + "/categories/" + projectAuxiliaryName + "/subCategories/" + projectSubAuxiliaryName).remove()
                    .then(()=>{
                        firebase.database().ref("projectNames/" + projectMainName + "/categories/" + projectAuxiliaryName + "/subCategories/" + projectSubAuxiliaryNameEdit).update(projectSubAuxiliaryNameValues)
                        .then(()=>{
                            firebase.database().ref("projectNames/" + projectMainName + "/categories/" + projectAuxiliaryName + "/subCategories/" + projectSubAuxiliaryNameEdit).update({
                                projectSubAuxiliaryName : projectSubAuxiliaryNameEdit
                            })
                            .then(()=>{
                                firebase.database().ref("projects").orderByChild("projectSubAuxiliaryName").equalTo(projectSubAuxiliaryName).once("value", (snap)=>{
                                    snap.forEach(project =>{
                                        project.ref.update({
                                            projectSubAuxiliaryName: projectSubAuxiliaryNameEdit
                                        })
                                    })
                                })
                                .then(()=>{
                                    setProjectSubAuxiliaryName(projectSubAuxiliaryNameEdit);
                                    setProjectSubAuxiliaryNameEdit("");
                                    alert("Proje Alt Yardımcı Ad Verisi Güncellendi...");
                                })
                            })
                        })
                    })
                }
            })
        } else {
            alert("Lütfen Güncellemek İstediğiniz Değeri Giriniz...");
        }
    }

    const handleEditProjectCountries = () => {
        if(projectCountriesEdit){
            firebase.database().ref("countries/" + projectCountries).once("value", (snapshot)=>{
                if(snapshot.val()){
                    const projectCountryValues = snapshot.val();
                    firebase.database().ref("countries/" + projectCountries).remove()
                    .then(()=>{
                        firebase.database().ref("countries/" + projectCountriesEdit).update(projectCountryValues)
                        .then(()=>{
                            firebase.database().ref("countries/" + projectCountriesEdit).update({
                                value: projectCountriesEdit ,
                                text : projectCountriesEdit
                            })
                            .then(()=>{
                                firebase.database().ref("projects").orderByChild("validCityCountry/" + projectCountries + "/countryValue").equalTo(projectCountries).once("value", (snap)=>{
                                    snap.forEach(project =>{
                                        project.ref.child("validCityCountry/" + projectCountries).once("value", child => {
                                            let country = child.val();
                                            project.ref.child("validCityCountry/" + projectCountries).remove()
                                            .then(()=>{
                                                project.ref.child("validCityCountry/" + projectCountriesEdit).update(country)
                                                .then(()=>{
                                                    project.ref.child("validCityCountry/" + projectCountriesEdit).update({
                                                        countryValue : projectCountriesEdit
                                                    })
                                                })
                                            })
                                        })
                                    })
                                })
                                .then(()=>{
                                    setProjectCountries(projectCountriesEdit);
                                    setProjectCountriesEdit("");
                                    alert("Proje Ülke Verisi Güncellendi...");
                                })
                            })
                        })
                    })
                }
            })
        } else {
            alert("Lütfen Güncellemek İstediğiniz Değeri Giriniz...");
        }
    }

    const handleEditProjectStatus = () => {
        if(projectStatusEdit){
            firebase.database().ref("status/" + projectStatus).once("value", (snapshot)=>{
                if(snapshot.val()){
                    const projectStatusValues = snapshot.val();
                    firebase.database().ref("status/" + projectStatus).remove()
                    .then(()=>{
                        firebase.database().ref("status/" + projectStatusEdit).update(projectStatusValues)
                        .then(()=>{
                            firebase.database().ref("status/" + projectStatusEdit).update({
                                value: projectStatusEdit ,
                                text : projectStatusEdit
                            })
                            .then(()=>{
                                firebase.database().ref("projects").orderByChild( "projectStatus/" + projectStatus + "/statusValue").equalTo(projectStatus).once("value", (snap)=>{
                                    snap.forEach(project =>{
                                        project.ref.child("projectStatus/" + projectStatus).once("value", child => {
                                            let status = child.val();
                                            project.ref.child("projectStatus/" + projectStatus).remove()
                                            .then(()=>{
                                                project.ref.child("projectStatus/" + projectStatusEdit).update(status)
                                                .then(()=>{
                                                    project.ref.child("projectStatus/" + projectStatusEdit).update({
                                                        statusValue : projectStatusEdit
                                                    })
                                                })
                                            })
                                        })
                                    })
                                })
                                .then(()=>{
                                    setProjectStatus(projectStatusEdit);
                                    setProjectStatusEdit("");
                                    alert("Proje Statü Verisi Güncellendi...");
                                })
                            })
                        })
                    })
                }
            })
        } else {
            alert("Lütfen Güncellemek İstediğiniz Değeri Giriniz...");
        }
    }

    const handleEditProjectCurrency = () => {
        if(projectCurrencyEdit){
            firebase.database().ref("currency/" + projectCurrency).once("value", (snapshot)=>{
                if(snapshot.val()){
                    const projectCurrencyValues = snapshot.val();
                    firebase.database().ref("currency/" + projectCurrency).remove()
                    .then(()=>{
                        firebase.database().ref("currency/" + projectCurrencyEdit).update(projectCurrencyValues)
                        .then(()=>{
                            firebase.database().ref("currency/" + projectCurrencyEdit).update({
                                value: projectCurrencyEdit
                            })
                            .then(()=>{
                                firebase.database().ref("projects").orderByChild("projectCurrency").equalTo(projectCurrency).once("value", (snap)=>{
                                    snap.forEach(project =>{
                                        project.ref.update({
                                            projectCurrency: projectCurrencyEdit
                                        })
                                    })
                                })
                                .then(()=>{
                                    setProjectCurrency(projectCurrencyEdit);
                                    setProjectCurrencyEdit("");
                                    alert("Para Birimi Verisi Güncellendi...");
                                })
                            })
                        })
                    })
                }
            })
        } else {
            alert("Lütfen Güncellemek İstediğiniz Değeri Giriniz...");
        }
    }

    const handleEditProjectPointCurrency = () => {
        if(projectPointCurrencyEdit){
            firebase.database().ref("pointCurrency/" + projectPointCurrency).once("value", (snapshot)=>{
                if(snapshot.val()){
                    const projectPointCurrencyValues = snapshot.val();
                    firebase.database().ref("pointCurrency/" + projectPointCurrency).remove()
                    .then(()=>{
                        firebase.database().ref("pointCurrency/" + projectPointCurrencyEdit).update(projectPointCurrencyValues)
                        .then(()=>{
                            firebase.database().ref("pointCurrency/" + projectPointCurrencyEdit).update({
                                value: projectPointCurrencyEdit
                            })
                            .then(()=>{
                                firebase.database().ref("projects").orderByChild("projectPointCurrency").equalTo(projectPointCurrency).once("value", (snap)=>{
                                    snap.forEach(project =>{
                                        project.ref.update({
                                            projectPointCurrency: projectPointCurrencyEdit
                                        })
                                    })
                                })
                                .then(()=>{
                                    setProjectPointCurrency(projectCurrencyEdit);
                                    setProjectPointCurrencyEdit("");
                                    alert("Puan Birimi Verisi Güncellendi...");
                                })
                            })
                        })
                    })
                }
            })
        } else {
            alert("Lütfen Güncellemek İstediğiniz Değeri Giriniz...");
        }
    }

    const handleEditProjectCategory = () => {
        if(projectCategoryEdit){
            firebase.database().ref("category/" + projectCategory).once("value", (snapshot)=>{
                if(snapshot.val()){
                    const projectCategoryValues = snapshot.val();
                    firebase.database().ref("category/" + projectCategory).remove()
                    .then(()=>{
                        firebase.database().ref("category/" + projectCategoryEdit).update(projectCategoryValues)
                        .then(()=>{
                            firebase.database().ref("category/" + projectCategoryEdit).update({
                                value: projectCategoryEdit
                            })
                            .then(()=>{
                                firebase.database().ref("projects").orderByChild("projectCategory").equalTo(projectCategory).once("value", (snap)=>{
                                    snap.forEach(project =>{
                                        project.ref.update({
                                            projectCategory: projectCategoryEdit
                                        })
                                    })
                                })
                                .then(()=>{
                                    setProjectCategory(projectCategoryEdit);
                                    setProjectCategoryEdit("");
                                    alert("Kategori Verisi Güncellendi...");
                                })
                            })
                        })
                    })
                }
            })
        } else {
            alert("Lütfen Güncellemek İstediğiniz Değeri Giriniz...");
        }
    }

    useEffect(()=>{
        setProjectAuxiliaryName("");
        setProjectSubAuxiliaryName("");
    },[projectMainName]);
  
    useEffect(()=>{
        setProjectSubAuxiliaryName("");
    },[projectAuxiliaryName]);

    const categories = projectMainName && projectNames && Object.values(projectNames).filter(projectNames=> projectNames.projectMainName === projectMainName)[0] ? Object.values(Object.values(projectNames).filter(projectNames=> projectNames.projectMainName === projectMainName)[0].categories) : "" ;
    const subCategories = projectMainName && projectNames && projectAuxiliaryName && Object.values(projectNames).filter(projectNames=> projectNames.projectMainName === projectMainName)[0] && Object.values(Object.values(projectNames).filter(projectNames=> projectNames.projectMainName === projectMainName)[0].categories).filter(child => child.projectAuxiliaryName === projectAuxiliaryName)[0] ? Object.values(Object.values(Object.values(projectNames).filter(projectNames=> projectNames.projectMainName === projectMainName)[0].categories).filter(child => child.projectAuxiliaryName === projectAuxiliaryName)[0].subCategories) : "" ;   

    return (
        <Modal basic open={true} onClose={closeModal}>
            <Modal.Header style={{textAlign: "center"}}>Veri Girişi</Modal.Header>
            <hr/>
            <br/>
            <Modal.Content>
                <Form autocomplete="off" style={{border:"1px dashed rgba(200, 200, 200, 0.3)",borderRadius:"10px", padding:"20px"}}>
                    <Header style={{textAlign: "center", color:"salmon",textDecoration:"underline"}}>Ada Göre Veri Girişi</Header>
                    <Form.Field>
                        <Input
                            fluid
                            label = "Proje Ana Ad"
                            type="text"
                            name = "projectMainName"
                            value = {projectMainName}
                            onChange={e => setProjectMainName(e.target.value.replace("/", "").replace('"', "").replace("'","").replace("[","").replace("]","").replace("#","").replace("$","").replace("|","").replace("&","").replace("{","").replace("}",""))}
                            list="projectMainNames"
                        />
                        { projectNames && Object.values(projectNames).filter(projectNames=> projectNames.projectMainName === projectMainName)[0] ? <Popup
                            content={
                                <div>
                                    <input
                                        autocomplete="off"
                                        type="text"
                                        required
                                        placeholder={projectMainName}
                                        name="projectMainNameEdit"
                                        value = {projectMainNameEdit}
                                        onChange={e => setProjectMainNameEdit(e.target.value.replace("/", "").replace('"', "").replace("'","").replace("[","").replace("]","").replace("#","").replace("$","").replace("|","").replace("&","").replace("{","").replace("}",""))}
                                        style={{marginBottom:"10px"}}
                                    />
                                    <Button size="mini" color="green" onClick={handleEditProjectMainName} >Düzenle</Button>
                                </div>
                            } on='click' trigger={ <p style={{cursor:"pointer"}}>{projectMainName} <Icon color="green" name="edit" /></p>} /> : <p style={{height:"1.4275em"}}></p>}
                        <datalist id='projectMainNames'>
                        {projectNames ? Object.values(projectNames).map((child)=>{
                            return (<option key={child.key} value={child.projectMainName}>{child.projectMainName}</option>)
                        }) : ""}
                        </datalist>
                    </Form.Field>
                    { projectMainName ? <Form.Field>
                        <Input
                            fluid
                            label = "Proje Yardımcı Ad"
                            name = "projectAuxiliaryName"
                            type="text"
                            value = {projectAuxiliaryName}
                            onChange={e => setProjectAuxiliaryName(e.target.value.replace("/", "").replace('"', "").replace("'","").replace("[","").replace("]","").replace("#","").replace("$","").replace("|","").replace("&","").replace("{","").replace("}",""))}
                            list="projectAuxiliaryNames"
                        />
                        { projectNames && Object.values(projectNames).filter(projectNames=> projectNames.projectMainName === projectMainName)[0] && Object.values(Object.values(projectNames).filter(projectNames=> projectNames.projectMainName === projectMainName)[0].categories).filter(child => child.projectAuxiliaryName === projectAuxiliaryName)[0] ? <Popup
                            content={
                                <div>
                                    <input
                                        autocomplete="off"
                                        type="text"
                                        required
                                        placeholder={projectAuxiliaryName}
                                        name="projectAuxiliaryNameEdit"
                                        value = {projectAuxiliaryNameEdit}
                                        onChange={e => setProjectAuxiliaryNameEdit(e.target.value.replace("/", "").replace('"', "").replace("'","").replace("[","").replace("]","").replace("#","").replace("$","").replace("|","").replace("&","").replace("{","").replace("}",""))}
                                        style={{marginBottom:"10px"}}
                                    />
                                    <Button size="mini" color="green" onClick={handleEditProjectAuxiliaryName} >Düzenle</Button>
                                </div>
                            } on='click' trigger={ <p style={{cursor:"pointer"}}>{projectAuxiliaryName} <Icon color="green" name="edit" /></p>} /> : <p style={{height:"1.4275em"}}></p>}
                        <datalist id='projectAuxiliaryNames'>
                        {projectNames && projectMainName && Object.values(projectNames).filter(projectNames=> projectNames.projectMainName === projectMainName)[0] ? categories.map((child)=>{
                            return (<option key={child.key} value={child.projectAuxiliaryName}>{child.projectAuxiliaryName}</option>)
                        }) : ""}
                        </datalist>
                    </Form.Field> : ""}
                    { projectMainName && projectAuxiliaryName ? <Form.Field>
                        <Input
                            fluid
                            label = "Proje Alt Yardımcı Ad"
                            name = "projectSubAuxiliaryName"
                            type="text"
                            value = {projectSubAuxiliaryName}
                            onChange={e => setProjectSubAuxiliaryName(e.target.value.replace("/", "").replace('"', "").replace("'","").replace("[","").replace("]","").replace("#","").replace("$","").replace("|","").replace("&","").replace("{","").replace("}",""))}
                            list="projectSubAuxiliaryNames"
                        />
                        { projectNames && Object.values(projectNames).filter(projectNames=> projectNames.projectMainName === projectMainName)[0] && Object.values(Object.values(projectNames).filter(projectNames=> projectNames.projectMainName === projectMainName)[0].categories).filter(child => child.projectAuxiliaryName === projectAuxiliaryName)[0] && Object.values(Object.values(Object.values(projectNames).filter(projectNames=> projectNames.projectMainName === projectMainName)[0].categories).filter(child => child.projectAuxiliaryName === projectAuxiliaryName)[0].subCategories).filter(child => child.projectSubAuxiliaryName === projectSubAuxiliaryName)[0] ? <Popup
                            content={
                                <div>
                                    <input
                                        autocomplete="off"
                                        type="text"
                                        required
                                        placeholder={projectSubAuxiliaryName}
                                        name="projectSubAuxiliaryNameEdit"
                                        value = {projectSubAuxiliaryNameEdit}
                                        onChange={e => setProjectSubAuxiliaryNameEdit(e.target.value.replace("/", "").replace('"', "").replace("'","").replace("[","").replace("]","").replace("#","").replace("$","").replace("|","").replace("&","").replace("{","").replace("}",""))}
                                        style={{marginBottom:"10px"}}
                                    />
                                    <Button size="mini" color="green" onClick={handleEditProjectSubAuxiliaryName} >Düzenle</Button>
                                </div>
                            } on='click' trigger={ <p style={{cursor:"pointer"}}>{projectSubAuxiliaryName} <Icon color="green" name="edit" /></p>} /> : <p style={{height:"1.4275em"}}></p>}
                        <datalist id='projectSubAuxiliaryNames'>
                        {projectNames && projectMainName && projectAuxiliaryName && Object.values(projectNames).filter(projectNames=> projectNames.projectMainName === projectMainName)[0] && Object.values(Object.values(projectNames).filter(projectNames=> projectNames.projectMainName === projectMainName)[0].categories).filter(child => child.projectAuxiliaryName === projectAuxiliaryName)[0] ? subCategories.map((child)=>{
                            return (<option key={child.key} value={child.projectSubAuxiliaryName}>{child.projectSubAuxiliaryName}</option>)
                        }) : ""}
                        </datalist>
                    </Form.Field> : ""}
                    <Form.Field>
                        <Button size="mini" color="green" inverted onClick={handleSubmitNames}>
                            <Icon name="checkmark"/> Ekle
                        </Button>
                    </Form.Field>
                </Form>
                <br/>
                <Form autocomplete="off" style={{border:"1px dashed rgba(200, 200, 200, 0.3)",borderRadius:"10px", padding:"20px"}}>
                    <Header style={{textAlign: "center", color:"salmon",textDecoration:"underline"}}>Geçerli Ülkeler Veri Girişi</Header>
                    <Form.Field>
                        <Input
                            fluid
                            label = "Geçerli Ülkeler"
                            type="text"
                            name = "projectCountries"
                            value = {projectCountries}
                            onChange={e => setProjectCountries(e.target.value.replace("/", "").replace('"', "").replace("'","").replace("[","").replace("]","").replace("#","").replace("$","").replace("|","").replace("&","").replace("{","").replace("}",""))}
                            list="projectCountries"
                        />
                        { countries && Object.values(countries).filter(child=> child.value === projectCountries)[0] ? <Popup
                            content={
                                <div>
                                    <input
                                        autocomplete="off"
                                        type="text"
                                        required
                                        placeholder={projectCountries}
                                        name="projectCountriesEdit"
                                        value = {projectCountriesEdit}
                                        onChange={e => setProjectCountriesEdit(e.target.value.replace("/", "").replace('"', "").replace("'","").replace("[","").replace("]","").replace("#","").replace("$","").replace("|","").replace("&","").replace("{","").replace("}",""))}
                                        style={{marginBottom:"10px"}}
                                    />
                                    <Button size="mini" color="green" onClick={handleEditProjectCountries} >Düzenle</Button>
                                </div>
                            } on='click' trigger={ <p style={{cursor:"pointer"}}>{projectCountries} <Icon color="green" name="edit" /></p>} /> : <p style={{height:"1.4275em"}}></p>}
                        <datalist id='projectCountries'>
                            {countries ? Object.values(countries).map((child)=>{
                                return (
                                    <option key={child.key} value={child.value} >{child.text}</option>
                                )
                            }) : ""}
                        </datalist>
                    </Form.Field>
                    <Form.Field>
                        <Button size="mini" color="green" inverted onClick={handleSubmitCountries}>
                            <Icon name="checkmark"/> Ekle
                        </Button>
                    </Form.Field>
                </Form>
                <br/>
                <Form autocomplete="off" style={{border:"1px dashed rgba(200, 200, 200, 0.3)",borderRadius:"10px", padding:"20px"}}>
                    <Header style={{textAlign: "center", color:"salmon",textDecoration:"underline"}}>Statü Veri Girişi</Header>
                    <Form.Field>
                        <Input
                            fluid
                            label = "Statüler"
                            type="text"
                            name = "projectStatus"
                            value = {projectStatus}
                            onChange={e => setProjectStatus(e.target.value.replace("/", "").replace('"', "").replace("'","").replace("[","").replace("]","").replace("#","").replace("$","").replace("|","").replace("&","").replace("{","").replace("}",""))}
                            list="projectStatus"
                        />
                        { status && Object.values(status).filter(child=> child.value === projectStatus)[0] ? <Popup
                            content={
                                <div>
                                    <input
                                        autocomplete="off"
                                        type="text"
                                        required
                                        placeholder={projectStatus}
                                        name="projectStatusEdit"
                                        value = {projectStatusEdit}
                                        onChange={e => setProjectStatusEdit(e.target.value.replace("/", "").replace('"', "").replace("'","").replace("[","").replace("]","").replace("#","").replace("$","").replace("|","").replace("&","").replace("{","").replace("}",""))}
                                        style={{marginBottom:"10px"}}
                                    />
                                    <Button size="mini" color="green" onClick={handleEditProjectStatus} >Düzenle</Button>
                                </div>
                            } on='click' trigger={ <p style={{cursor:"pointer"}}>{projectStatus} <Icon color="green" name="edit" /></p>} /> : <p style={{height:"1.4275em"}}></p>}
                        <datalist id='projectStatus'>
                            {status ? Object.values(status).map((child)=>{
                                return (
                                    <option key={child.key} value={child.value} >{child.text}</option>
                                )
                            }) : ""}
                        </datalist>
                    </Form.Field>
                    <Form.Field>
                        <Button size="mini" color="green" inverted onClick={handleSubmitStatus}>
                            <Icon name="checkmark"/> Ekle
                        </Button>
                    </Form.Field>
                </Form>
                <br/>
                <Form autocomplete="off" style={{border:"1px dashed rgba(200, 200, 200, 0.3)",borderRadius:"10px", padding:"20px"}}>
                    <Header style={{textAlign: "center", color:"salmon",textDecoration:"underline"}}>Para Birimi Veri Girişi</Header>
                    <Form.Field>
                        <Input
                            fluid
                            label = "Para Birimleri"
                            type="text"
                            name = "projectCurrency"
                            value = {projectCurrency}
                            onChange={e => setProjectCurrency(e.target.value.replace("/", "").replace('"', "").replace("'","").replace("[","").replace("]","").replace("#","").replace("$","").replace("|","").replace("&","").replace("{","").replace("}",""))}
                            list="projectCurrency"
                        />
                        { currency && Object.values(currency).filter(child=> child.value === projectCurrency)[0] ? <Popup
                            content={
                                <div>
                                    <input
                                        autocomplete="off"
                                        type="text"
                                        required
                                        placeholder={projectCurrency}
                                        name="projectCurrencyEdit"
                                        value = {projectCurrencyEdit}
                                        onChange={e => setProjectCurrencyEdit(e.target.value.replace("/", "").replace('"', "").replace("'","").replace("[","").replace("]","").replace("#","").replace("$","").replace("|","").replace("&","").replace("{","").replace("}",""))}
                                        style={{marginBottom:"10px"}}
                                    />
                                    <Button size="mini" color="green" onClick={handleEditProjectCurrency} >Düzenle</Button>
                                </div>
                            } on='click' trigger={ <p style={{cursor:"pointer"}}>{projectCurrency} <Icon color="green" name="edit" /></p>} /> : <p style={{height:"1.4275em"}}></p>}
                        <datalist id='projectCurrency'>
                            {currency ? Object.values(currency).map((child)=>{
                                return (
                                    <option key={child.key} value={child.value} >{child.value}</option>
                                )
                            }) : ""}
                        </datalist>
                    </Form.Field>
                    <Form.Field>
                        <Button size="mini" color="green" inverted onClick={handleSubmitCurrency}>
                            <Icon name="checkmark"/> Ekle
                        </Button>
                    </Form.Field>
                </Form>
                <br/>
                <Form autocomplete="off" style={{border:"1px dashed rgba(200, 200, 200, 0.3)",borderRadius:"10px", padding:"20px"}}>
                    <Header style={{textAlign: "center", color:"salmon",textDecoration:"underline"}}>Puan Birimi Veri Girişi</Header>
                    <Form.Field>
                        <Input
                            fluid
                            label = "Puan Birimleri"
                            type="text"
                            name = "projectPointCurrency"
                            value = {projectPointCurrency}
                            onChange={e => setProjectPointCurrency(e.target.value.replace("/", "").replace('"', "").replace("'","").replace("[","").replace("]","").replace("#","").replace("$","").replace("|","").replace("&","").replace("{","").replace("}",""))}
                            list="projectPointCurrency"
                        />
                        { pointCurrency && Object.values(pointCurrency).filter(child=> child.value === projectPointCurrency)[0] ? <Popup
                            content={
                                <div>
                                    <input
                                        autocomplete="off"
                                        type="text"
                                        required
                                        placeholder={projectPointCurrency}
                                        name="projectPointCurrencyEdit"
                                        value = {projectPointCurrencyEdit}
                                        onChange={e => setProjectPointCurrencyEdit(e.target.value.replace("/", "").replace('"', "").replace("'","").replace("[","").replace("]","").replace("#","").replace("$","").replace("|","").replace("&","").replace("{","").replace("}",""))}
                                        style={{marginBottom:"10px"}}
                                    />
                                    <Button size="mini" color="green" onClick={handleEditProjectPointCurrency} >Düzenle</Button>
                                </div>
                            } on='click' trigger={ <p style={{cursor:"pointer"}}>{projectPointCurrency} <Icon color="green" name="edit" /></p>} /> : <p style={{height:"1.4275em"}}></p>}
                        <datalist id='projectPointCurrency'>
                            {pointCurrency ? Object.values(pointCurrency).map((child)=>{
                                return (
                                    <option key={child.key} value={child.value} >{child.value}</option>
                                )
                            }) : ""}
                        </datalist>
                    </Form.Field>
                    <Form.Field>
                        <Button size="mini" color="green" inverted onClick={handleSubmitPointCurrency}>
                            <Icon name="checkmark"/> Ekle
                        </Button>
                    </Form.Field>
                </Form>
                <br/>
                <Form autocomplete="off" style={{border:"1px dashed rgba(200, 200, 200, 0.3)",borderRadius:"10px", padding:"20px"}}>
                    <Header style={{textAlign: "center", color:"salmon",textDecoration:"underline"}}>Kategori Veri Girişi</Header>
                    <Form.Field>
                        <Input
                            fluid
                            label = "Kategoriler"
                            type="text"
                            name = "projectCategory"
                            value = {projectCategory}
                            onChange={e => setProjectCategory(e.target.value.replace("/", "").replace('"', "").replace("'","").replace("[","").replace("]","").replace("#","").replace("$","").replace("|","").replace("&","").replace("{","").replace("}",""))}
                            list="projectCategory"
                        />
                        { category && Object.values(category).filter(child=> child.value === projectCategory)[0] ? <Popup
                            content={
                                <div>
                                    <input
                                        autocomplete="off"
                                        type="text"
                                        required
                                        placeholder={projectCategory}
                                        name="projectCategoryEdit"
                                        value = {projectCategoryEdit}
                                        onChange={e => setProjectCategoryEdit(e.target.value.replace("/", "").replace('"', "").replace("'","").replace("[","").replace("]","").replace("#","").replace("$","").replace("|","").replace("&","").replace("{","").replace("}",""))}
                                        style={{marginBottom:"10px"}}
                                    />
                                    <Button size="mini" color="green" onClick={handleEditProjectCategory} >Düzenle</Button>
                                </div>
                            } on='click' trigger={ <p style={{cursor:"pointer"}}>{projectCategory} <Icon color="green" name="edit" /></p>} /> : <p style={{height:"1.4275em"}}></p>}
                        <datalist id='projectCategory'>
                            {category ? Object.values(category).map((child)=>{
                                return (
                                    <option key={child.key} value={child.value} >{child.value}</option>
                                )
                            }) : ""}
                        </datalist>
                    </Form.Field>
                    <Form.Field>
                        <Button size="mini" color="green" inverted onClick={handleSubmitCategory}>
                            <Icon name="checkmark"/> Ekle
                        </Button>
                    </Form.Field>
                </Form>
            </Modal.Content>
            <Modal.Actions>
                <Button color="red" inverted onClick={closeModal}>
                    <Icon name="remove"/> Kapat
                </Button>
            </Modal.Actions>
        </Modal>
    );
}

const mapStateToProps = state => ({
    currentUser: state.user.currentUser
});
  
export default connect(mapStateToProps)(DataTyping);