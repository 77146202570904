import React, {useState, useEffect} from "react";
import {connect} from "react-redux";
import {
    Grid,
    Icon,
    Menu,
    Sidebar
} from 'semantic-ui-react';
import {Link, Route , useLocation, useHistory, useParams} from "react-router-dom";
import UserPanel from "../UserPanel";
import UpdateAccount from "./UpdateAccount";
import AdvisorMessages from "./Messages";
import Notifications from "./Notifications";
import Projects from "./Projects";
import DoneProjects from "./DoneProjects";
import AdvisorsWeb from "./AdvisorsWeb";
import UpdateBackgroundImage from "./UpdateBackgroundImage";
import Entrepreneurs from "./Entrepreneurs";
import firebase from "../../firebase";

const AdvisorAccount = ({currentUser}) => {
  const [visible, setVisible] = useState(true);
  const [messages, setMessages] = useState([]);
  const [notification, setNotification] = useState([]);
  const [projects, setProjects] = useState([]);
  const [backgroundImage, setBackgroundImage] = useState("");
  const [price , setPrice] = useState("");
  const [point , setPoint] = useState("");
  const location = useLocation();
  const history = useHistory();
  const {user_id} = useParams();

  useEffect(()=>{
    if(currentUser.uid !== user_id || currentUser.role !== "advisor"){
      history.push("/");
    }
  });

  useEffect(() => {
    firebase.database().ref("backgroundImage/" + user_id).once("value", snapshot => {
        let background = snapshot.val();
        if(background){
            setBackgroundImage(background.backgroundImage)
        }
    })
  }, [location , user_id]);

  useEffect(()=>{
    firebase.database().ref("users/" + user_id + "/messages").on("value" , (snapshot) => {
        if(snapshot.val()){
            let snap = Object.values( snapshot.val() );
            let message = snap.filter(child => child.role !== "admin");
            setMessages(message);
        }
    })
  },[user_id]);

  useEffect(()=>{
    firebase.database().ref("users/" + user_id + "/messages").on("value" , (snapshot) => {
        if(snapshot.val()){
            let snap = Object.values( snapshot.val() );
            let message = snap.filter(child => child.role === "admin");
            setNotification(message);
        }
    })
  },[user_id]);

  useEffect(() => {
    firebase.database().ref("users/" + currentUser.uid ).on("value" , snapshot=> {
      if(snapshot.val()){
        let snap = snapshot.val();
        if(snap.price){
          setPrice(snap.price);
        }
        if(snap.point){
          setPoint(snap.point);
        }
      }
    })
  }, [ currentUser.uid ]);

  useEffect(() => {
    firebase.database().ref("projects").orderByChild("advisor").equalTo(currentUser.email).on("value", snapshot => {
      if(snapshot.val()){
        let snap = Object.values(snapshot.val());
        setProjects(snap);
      }
    })
  }, [ currentUser.email ]);

  const unReadMessagesLength = messages.filter(child => child.read === false).length ;
  const unReadNotificationsLength = notification.filter(child => child.read === false).length ;
  const unSeenProjectsLength = projects.filter(child => child.seen === false && child.done === false ).length ;
  const unSeenDoneProjectsLength = projects.filter(child => child.seen === false && child.done === true ).length ;

        return (
            <Grid columns={1} style={{background:"rgba(200,200,255,0.8)"}} >
              <Icon
                    name={visible ? "angle double left" : "angle double right"} 
                    color={visible ? "blue" : "black"}
                    style={{position:"fixed",float:"left", marginTop:"50vh",zIndex:1000,fontSize:"30px", marginLeft:"10px"}}
                    onClick ={()=>setVisible(!visible)}
              />

              <Grid.Column>
                <Sidebar.Pushable >
                
                  <Sidebar
                    as={Menu}
                    animation='overlay'
                    direction='left'
                    icon='labeled'
                    inverted
                    vertical
                    visible={visible}
                    width='large'
                    style={{background:"rgba(20,20,20,0.8)", height:"97.5vh"}}
                  >
                    <UserPanel currentUser={currentUser}/>
                    <Menu.Item as='a' style={{ cursor:"default" , backgroundColor:"#4158D0" , backgroundImage:"linear-gradient(43deg, #4158D0 0%, #C850C0 46%, #FFCC70 100%)"}} >
                        <Link to={"/user/" + user_id} style={{cursor:"default"}}>
                        <Icon name='money bill alternate outline' color="teal" />
                        <b style={{textShadow:"0 0 1px black"}}>Kazanç = { price ? price : 0}</b>
                        </Link>
                        <br/>
                        <Link to={"/user/" + user_id} style={{cursor:"default"}}>
                        <Icon name='gem' color="teal" />
                        <b style={{textShadow:"0 0 1px black"}}>Puan = { point ? point : 0}</b>
                        </Link>
                    </Menu.Item>
                    <Menu.Item as='a'>
                      <Link to="/">
                        <Icon name='home' color="olive" />
                        Ana Sayfa
                      </Link>
                    </Menu.Item>
                    {location.pathname !== "/user/" + user_id ? <Menu.Item as='a'>
                      <Link to={"/user/" + user_id}>
                        <Icon name='user' color="purple" />
                        Hesabım
                      </Link>
                    </Menu.Item> : ""}
                    <Menu.Item as='a'>
                      <Link to={"/user/" + user_id + "/update"}>
                        <Icon name='eye' color="red" />
                        Profili Güncelle
                      </Link>
                    </Menu.Item>
                    <Menu.Item as='a'>
                      <Link to={"/user/" + user_id + "/messages"}>
                        <Icon name='envelope' color="violet" />
                        {unReadMessagesLength !== 0 ? <b style={{color:"red"}}> ( {unReadMessagesLength} ) </b> : ""} Gelen Mesajlar
                      </Link>
                    </Menu.Item>
                    <Menu.Item as='a'>
                      <Link to={"/user/" + user_id + "/notifications"}>
                        <Icon name='send' color="pink" /> 
                        {unReadNotificationsLength !== 0 ? <b style={{color:"red"}}> ( {unReadNotificationsLength} ) </b> : ""} Bildirimler 
                      </Link>
                    </Menu.Item>
                    <Menu.Item as='a'>
                      <Link to={"/user/" + user_id + "/projects"}>
                        <Icon name='th' color="teal" />
                        {unSeenProjectsLength !== 0 ? <b style={{color:"red"}}> ( {unSeenProjectsLength} ) </b> : ""} Projelerim
                      </Link>
                    </Menu.Item>
                    <Menu.Item as='a'>
                      <Link to={"/user/" + user_id + "/doneprojects"}>
                        <Icon name="check circle outline" color="green" />
                        {unSeenDoneProjectsLength !== 0 ? <b style={{color:"red"}}> ( {unSeenDoneProjectsLength} ) </b> : ""} Biten Projelerim
                      </Link>
                    </Menu.Item>
                    <Menu.Item as='a'>
                      <Link to={"/user/" + user_id + "/advisorsweb"}>
                        <Icon name='sitemap' color="orange" />
                        İş Danışmanı Ağı
                      </Link>
                    </Menu.Item>
                    <Menu.Item as='a'>
                      <Link to={"/user/" + user_id + "/entrepreneurs"}>
                        <Icon name='users' color="olive" />
                        Girişimcilerim
                      </Link>
                    </Menu.Item>
                    <Menu.Item as='a'>
                      <Link to={"/user/" + user_id + "/updatebackgroundimage"}>
                        <Icon name='image outline' color="blue" />
                        Arka Plan Resmi
                      </Link>
                    </Menu.Item>
                  </Sidebar>
                  <Sidebar.Pusher style={{textAlign:"center", height:"97.5vh", overflowY:"auto" , backgroundImage: backgroundImage ? "url(" + backgroundImage + ")" : "url(/AdvisorBackgroundImage.jpg)", backgroundRepeat:"no-repeat" , backgroundSize:"100% 100%"  }}>
                    <Link to={"/user/" + user_id}><h1 style={{color:"white" , textShadow:"0 0 2px black" }}><u>Hesabım</u></h1></Link>
                    <Route path="/user/:user_id/update" component={UpdateAccount}/>   
                    <Route path="/user/:user_id/messages" component={AdvisorMessages}/>  
                    <Route path="/user/:user_id/notifications" component={Notifications}/>   
                    <Route path="/user/:user_id/projects" component={Projects}/>     
                    <Route path="/user/:user_id/doneprojects" component={DoneProjects}/>   
                    <Route path="/user/:user_id/advisorsweb" component={AdvisorsWeb}/>  
                    <Route path="/user/:user_id/updatebackgroundimage" component={UpdateBackgroundImage}/>  
                    <Route path="/user/:user_id/entrepreneurs" component={Entrepreneurs}/>            
                  </Sidebar.Pusher>
                </Sidebar.Pushable>
              </Grid.Column>
            </Grid>
          )
}

const mapStateToProps = state => ({
    currentUser: state.user.currentUser
});
  
export default connect(mapStateToProps)(AdvisorAccount);
