import React , {useState , useEffect} from "react";
import firebase from "../../firebase";
import { Icon, Table, Header ,Grid} from 'semantic-ui-react';
import {useHistory} from "react-router-dom";
import {connect} from "react-redux";

const db = firebase.database();


const PreApplications = ({currentUser}) => {
    const [preApplications, setPreApplications] = useState([]);
    const [roles, setRoles] = useState([]);
    const history = useHistory();

    useEffect(()=>{
        db.ref("preapplications").on("value" , (snapshot) => {
            if(snapshot.val()){
                setPreApplications(Object.values( snapshot.val() ));
            }
        })
    },[]);

    useEffect(() => {
        db.ref("roles").on("value", (snapshot) => {
            if(snapshot.val()){
                let snap = Object.values( snapshot.val() );
                setRoles(snap);
            }
        })
    },[]);

    useEffect(()=>{
        return currentUser.role !== "admin" ? history.push("/") : null
    });


    const handleDelete = (preapplications) => {
        if (window.confirm( preapplications.username + ' Kişisinin Ön Başvurusunu Silmek İstediğinize Emin misiniz?')){
            firebase.database().ref('preapplications/' + preapplications.key).remove()
            .then(()=>{
                console.log("Application Deleted...");
                history.push("/admin");
                history.push("/admin/preapplications");
            })
        }
    }

    const handleRead = (applicationKey) => {
        db.ref("preapplications/" + applicationKey).update({
            read: true
        });
    }

        return (
            <Grid textAlign="center" style={{marginTop:"20px"}}>
                <Grid.Column computer={16} tablet={16} mobile={16} style={{overflow:"auto"}}>
                    <Table  celled >
                        <Table.Header>
                        <Table.Row style={{textAlign:"center"}}>
                            <Table.HeaderCell></Table.HeaderCell>
                            <Table.HeaderCell>Kullanıcı</Table.HeaderCell>
                            <Table.HeaderCell>Tarih</Table.HeaderCell>
                            <Table.HeaderCell>Email Adresi</Table.HeaderCell>
                            <Table.HeaderCell>İletişim Numarası</Table.HeaderCell>
                            <Table.HeaderCell>Email <br/>Doğrulanma</Table.HeaderCell>
                            <Table.HeaderCell>Ülke</Table.HeaderCell>
                            <Table.HeaderCell>Başvurulan Proje Adı ve ID'si</Table.HeaderCell>
                            <Table.HeaderCell>Okundu</Table.HeaderCell>
                        </Table.Row>
                        </Table.Header>
                        <Table.Body>
                        { preApplications.sort((b,a)=>{return a.createdAt.localeCompare(b.createdAt)}).map(application => {
                            return (
                                <Table.Row key={application.key} style={{textAlign:"center", background: application.read ? "white" : "salmon"}}>
                                    <Table.Cell>
                                        <Icon name="trash alternate" color="red" style={{fontSize:"25px",cursor:"pointer"}} onClick={()=>handleDelete(application)} />
                                    </Table.Cell>
                                    <Table.Cell>
                                        <Header as='h4'>
                                            <Header.Content>
                                                {application.username}
                                                <Header.Subheader> { roles && roles.filter(child => child.role === application.role )[0] ? roles.filter(child => child.role === application.role )[0].displayName : ""}</Header.Subheader>
                                            </Header.Content>
                                        </Header>
                                    </Table.Cell>
                                    <Table.Cell>{new Date(application.createdAt).toLocaleDateString()} {new Date(application.createdAt).toLocaleTimeString()}</Table.Cell>
                                    <Table.Cell>{application.email}</Table.Cell>
                                    <Table.Cell>{application.phoneNumber}</Table.Cell>
                                    <Table.Cell style={{textAlign:"center"}} >{application.emailVerified ? <Icon name='check' color="green"/> : <Icon name='close' color="red"/>}</Table.Cell>
                                    <Table.Cell >{application.country}</Table.Cell>
                                    <Table.Cell >{application.appliedProjectName}<br/>{application.appliedProjectID}</Table.Cell>
                                    <Table.Cell style={{cursor:"pointer"}}>
                                        <Icon name={application.read ? "check" : "close"} color={application.read ? "green" : "red"} onClick={ () => handleRead(application.key)} />
                                    </Table.Cell>
                                </Table.Row>
                            )
                        }) }
                        </Table.Body>
                        <Table.Footer fullWidth>
                            <Table.Row>
                                <Table.HeaderCell colSpan='9' style={{textAlign:"center", color:"maroon", fontWeight:"bolder"}}>
                                    {preApplications.length + " Başvuru Alındı..."}                            
                                </Table.HeaderCell>
                            </Table.Row>
                        </Table.Footer>
                    </Table>
                </Grid.Column>
            </Grid>
        );    
}

const mapStateToProps = state => ({
    currentUser: state.user.currentUser
});
  
export default connect(mapStateToProps)(PreApplications);