import React from  "react";
import {connect} from "react-redux";
import firebase from "../../firebase";
import {Modal, Form, Input, Button, Icon } from "semantic-ui-react";
import Spinner from "../Spinner";
import md5 from "md5";


class UpdateUser extends React.Component {
    constructor(props){
        super(props);
        this.state = { price:"" , point:"" , person: [] , backgroundImage: "" , eposta:"" , loading: false , file: "" , avatar:"" , users:[] , referance : "" , referans: "" , phoneNumber : "" , username : "" , email : "" , emailVerified: true , toogleIcon : true , password : "" , passwordConfirmation : "" , role : "" , roles : []}
    }

    componentDidMount(){
        if(this.props.currentUser.role !== "admin"){
            this.props.history.push("/");
        }

        firebase.database().ref("/roles").once("value").then((snapshot)=>{
            this.setState({roles: Object.values(snapshot.val())})
        });
        
        firebase.database().ref("/users/" + this.props.match.params.updated_user_id).once("value").then((snapshot)=>{
            let person = snapshot.val();
            if(person){
                this.setState({ price:person.price ? person.price : "" , point:person.point ? person.point : "" , person: person , avatar: person.avatar , referance: person.referance , referans: person.referance , phoneNumber: person.phoneNumber , username: person.name , emailVerified : person.emailVerified , email : person.email , eposta: person.email , password: person.password.replace(md5(person.email), "") , passwordConfirmation: person.password.replace(md5(person.email), "") , role: person.role});
            }
        });

        firebase.database().ref("/users").once("value").then((snapshot)=>{
            this.setState({users: Object.values(snapshot.val())})
        });

        firebase.database().ref("backgroundImage/" + this.props.match.params.updated_user_id).once("value", snapshot=>{
            let background = snapshot.val();
            if(background){
                this.setState({ backgroundImage : background.backgroundImage })
            }
        })
    }

    handleChange = event => this.setState({ [event.target.name] : event.target.value} );

    isFormEmpty = ({username, email, password, passwordConfirmation , role , referance}) => {
        return !referance.length || !username.length || !email.length || !password.length || !passwordConfirmation.length || !role.length ;
    }

    isPasswordValid = ({password, passwordConfirmation}) => {
        if(password.length < 6 || passwordConfirmation.length < 6 ){
            return false;
        } else if(password !== passwordConfirmation){
            return false;
        } else {
            return true;
        }
    }

    isFormValid = () => {
        if(this.isFormEmpty(this.state)){
            alert("Tüm Bilgileri Doldurduğunuza Emin misiniz?");
            return false;
        } else if(!this.isPasswordValid(this.state)){
            alert("Şifreyi Doğru Yazdığınıza Emin misiniz?")
            return false;
        } else {
            return true;
        }
    }

    handleSubmit = event => {
        event.preventDefault();
        if(this.isFormValid()){
            this.setState({loading: true});

            firebase.database().ref("users").orderByChild("referance").equalTo(this.state.eposta).on("value", snapshot=>{
                snapshot.forEach(child => {
                    child.ref.update({
                        referance: this.state.email
                    })
                })
            });

            firebase.database().ref("projects").orderByChild("advisor").equalTo(this.state.eposta).on("value", snapshot=>{
                snapshot.forEach(child => {
                    child.ref.update({
                        advisor : this.state.email
                    })
                })
            });

            if(this.state.file){
                if(this.props.currentUser.uid === this.props.match.params.updated_user_id){
                    firebase.auth().currentUser.updateEmail(this.state.email)
                    .then(()=>{
                        const {file} = this.state;
                        const metaData = {
                            contentType : "image"
                        };
                        const imageUrl = this.state.avatar.substring(
                            this.state.avatar.lastIndexOf("F") + 1 ,
                            this.state.avatar.lastIndexOf("?")
                        );
                        firebase.storage().ref().child("users/" + imageUrl).delete()
                        .then(()=> {
                            firebase.storage().ref().child('users/' + file.lastModified.toString() + file.size.toString() + "." + file.type.replace("image/" , "") ).put(file, metaData)
                            .then((snapshot)=>{
                                snapshot.ref.getDownloadURL()
                                .then((url)=>{
                                    firebase.database().ref('users/' + this.props.match.params.updated_user_id).update({
                                        name: this.state.username,
                                        email: this.state.email,
                                        password : this.state.password +md5(this.state.email),
                                        phoneNumber : this.state.phoneNumber,
                                        referance: this.state.referance,
                                        avatar : url ,
                                        role : this.state.role ,
                                        updatedAt : new Date().toString() ,
                                        seen : true
                                    })
                                    .then(()=>{
                                        firebase.auth().currentUser.updateProfile({displayName: this.state.username})
                                        .then(()=>{
                                            firebase.auth().currentUser.updatePassword(this.state.password)
                                            .then(()=>{
                                                firebase.auth().currentUser.sendEmailVerification()
                                                .then(()=>{
                                                    alert(this.state.email + " adresine doğrulama mesajı gönderildi..."); 
                                                })
                                                this.closeModal();
                                            })
                                        })
                                    });
                                })
                            })
                        })
                        .catch((err) => {
                            console.log(err);
                            firebase.storage().ref().child('users/' + file.lastModified.toString() + file.size.toString() + "." + file.type.replace("image/" , "") ).put(file, metaData)
                            .then((snapshot)=>{
                                snapshot.ref.getDownloadURL()
                                .then((url)=>{
                                    firebase.database().ref('users/' + this.props.match.params.updated_user_id).update({
                                        name: this.state.username,
                                        email: this.state.email,
                                        password : this.state.password +md5(this.state.email),
                                        phoneNumber : this.state.phoneNumber,
                                        referance: this.state.referance,
                                        avatar : url ,
                                        role : this.state.role ,
                                        updatedAt : new Date().toString() ,
                                        seen : true
                                    })
                                    .then(()=>{
                                        firebase.auth().currentUser.updateProfile({displayName: this.state.username})
                                        .then(()=>{
                                            firebase.auth().currentUser.updatePassword(this.state.password)
                                            .then(()=>{
                                                firebase.auth().currentUser.sendEmailVerification()
                                                .then(()=>{
                                                    alert(this.state.email + " adresine doğrulama mesajı gönderildi..."); 
                                                })
                                                this.closeModal();
                                            })
                                        })
                                    });
                                })
                            })
                        });
                    })
                    .catch(err => {
                        alert("Bu Email Adresi Zaten Kullanılıyor.");
                        console.log(err);
                    });
                } else {
                        const {file} = this.state;
                        const metaData = {
                            contentType : "image"
                        };
                        const imageUrl = this.state.avatar.substring(
                            this.state.avatar.lastIndexOf("F") + 1 ,
                            this.state.avatar.lastIndexOf("?")
                        );
                        firebase.storage().ref().child("users/" + imageUrl).delete()
                        .then(()=> {
                            firebase.storage().ref().child('users/' + file.lastModified.toString() + file.size.toString() + "." + file.type.replace("image/" , "") ).put(file, metaData)
                            .then((snapshot)=>{
                                snapshot.ref.getDownloadURL()
                                .then((url)=>{
                                    firebase.database().ref('users/' + this.props.match.params.updated_user_id).update({
                                        name: this.state.username,
                                        email: this.state.email,
                                        password : this.state.password +md5(this.state.email),
                                        phoneNumber : this.state.phoneNumber,
                                        referance: this.state.referance,
                                        avatar : url ,
                                        price : this.state.price , 
                                        point : this.state.point , 
                                        role : this.state.role ,
                                        updatedAt : new Date().toString() ,
                                        seen : true
                                    })
                                    .then(()=>{
                                        this.closeModal();
                                    })
                                })
                            })
                        })
                        .catch((err) => {
                            console.log(err);
                            firebase.storage().ref().child('users/' + file.lastModified.toString() + file.size.toString() + "." + file.type.replace("image/" , "") ).put(file, metaData)
                            .then((snapshot)=>{
                                snapshot.ref.getDownloadURL()
                                .then((url)=>{
                                    firebase.database().ref('users/' + this.props.match.params.updated_user_id).update({
                                        name: this.state.username,
                                        email: this.state.email,
                                        password : this.state.password +md5(this.state.email),
                                        phoneNumber : this.state.phoneNumber,
                                        referance: this.state.referance,
                                        avatar : url ,
                                        price : this.state.price , 
                                        point : this.state.point , 
                                        role : this.state.role ,
                                        updatedAt : new Date().toString() ,
                                        seen : true
                                    })
                                    .then(()=>{
                                        this.closeModal();
                                    })
                                })
                            })
                        });
                }
            } else {
                if(this.props.currentUser.uid === this.props.match.params.updated_user_id){
                    firebase.auth().currentUser.updateEmail(this.state.email)
                    .then(()=>{
                        firebase.database().ref('users/' + this.props.match.params.updated_user_id).update({
                            name: this.state.username,
                            email: this.state.email,
                            phoneNumber : this.state.phoneNumber,
                            referance : this.state.referance ,
                            password : this.state.password +md5(this.state.email),
                            role : this.state.role ,
                            updatedAt : new Date().toString() ,
                            seen : true
                        })
                        .then(()=>{
                            firebase.auth().currentUser.updateProfile({displayName: this.state.username})
                            .then(()=>{
                                firebase.auth().currentUser.updatePassword(this.state.password)
                                .then(()=>{
                                    firebase.auth().currentUser.sendEmailVerification()
                                    .then(()=>{
                                        alert(this.state.email + " adresine doğrulama mesajı gönderildi..."); 
                                    })
                                    this.closeModal();
                                })
                            })
                        });
                    })
                    .catch(err => {
                        alert("Bu Email Adresi Zaten Kullanılıyor.");
                        console.log(err);
                    });
                } else {
                    firebase.database().ref('users/' + this.props.match.params.updated_user_id).update({
                        name: this.state.username,
                        email: this.state.email,
                        phoneNumber : this.state.phoneNumber,
                        referance : this.state.referance ,
                        password : this.state.password +md5(this.state.email),
                        role : this.state.role ,
                        price : this.state.price , 
                        point : this.state.point , 
                        updatedAt : new Date().toString() , 
                        seen : true
                    })
                    .then(()=>{
                        this.closeModal();
                    })
                }
            }
        }
    }

    handleDelete = () => {
        if (window.confirm('Hesabı Silmek İstediğinize Emin misiniz?')){
            const imageUrl = this.state.avatar.substring(
                this.state.avatar.lastIndexOf("F") + 1 ,
                this.state.avatar.lastIndexOf("?")
            );
            firebase.storage().ref().child("users/" + imageUrl).delete();

           
            const backgroundUrl = this.state.backgroundImage.substring(
                this.state.backgroundImage.lastIndexOf("F") + 1 ,
                this.state.backgroundImage.lastIndexOf("?")
            );
            firebase.storage().ref().child("backgroundImage/" + this.state.person.key + "/" + backgroundUrl).delete()
            .then(()=>{
                firebase.database().ref("backgroundImage/" + this.state.person.key).remove()
            });
            

            firebase.database().ref("users").orderByChild("referance").equalTo(this.state.eposta).on("value", snapshot=>{
                snapshot.forEach(child => {
                    child.ref.update({
                        referance: this.state.referans
                    })
                })
            });

            firebase.database().ref("projects").orderByChild("advisor").equalTo(this.state.eposta).on("value", snapshot=>{
                snapshot.forEach(child => {
                    child.ref.update({
                        advisor : "Belirsiz"
                    })
                })
            });
            
            if(this.props.currentUser.uid === this.props.match.params.updated_user_id){
                firebase.database().ref('users/' + this.props.match.params.updated_user_id).remove()
                .then(()=>{
                    firebase.auth().currentUser.delete()
                    .then(()=>{
                        console.log("Deleted User");
                        this.props.history.push("/login");
                    });
                });
            } else {
                firebase.database().ref("/users/" + this.props.match.params.updated_user_id).once("value").then((snapshot)=>{
                    let person = snapshot.val();
                    let currentUserEmail = this.props.currentUser.email;
                    let currentUserPassword = this.props.currentUser.password;
                    if(person){
                        person.messages && Object.values(person.messages).map(message => {
                            return (
                                message.photos && Object.values(message.photos).map(child =>{
                                    return firebase.storage().ref().child("contact/" + person.key + "/" + message.key + "/" + child.name).delete();
                                })
                            )
                        })
                    }

                    firebase.auth().signOut()
                    .then(()=>{
                        firebase.auth().signInWithEmailAndPassword(person.email , person.password.replace(md5(person.email), ""))
                        .then(()=>{
                            firebase.auth().currentUser.delete()
                            .then(()=>{
                                firebase.database().ref('users/' + this.props.match.params.updated_user_id).remove()
                                .then(()=>{
                                    firebase.auth().signInWithEmailAndPassword(currentUserEmail , currentUserPassword)
                                    .then(()=>{
                                        console.log("Deleted User...");
                                        setTimeout(()=>{
                                            this.props.history.push("/admin/users");
                                        }, 3);
                                    })
                                })
                            })
                        })
                    })
                });
            }
        }
    }


    closeModal = () => {
        this.props.history.push("/admin/users/");
    }

    render(){
        const { username, email , password , passwordConfirmation, role , roles, referance , phoneNumber, users , price, point} = this.state;
        if(roles.length === 0 ) {
            return <Spinner/>
        }

        return (
            <Modal basic open={true} onClose={ this.closeModal }>
                <Modal.Header style={{textAlign: "center"}} >{username + " 'nı Güncelle"}</Modal.Header>
                <Modal.Content>
                    <Form onSubmit={this.handleSubmit}>
                        <Form.Field>
                            <Input
                                fluid
                                label = "İsim Soyisim"
                                type="text"
                                name = "username"
                                value = {username}
                                onChange={this.handleChange}
                            />
                        </Form.Field>
                        <Form.Field>
                            <Input
                                fluid
                                label = "E-Posta Adresi"
                                name = "email"
                                disabled = {this.props.currentUser.uid === this.props.match.params.updated_user_id ? false : true}
                                type="email"
                                value = {email}
                                onChange={this.handleChange}
                            />
                        </Form.Field>
                        <Form.Field>
                            <Input
                                fluid
                                placeholder ="10 Haneli İletişim Numarasını Giriniz..."
                                label = "İletişim Numarası"
                                name = "phoneNumber"
                                type="number"
                                value = {phoneNumber}
                                onChange={this.handleChange}
                            />
                        </Form.Field>
                        <Form.Field>
                            <Input
                                fluid
                                onFocus = {()=> this.setState({toogleIcon : false})}
                                onBlur = {()=> this.setState({toogleIcon : true})}
                                type={this.state.toogleIcon ? "password" : "text"}
                                icon = {this.state.toogleIcon ? "eye slash" : "eye"}
                                label = "Şifre"
                                disabled = {this.props.currentUser.uid === this.props.match.params.updated_user_id ? false : true}
                                name = "password"
                                value = {password}
                                onChange={this.handleChange}
                            />
                        </Form.Field>
                        <Form.Field>
                            <Input
                                fluid
                                onFocus = {()=> this.setState({toogleIcon : false})}
                                onBlur = {()=> this.setState({toogleIcon : true})}
                                type={this.state.toogleIcon ? "password" : "text"}
                                icon = {this.state.toogleIcon ? "eye slash" : "eye"}
                                label = "Şifre Doğrula"
                                disabled = {this.props.currentUser.uid === this.props.match.params.updated_user_id ? false : true}
                                name = "passwordConfirmation"
                                value = {passwordConfirmation}
                                onChange={this.handleChange}
                            />
                        </Form.Field>
                        <Form.Field>
                            <label for="role">
                                <h4 style={{color:"whitesmoke"}}>Rol</h4>
                                <select value={role} onChange={this.handleChange} name="role" id="role" >
                                    {roles.map((role)=>{
                                        return <option value={role.role}>{role.displayName}</option>
                                    })}
                                </select>
                            </label>
                        </Form.Field>
                        { role === "advisor" && <Form.Field>
                            <Input
                                fluid
                                label = "Kazanç"
                                type="text"
                                name = "price"
                                value = {price}
                                onChange={this.handleChange}
                            />
                        </Form.Field>}
                        { role === "advisor" && <Form.Field>
                            <Input
                                fluid
                                label = "Puan"
                                type="text"
                                name = "point"
                                value = {point}
                                onChange={this.handleChange}
                            />
                        </Form.Field>}
                        { role !== "admin" && <Form.Field>
                            <label for="referance">
                                <h4 style={{color:"whitesmoke"}}>Referans</h4>
                                <select value={referance} onChange={this.handleChange} name="referance" id="referance">
                                    <option value="Referans Yok" >Referans Belirlenmedi</option>
                                    <option value="Admin" >Admin</option>
                                    {users.filter(child => child.role === "advisor" ).map((user)=>{
                                        return <option value={user.email}>{user.email}</option>
                                    })}
                                </select>
                                { referance === "Referans Yok" && role === "advisor" && <h5 style={{color:"aqua"}}>(İş danışmanı'nın referansı var ise lütfen kendisine referans olan İş danışmanını seçiniz. Yok ise referans seçiminizi "Admin" olarak yapınız.)</h5>}
                                { referance === "Referans Yok" && role === "entrepreneur" && <h5 style={{color:"aqua"}}>(Girişimci rolündeki kullanıcıyı iş danışmanına atamak için girişimcinin referansını atamak istediğiniz İş danışmanı olarak belirleyiniz.)</h5>}
                            </label>
                        </Form.Field>}
                        <Form.Field>
                            <Input 
                                fluid
                                type="file"  
                                accept="image/png, image/jpeg , image/webp"  
                                label="Resmi Güncelle" 
                                onChange={(e) => this.setState({ file: e.target.files[0] })} 
                            />
                        </Form.Field>
                    </Form>
                </Modal.Content>
                <Modal.Actions>
                    <Button color="green" loading={this.state.loading} inverted onClick={this.handleSubmit}>
                        <Icon name="checkmark"/> Güncelle
                    </Button>
                    <Button color="orange" disabled={this.state.loading} inverted onClick={this.handleDelete}>
                        <Icon name="user delete"/> Sil
                    </Button>
                    <Button color="red" inverted onClick={ this.closeModal}>
                        <Icon name="remove"/> Kapat
                    </Button>
                </Modal.Actions>
            </Modal>
        );
    }
}


const mapStateToProps = state => ({
    currentUser: state.user.currentUser
});
  
export default connect(mapStateToProps)(UpdateUser);