import React, {useState, useEffect} from "react";
import {connect} from "react-redux";
import {useHistory} from "react-router-dom";
import Slider from "../Slider";
import firebase from "../../firebase";
import { Grid } from "semantic-ui-react";

const DoneProjects = ({currentUser}) => {
    const [project,setProject] = useState([]);
    const [width, setWidth] = useState("");
    const [maxWidth, setMaxWidth] = useState("");
    const [height, setHeight] = useState("");
    const history = useHistory();

    useEffect(()=>{
        if( currentUser.role !== "admin" ){
            history.push("/");
        }
    },[ currentUser.role, history ]);

    useEffect(() => {
        firebase.database().ref("projects").orderByChild("done").equalTo(true).on("value", snapshot => {
            if(snapshot.val()){
                let snap = Object.values(snapshot.val());
                setProject(snap);
            }
        })
    }, []);

    useEffect(()=>{
        project.length === 1 ? setWidth("80vw") : setWidth("100%");
        project.length === 1 ? setMaxWidth("80vw") : setMaxWidth("200px");
        project.length === 1 ? setHeight("80vh") : setHeight("150px");
    },[project])

    useEffect(() => {
        setTimeout(() => {
            firebase.database().ref("projects").orderByChild("done").equalTo(true).once("value" , (snapshot) => {
                snapshot.forEach(child => {
                    child.ref.update({
                        seen : true
                    })
                })
            });
        }, 10000);
    }, []);

    return (
        <Grid >
            <Grid.Column computer={10} tablet={10} mobile={16} style={{margin:"0 auto"}}>
                <Slider maxWidth={maxWidth} width={width} height={height} projects={project} />
            </Grid.Column>
        </Grid>   
    )
}

const mapStateToProps = state => ({
    currentUser: state.user.currentUser
});
  
export default connect(mapStateToProps)(DoneProjects);