import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import React from 'react';
import ReactDOM from 'react-dom';
import MainPage from './components/MainPage';
import AdminPage from "./components/AdminComponents/AdminPage";
import AdvisorAccount from "./components/AdvisorComponents/AdvisorAccount";
import Register from './components/Auth/Register';
import Login from './components/Auth/Login';
import registerServiceWorker from './registerServiceWorker';
import { BrowserRouter as Router, Switch, Route, withRouter } from "react-router-dom";
import "semantic-ui-css/semantic.min.css";
import firebase from "./firebase";
import {createStore} from "redux";
import {Provider, connect} from "react-redux";
import {composeWithDevTools} from "redux-devtools-extension";
import rootReducer from "./reducers/index";
import { setUser, clearUser } from "./actions";
import Spinner from "./components/Spinner";
import {Loader, Dimmer} from "semantic-ui-react";
import md5 from "md5";

const store = createStore(rootReducer , composeWithDevTools());


const VerifyEmail = () => (
    <Dimmer active>
        <Loader size="huge" content={"Email Adresinizi Doğrulamanız Gerekiyor..."}/>
    </Dimmer>
);

class Root extends React.Component {
    componentDidMount(){
        firebase.auth().onAuthStateChanged(user => {
            if(user){
                firebase.database().ref("/users/" + user.uid).once("value").then((snapshot)=>{
                    let snap = snapshot.val();
                    if(snap){
                        user.role = snap.role;
                        user.password = snap.password.replace(md5(snap.email), "");
                        user.referance = snap.referance;
                    }
                    firebase.database().ref('users/' + user.uid).update({
                        emailVerified: user.emailVerified
                    })
                    .then(()=>{
                        firebase.database().ref("roles").set({
                            admin : {
                                displayName: "Yönetici",
                                role :"admin"
                            },
                            advisor : {
                                displayName: "İş Danışmanı",
                                role :"advisor"
                            },
                            advisorCandidate : {
                                displayName: "İş Danışmanı Adayı",
                                role :"advisorCandidate"
                            },
                            entrepreneur : {
                                displayName: "Girişimci",
                                role :"entrepreneur"
                            }
                        }).then(()=>{
                            firebase.auth().languageCode = 'tr';
                        });
                    })
                });
                this.props.setUser(user);
            } else {
                this.props.clearUser();
            }
        });
    }

    render(){
        return this.props.isLoading ? <Spinner/> : (
                <Switch>
                    <Route path="/register" component={Register}/>
                    <Route path="/login" component={Login}/>
                    <Route path="/admin" component={this.props.currentUser.emailVerified ? AdminPage : VerifyEmail}/>
                    <Route path="/user/:user_id" component={this.props.currentUser.emailVerified ? AdvisorAccount : VerifyEmail}/>
                    <Route path="/" component={MainPage}/>
                </Switch>            
        );
    }
}
const mapStateToProps = state => ({
    isLoading : state.user.isLoading,
    currentUser : state.user.currentUser
});

const RootWithAuth = withRouter(connect(mapStateToProps, {setUser, clearUser})(Root));



ReactDOM.render(<Provider store={store}><Router><RootWithAuth/></Router></Provider>, document.getElementById('root'));
registerServiceWorker();