import React, {useState, useEffect} from "react";
import {Modal, Form, Input, Button, Icon, Dropdown , Checkbox , Progress} from "semantic-ui-react";
import {connect} from "react-redux";
import {useHistory} from "react-router-dom";
import firebase from "../../firebase";
import DateTimePicker from 'react-datetime-picker';

const AddProject = ({currentUser}) => {
    const history = useHistory();
    const [projectMainName, setProjectMainName] = useState("");
    const [projectAuxiliaryName, setProjectAuxiliaryName] = useState("");
    const [projectSubAuxiliaryName, setProjectSubAuxiliaryName] = useState("");
    const [name, setName] = useState("");
    const [projectStatus, setProjectStatus] = useState([]);
    const [projectStatusValue, setProjectStatusValue] = useState([]);
    const [projectCurrency, setProjectCurrency] = useState("");
    const [projectPointCurrency, setProjectPointCurrency] = useState("");
    const [projectLink, setProjectLink] = useState("");
    const [projectPublishTime, setProjectPublishTime] = useState(new Date());
    const [validCityCountry, setValidCityCountry] = useState([]);
    const [validCityCountryValue, setValidCityCountryValue] = useState([]);
    const [projectPrice, setProjectPrice] = useState("");
    const [projectPoint, setProjectPoint] = useState("");
    const [projectCategory, setProjectCategory] = useState("");
    const [upToDate, setUpToDate] = useState("Güncel");
    const [projectProgress, setProjectProgress] = useState("");
    const [projectNote, setProjectNote] = useState("");
    const [category, setCategory] = useState([]);
    const [projectNames, setProjectNames] = useState([]);
    const [countries, setCountries] = useState([]);
    const [status, setStatus] = useState([]);
    const [currency, setCurrency] = useState([]);
    const [pointCurrency, setPointCurrency] = useState([]);
    const [isChecked, setIsChecked] = useState(false);
    const [users, setUsers] = useState([]);
    const [advisor, setAdvisor] = useState("");
    const [percent , setPercent] = useState(0);
    const [files, setFiles] = useState([]);
    const [loading, setLoading] = useState(false);
    let projectId = Math.floor(Math.random() * 1000000000);

    useEffect(()=>{
        if( currentUser.role !== "admin" ){
            history.push("/");
        }
    },[ currentUser.role, history ]);

    useEffect(()=>{
        firebase.database().ref("/projectNames").once("value", snapshot => {
            setProjectNames(snapshot.val());
        });
    },[projectMainName, projectAuxiliaryName, projectSubAuxiliaryName]);

    useEffect(()=>{
        firebase.database().ref("/countries").once("value", (snapshot)=>{
            setCountries(snapshot.val());
        });
    },[ validCityCountry ]);

    useEffect(()=>{
        firebase.database().ref("/status").once("value", (snapshot)=>{
            setStatus(snapshot.val());
        });
    },[ projectStatus ]);

    useEffect(()=>{
        firebase.database().ref("/currency").once("value", (snapshot)=>{
            setCurrency(snapshot.val());
        });
    },[ projectCurrency ]);

    useEffect(()=>{
        firebase.database().ref("/pointCurrency").once("value", (snapshot)=>{
            setPointCurrency(snapshot.val());
        });
    },[ projectPointCurrency ]);

    useEffect(()=>{
        firebase.database().ref("/category").once("value", (snapshot)=>{
            setCategory(snapshot.val());
        });
    },[ projectCategory ]);

    useEffect(()=>{
        firebase.database().ref("/users").once("value", (snapshot)=>{
            if(snapshot.val()){
                let snap = Object.values(snapshot.val());
                setUsers(snap);
            }
        });
    },[ advisor ]);
    
    useEffect(()=>{
        if(isChecked && countries){
            let i;
            const countryValues = [];
            const countryValue = [];
            for(i=0; i< Object.values(countries).length; i++){
                let country_ID = Math.floor(Math.random() * 1000000000);
                countryValues[Object.values(countries)[i].value] = { countryValue: Object.values(countries)[i].value , key: country_ID };
                countryValue[i] = Object.values(countries)[i].value ;  
            }
            setValidCityCountry(countryValues);
            setValidCityCountryValue(countryValue);  
        }
    },[isChecked , countries]);

    const isFormEmpty = ({ projectMainName, name , validCityCountryValue, projectPrice , projectCurrency , projectCategory, files , projectPublishTime , projectStatusValue, advisor}) => {
        return !projectMainName.length || !validCityCountryValue.length || !projectPrice.length || !projectCategory.length || !files.length || !projectCurrency.length || !name.length || !projectPublishTime || !projectStatusValue.length || !advisor.length ;
    }


    const isFormValid = () => {
        if(isFormEmpty({ projectMainName, name , validCityCountryValue, projectPrice , projectCurrency , projectCategory , files , projectPublishTime , projectStatusValue, advisor })){
            alert("Tüm Gerekli Bilgileri Doldurduğunuza Emin misiniz?");
            return false;
        } else {
            return true;
        }
    }

    const handleSubmit = async event => {
        event.preventDefault();
        if(isFormValid()){
            let time = projectPublishTime.getTime();
            let offset = projectPublishTime.getTimezoneOffset();
            offset = offset * 60000;
            offset = offset + 10800000;
            let seconds = time - offset;
            setLoading(true);
            const photo = [];
            const projectID = projectId;
            for( let i = 0; i< files.length; i++){
                const file = files[i];
                let img_ID = file.lastModified.toString() + file.size.toString() ;
                let img_name = file.lastModified.toString() + file.size.toString() + "." + file.type.replace("image/" , "");
                let profilPhoto = i === 0 ? true : false ;
                console.log(file);
                const metaData = {
                    contentType : "image"
                };
                const percentIncrement = 100 / files.length ;
                await firebase.storage().ref().child('projects/' + projectID + "/" + img_name ).put(file, metaData)
                .then((snapshot)=>{
                    snapshot.ref.getDownloadURL()
                    .then((url)=>{
                        photo[img_ID] = {photo: url, key: img_ID, profilPhoto: profilPhoto , name: img_name };
                        setPercent( prevPercent => prevPercent + percentIncrement);
                    })
                })
            }

            setTimeout(() => {
                firebase.database().ref("projects/" + projectID ).set({
                    projectMainName,
                    projectAuxiliaryName,
                    projectSubAuxiliaryName,
                    name,
                    projectStatus,
                    projectLink,
                    validCityCountry,
                    projectPublishTime : seconds ,
                    projectPrice,
                    projectPoint,
                    advisor,
                    projectCurrency,
                    projectNote,
                    projectPointCurrency,
                    projectCategory,
                    upToDate,
                    projectProgress,
                    photos : photo ,
                    key : projectID,
                    createdAt : new Date().toString(),
                    createdBy : currentUser.email ,
                    seen : false ,
                    done : false
                })
                .then(()=>{
                    setProjectMainName("");
                    setProjectAuxiliaryName("");
                    setProjectSubAuxiliaryName("");
                    setName("");
                    setProjectStatus([]);
                    setAdvisor("");
                    setProjectLink("");
                    setValidCityCountry([]);
                    setProjectPrice("");
                    setProjectNote("");
                    setProjectPoint("");
                    setProjectCurrency("");
                    setProjectPointCurrency("");
                    setProjectPublishTime(new Date());
                    setProjectCategory("");
                    setUpToDate(upToDate);
                    setProjectProgress("");
                    
                    setTimeout(()=>{
                        alert("Projeniz başarı ile yüklenmiştir. Bol kazanç dileriz...");
                        closeModal();
                    },1000);
                })
                .catch(err =>{
                    console.log(err);
                    alert("Proje yüklenirken bir hata meydana geldi. Lütfen tekrar deneyiniz...");
                });
            }, 2000);
        }
    }

    const handleChangeStatus = (data) => {
        let i;
        const statusValues = [];
        const statusValue = [];
        for(i=0; i< data.length; i++){
            let status_ID = Math.floor(Math.random() * 1000000000);
            statusValues[data[i]] = { statusValue: data[i] , key: status_ID };
            statusValue[i] = data[i] ;  
        }
        setProjectStatus(statusValues);
        setProjectStatusValue(statusValue);        
    }

    const handleChangeCountries = (data) => {
        let i;
        const countryValues = [];
        const countryValue = [];
        for(i=0; i< data.length; i++){
            let country_ID = Math.floor(Math.random() * 1000000000);
            countryValues[data[i]] = { countryValue: data[i] , key: country_ID };
            countryValue[i] = data[i] ;  
        }
        setValidCityCountry(countryValues);
        setValidCityCountryValue(countryValue);        
    }

    const closeModal = () => {
        history.push("/admin");
    }

    useEffect(()=>{
        document.getElementById('textarea').addEventListener('keydown', function(e) {
            if (e.key === 'Tab') {
              e.preventDefault();
              var start = this.selectionStart;
              var end = this.selectionEnd;
              this.value = this.value.substring(0, start) + "\t" + this.value.substring(end);
              this.selectionStart = this.selectionEnd = start + 1;
            }
          });
    },[]);

    const advisors = users ? users.filter(user => user.role === "advisor") : [] ;
    const categories = projectMainName && projectNames && Object.values(projectNames).filter(projectNames=> projectNames.projectMainName === projectMainName)[0] ? Object.values(Object.values(projectNames).filter(projectNames=> projectNames.projectMainName === projectMainName)[0].categories) : "" ;
    const subCategories = projectMainName && projectNames && projectAuxiliaryName && Object.values(projectNames).filter(projectNames=> projectNames.projectMainName === projectMainName)[0] && Object.values(Object.values(projectNames).filter(projectNames=> projectNames.projectMainName === projectMainName)[0].categories).filter(child => child.projectAuxiliaryName === projectAuxiliaryName)[0] ? Object.values(Object.values(Object.values(projectNames).filter(projectNames=> projectNames.projectMainName === projectMainName)[0].categories).filter(child => child.projectAuxiliaryName === projectAuxiliaryName)[0].subCategories) : "" ;    
        return (
            <Modal basic open={true} onClose={closeModal}>
                <Modal.Header style={{textAlign: "center"}} >Proje Ekle</Modal.Header>
                <Modal.Content>
                    <Form autocomplete="off" onSubmit={handleSubmit}>
                        <Form.Field>
                            <select value={projectMainName} onChange={e => setProjectMainName(e.target.value)} name="projectMainName">
                                <option value="" >Proje Ana Adı</option>
                                {projectNames ? Object.values(projectNames).map((child)=>{
                                    return (<option key={child.key} value={child.projectMainName}>{child.projectMainName}</option>)
                                }) : ""}
                            </select>
                        </Form.Field>
                        <Form.Field>
                            <select value={projectAuxiliaryName} onChange={e => setProjectAuxiliaryName(e.target.value)} name="projectAuxiliaryName">
                                <option value="" >Proje Yardımcı Adı</option>
                                {projectNames && projectMainName && Object.values(projectNames).filter(projectNames=> projectNames.projectMainName === projectMainName)[0] ? categories.map((child)=>{
                                    return (<option key={child.key} value={child.projectAuxiliaryName}>{child.projectAuxiliaryName}</option>)
                                }) : ""}
                            </select>
                        </Form.Field>
                        <Form.Field>
                            <select value={projectSubAuxiliaryName} onChange={e => setProjectSubAuxiliaryName(e.target.value)} name="projectSubAuxiliaryName">
                                <option value="" >Proje Alt Yardımcı Adı</option>
                                {projectNames && projectMainName && projectAuxiliaryName && Object.values(projectNames).filter(projectNames=> projectNames.projectMainName === projectMainName)[0] && Object.values(Object.values(projectNames).filter(projectNames=> projectNames.projectMainName === projectMainName)[0].categories).filter(child => child.projectAuxiliaryName === projectAuxiliaryName)[0] ? subCategories.map((child)=>{
                                    return (<option key={child.key} value={child.projectSubAuxiliaryName}>{child.projectSubAuxiliaryName}</option>)
                                }) : ""}
                            </select>
                        </Form.Field>
                        <Form.Field>
                            <Input
                                fluid
                                type="text"
                                label = "Proje Adı"
                                name = "name"
                                value = {name}
                                onChange={e => setName(e.target.value)}
                            />
                        </Form.Field>
                        <Form.Field>
                            <select value={projectCategory} onChange={e => setProjectCategory(e.target.value)} name="projectCategory">
                                <option value="" >Kategori Seçiniz</option>
                                {category ? Object.values(category).map((child)=>{
                                    return (<option key={child.key} value={child.value}>{child.value}</option>)
                                }) : ""}
                            </select>
                        </Form.Field>
                        <Form.Field>
                            <Dropdown 
                                placeholder='Proje Statüsü' 
                                fluid 
                                multiple 
                                selection 
                                value={projectStatusValue}
                                options={ status ? Object.values(status) : null} 
                                onChange={ (event,data)=> handleChangeStatus(data.value) }
                            />
                        </Form.Field>
                        <Form.Field>
                            <Input
                                fluid
                                type="text"
                                label = "Link Ekle"
                                name = "projectLink"
                                value = {projectLink}
                                onChange={e => setProjectLink(e.target.value)}
                            />
                        </Form.Field>
                        <Form.Field>
                            <Input
                                fluid
                                type="text"
                                label = "Proje Notu"
                                name = "projectNote"
                                value = {projectNote}
                                onChange={e => setProjectNote(e.target.value)}
                            />
                        </Form.Field>
                        <Form.Field>
                            <select value={advisor} onChange={e => setAdvisor(e.target.value)} name="advisor">
                                <option value="" >İş Danışmanı Seçiniz</option>
                                <option value="Belirsiz" >Belirsiz</option>
                                {advisors.map((child)=>{
                                    return (<option key={child.key} value={child.email}>{child.email}</option>)
                                })}
                            </select>
                        </Form.Field>
                        <Form.Field>
                            <Dropdown 
                                placeholder='Projenin Geçerli Olduğu Ülkeler' 
                                fluid 
                                multiple 
                                selection 
                                value={validCityCountryValue}
                                options={ countries ? Object.values(countries) : null} 
                                onChange={ (event,data)=> handleChangeCountries(data.value) }
                            />
                            <Checkbox
                                label="Tüm Ülkeleri Seç"
                                toggle
                                checked={isChecked} 
                                onChange={()=> setIsChecked(!isChecked)}
                            />
                        </Form.Field>
                        <Form.Field>
                            <Input
                                fluid
                                type="number"
                                label = "Proje Bedeli"
                                name = "projectPrice"
                                value = {projectPrice}
                                onChange={e => setProjectPrice(e.target.value)}
                            />
                        </Form.Field>
                        <Form.Field>
                            <select value={projectCurrency} onChange={e => setProjectCurrency(e.target.value)} name="projectCurrency">
                                <option value="" >Para Birimi</option>
                                {currency ? Object.values(currency).map((child)=>{
                                    return (<option key={child.key} value={child.value}>{child.value}</option>)
                                }) : ""}
                            </select>
                        </Form.Field>
                        <Form.Field>
                            <Input
                                fluid
                                type="number"
                                label = "Proje Puanı"
                                name = "projectPoint"
                                value = {projectPoint}
                                onChange={e => setProjectPoint(e.target.value)}
                            />
                        </Form.Field>
                        <Form.Field>
                            <select value={projectPointCurrency} onChange={e => setProjectPointCurrency(e.target.value)} name="projectPointCurrency">
                                <option value="" >Puan Birimi</option>
                                {pointCurrency ? Object.values(pointCurrency).map((child)=>{
                                    return (<option key={child.key} value={child.value}>{child.value}</option>)
                                }) : ""}
                            </select>
                        </Form.Field>
                        <Form.Field>
                            <textarea 
                                id="textarea"
                                type="text"
                                name ="projectProgress"
                                placeholder='Gelişmeler...' 
                                onChange={e => setProjectProgress(e.target.value)}
                                value = {projectProgress}
                                style = {{height:"30vh", resize:"none", overflow:"auto"}}
                            >
                            </textarea>  
                        </Form.Field>
                        <Form.Field>                        
                            <DateTimePicker
                                className="timepicker"
                                onChange={ setProjectPublishTime }
                                value={projectPublishTime}
                                name = "projectPublishTime"
                                autoFocus = {false}
                                showLeadingZeros = {false}                                    
                                closeWidgets = {false}
                                dayPlaceholder = "dd"                                    
                                format = "dd-MM-y / h:mm:ss a"
                                hourPlaceholder = "hh"                                    
                                minDetail = "decade"
                                minutePlaceholder = "mm"
                                monthPlaceholder = "mm"
                                secondPlaceholder = "ss"                                    
                                yearPlaceholder = "yyyy"
                                clearIcon = {<Icon name="repeat" color="red" />}
                                calendarIcon = {<Icon name="calendar alternate" color="olive"/>}
                            />    
                            <p style={{color:"aqua"}}>Yayınlanma Tarihi Belirleyiniz. (Gün-Ay-Yıl/Saat:Dakika:Saniye AM/PM)</p>                        
                        </Form.Field>
                        <Form.Field>
                            <Input 
                                fluid
                                type="file"   
                                multiple
                                accept="image/png, image/jpeg , image/webp" 
                                label="Resim Yükle" 
                                onChange={(e)=> setFiles(e.target.files) } 
                            />
                            <br/>
                            { percent === 0 || percent === 100 ? "" : <Progress percent={percent} indicating />}
                            { percent === 100 ? <Progress percent={100} success> <b style={{color:"white"}}>Resim Yükleme Başarılı...</b> <Icon name="check" color="green" size="large" /> </Progress> : "" }
                            { percent === 0 ? <Progress percent={percent} indicating style={{visibility: "hidden"}} /> : "" }
                        </Form.Field>
                    </Form>
                </Modal.Content>
                <Modal.Actions>
                    <Button color="green" loading={loading} disabled={percent < 100 && percent > 0 ? true : false } inverted onClick={handleSubmit}>
                        <Icon name="checkmark"/> Ekle
                    </Button>
                    <Button color="red" inverted onClick={closeModal}>
                        <Icon name="remove"/> Kapat
                    </Button>
                </Modal.Actions>
            </Modal>
        );
    
}

const mapStateToProps = state => ({
    currentUser: state.user.currentUser
});
  
export default connect(mapStateToProps)(AddProject);

