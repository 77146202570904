import React,{useState, useEffect} from  "react";
import {connect} from "react-redux";
import {useHistory} from "react-router-dom";
import firebase from "../../firebase";
import {Modal, Form, Button, Icon, Input} from "semantic-ui-react";

const ContactEdit = ({currentUser}) => {
    const history = useHistory();
    const [mobile, setMobile] = useState("");
    const [phone, setPhone] = useState("");

    useEffect(()=>{
        if( currentUser.role !== "admin" ){
            history.push("/");
        }
    },[ currentUser.role, history ]);

    useEffect(()=>{
        firebase.database().ref("contact").on("value", (snapshot)=>{
            if(snapshot.val()){
                setMobile(snapshot.val().mobile);
                setPhone(snapshot.val().phone);
            }
        })
    },[]);

    const closeModal = () => {
        history.push("/admin");
    }

    const handleSubmit = event => {
        event.preventDefault();
        firebase.database().ref("contact").update({mobile, phone})
        .then(()=>{
            alert("İletişim Numaraları Güncellendi...");
        })
    }

    return (
        <Modal basic open={true} onClose={closeModal}>
            <Modal.Header style={{textAlign: "center"}} >İletişim Numaraları</Modal.Header>
            <Modal.Content>
                <Form autocomplete="off" onSubmit={handleSubmit}>
                    <Form.Field>
                        <Input
                            label="Sabit Telefon"
                            type="text"
                            name ="phone"
                            placeholder='Sabit Telefon' 
                            onChange={e => setPhone(e.target.value)}
                            value = {phone}
                        />
                    </Form.Field>
                    <Form.Field>
                        <Input
                            label="Cep Telefonu"
                            type="text"
                            name ="mobile"
                            placeholder='Cep Telefonu' 
                            onChange={e => setMobile(e.target.value)}
                            value = {mobile}
                        />
                    </Form.Field>
                </Form>
            </Modal.Content>
            <Modal.Actions>
                <Button color="green" inverted onClick={handleSubmit}>
                    <Icon name="checkmark"/> Güncelle
                </Button>
                <Button color="red" inverted onClick={closeModal}>
                    <Icon name="remove"/> Kapat
                </Button>
            </Modal.Actions>
        </Modal>
    )
}

const mapStateToProps = state => ({
    currentUser: state.user.currentUser
});
  
export default connect(mapStateToProps)(ContactEdit);