import React from  "react";
import {connect} from "react-redux";
import firebase from "../../firebase";
import {Modal, Form, Input, Button, Icon } from "semantic-ui-react";
import md5 from "md5";


class UpdateAccount extends React.Component {
    constructor(props){
        super(props);
        this.state = { backgroundImage: "" , eposta:"" , person: [] , loading : false , avatar:"" , referance:"" , referans:"" , users:[] , username : "" , toogleIcon : true , email : "" , password : "" , passwordConfirmation : "" , file : "" , phoneNumber : ""}
    }

    componentDidMount(){
        if(this.props.currentUser.uid !== this.props.match.params.user_id || this.props.currentUser.role !== "advisor"){
            this.props.history.push("/");
        }
        
        firebase.database().ref("/users/" + this.props.match.params.user_id).once("value").then((snapshot)=>{
            let person = snapshot.val();
            if(person){
                this.setState({ person: person , avatar: person.avatar , referance:person.referance , referans:person.referance , phoneNumber : person.phoneNumber , username: person.name , email : person.email , eposta: person.email , password: person.password.replace(md5(person.email), "") , passwordConfirmation: person.password.replace(md5(person.email), "") });
            }
        });

        firebase.database().ref("/users").once("value").then((snapshot)=>{
            this.setState({users: Object.values(snapshot.val())})
        });

        firebase.database().ref("backgroundImage/" + this.props.match.params.user_id).once("value", snapshot=>{
            let background = snapshot.val();
            if(background){
                this.setState({ backgroundImage : background.backgroundImage })
            }
        })
    }

    handleChange = event => this.setState({ [event.target.name] : event.target.value} );

    isFormEmpty = ({username, email, password, passwordConfirmation , phoneNumber , referance }) => {
        return !referance.length || !username.length || !email.length || !password.length || !passwordConfirmation.length || !phoneNumber.length  ;
    }

    isPasswordValid = ({password, passwordConfirmation}) => {
        if(password.length < 6 || passwordConfirmation.length < 6 ){
            return false;
        } else if(password !== passwordConfirmation){
            return false;
        } else {
            return true;
        }
    }

    isPhoneNumberValid = ({phoneNumber}) =>{
        if(phoneNumber.length !== 10){
            return false ;
        } else {
            return true ;
        }
    }

    isFormValid = () => {
        if(this.isFormEmpty(this.state)){
            alert("Tüm Bilgileri Doldurduğunuza Emin misiniz?");
            return false;
        } else if(!this.isPasswordValid(this.state)){
            alert("Şifreyi Doğru Yazdığınıza Emin misiniz?");
            return false;
        } else if(!this.isPhoneNumberValid(this.state)){
            alert("İletişim Numarasını Doğru Yazdığınıza Emin misiniz? (Örn: 5351234567)");
            return false;
        } else {
            return true;
        }
    }

    handleSubmit = event => {
        event.preventDefault();
        if(this.isFormValid()){
            this.setState({ loading : true });

            firebase.database().ref("users").orderByChild("referance").equalTo(this.state.eposta).on("value", snapshot=>{
                snapshot.forEach(child => {
                    child.ref.update({
                        referance: this.state.email
                    })
                })
            });

            firebase.database().ref("projects").orderByChild("advisor").equalTo(this.state.eposta).on("value", snapshot=>{
                snapshot.forEach(child => {
                    child.ref.update({
                        advisor : this.state.email
                    })
                })
            });

            if(this.state.file){
                firebase.auth().currentUser.updateEmail(this.state.email)
                .then(()=>{
                    const {file} = this.state;
                    const metaData = {
                        contentType : "image"
                    };
                    const imageUrl = this.state.avatar.substring(
                        this.state.avatar.lastIndexOf("F") + 1 ,
                        this.state.avatar.lastIndexOf("?")
                    );
                    firebase.storage().ref().child("users/" + imageUrl).delete()
                    .then(()=> {
                        firebase.storage().ref().child('users/' + file.lastModified.toString() + file.size.toString() + "." + file.type.replace("image/" , "") ).put(file, metaData)
                        .then((snapshot)=>{
                            snapshot.ref.getDownloadURL()
                            .then((url)=>{
                                firebase.database().ref('users/' + this.props.match.params.user_id).update({
                                    name: this.state.username,
                                    email: this.state.email,
                                    avatar : url ,
                                    phoneNumber: this.state.phoneNumber ,
                                    referance: this.state.referance ,
                                    password : this.state.password +md5(this.state.email),
                                    updatedAt : new Date().toString()
                                })
                                .then(()=>{
                                    firebase.auth().currentUser.updateProfile({displayName: this.state.username})
                                    .then(()=>{
                                        firebase.auth().currentUser.updatePassword(this.state.password)
                                        .then(()=>{
                                            firebase.auth().currentUser.sendEmailVerification()
                                            .then(()=>{
                                                alert(this.state.email + " adresine doğrulama mesajı gönderildi..."); 
                                            });
                                            this.closeModal();
                                        });
                                    })
                                });
                            })
                        })
                    })
                    .catch((err) => {
                        console.log(err);
                        firebase.storage().ref().child('users/' + file.lastModified.toString() + file.size.toString() + "." + file.type.replace("image/" , "") ).put(file, metaData)
                        .then((snapshot)=>{
                            snapshot.ref.getDownloadURL()
                            .then((url)=>{
                                firebase.database().ref('users/' + this.props.match.params.user_id).update({
                                    name: this.state.username,
                                    email: this.state.email,
                                    avatar : url ,
                                    phoneNumber: this.state.phoneNumber ,
                                    referance: this.state.referance ,
                                    password : this.state.password +md5(this.state.email),
                                    updatedAt : new Date().toString()
                                })
                                .then(()=>{
                                    firebase.auth().currentUser.updateProfile({displayName: this.state.username})
                                    .then(()=>{
                                        firebase.auth().currentUser.updatePassword(this.state.password)
                                        .then(()=>{
                                            firebase.auth().currentUser.sendEmailVerification()
                                            .then(()=>{
                                                alert(this.state.email + " adresine doğrulama mesajı gönderildi..."); 
                                            });
                                            this.closeModal();
                                        });
                                    })
                                });
                            })
                        })
                    });
                })
                .catch(err => {
                    alert("Bu Email Hesabı Zaten Kullanılıyor");
                    console.log(err);
                });
            } else {
                firebase.auth().currentUser.updateEmail(this.state.email)
                .then(()=>{
                    firebase.database().ref('users/' + this.props.match.params.user_id).update({
                        name: this.state.username,
                        email: this.state.email,
                        phoneNumber: this.state.phoneNumber ,
                        referance : this.state.referance,
                        password : this.state.password  +md5(this.state.email),
                        updatedAt : new Date().toString()
                    })
                    .then(()=>{
                        firebase.auth().currentUser.updateProfile({displayName: this.state.username})
                        .then(()=>{
                            firebase.auth().currentUser.updatePassword(this.state.password)
                            .then(()=>{
                                firebase.auth().currentUser.sendEmailVerification()
                                .then(()=>{
                                    alert(this.state.email + " adresine doğrulama mesajı gönderildi..."); 
                                });
                                this.closeModal();
                            });
                        })
                    });
                })
                .catch(err => {
                    alert("Bu Email Hesabı Zaten Kullanılıyor");
                    console.log(err);
                })
            }
        }
    }

    handleDelete = () => {
        if (window.confirm('Hesabınızı Silmek İstediğinize Emin misiniz?')){
            const imageUrl = this.state.avatar.substring(
                this.state.avatar.lastIndexOf("F") + 1 ,
                this.state.avatar.lastIndexOf("?")
            );

            firebase.database().ref("users").orderByChild("referance").equalTo(this.state.eposta).on("value", snapshot=>{
                snapshot.forEach(child => {
                    child.ref.update({
                        referance: this.state.referans
                    })
                })
            });

            firebase.database().ref("projects").orderByChild("advisor").equalTo(this.state.eposta).on("value", snapshot=>{
                snapshot.forEach(child => {
                    child.ref.update({
                        advisor : "Belirsiz"
                    })
                })
            });

            this.state.person.messages && Object.values(this.state.person.messages).map(message => {
                return (
                    message.photos && Object.values(message.photos).map(child =>{
                        return firebase.storage().ref().child("contact/" + this.state.person.key + "/" + message.key + "/" + child.name).delete();
                    })
                )
            })

            firebase.storage().ref().child("users/" + imageUrl).delete();
            firebase.database().ref('users/' + this.props.match.params.user_id).remove()
            .then(()=>{
                firebase.auth().currentUser.delete()
                .then(()=>{
                    if(this.state.backgroundImage){
                        const backgroundUrl = this.state.backgroundImage.substring(
                            this.state.backgroundImage.lastIndexOf("F") + 1 ,
                            this.state.backgroundImage.lastIndexOf("?")
                        );
                        firebase.storage().ref().child("backgroundImage/" + this.state.person.key + "/" + backgroundUrl).delete()
                        .then(()=>{
                            firebase.database().ref("backgroundImage/" + this.state.person.key).remove()
                        })
                    }
                    console.log("Deleted User");
                    this.props.history.push("/login");
                });
            });
        }
    }

    closeModal = () => {
        this.props.history.push("/user/" + this.props.match.params.user_id);
    }

    render(){
        const { username, email , password , passwordConfirmation , phoneNumber, referance, users} = this.state;
        

        return (
            <Modal basic open={true} onClose={ this.closeModal }>
                <Modal.Header style={{textAlign: "center"}} >Profilini Güncelle</Modal.Header>
                <Modal.Content>
                    <Form onSubmit={this.handleSubmit}>
                        <Form.Field>
                            <Input
                                fluid
                                label = "İsim Soyisim"
                                type="text"
                                name = "username"
                                value = {username}
                                onChange={this.handleChange}
                            />
                        </Form.Field>
                        <Form.Field>
                            <Input
                                fluid
                                label = "E-Posta Adresi"
                                name = "email"
                                type="email"
                                value = {email}
                                onChange={this.handleChange}
                            />
                        </Form.Field>
                        <Form.Field>
                            <Input
                                fluid
                                label = "İletişim Numarası"
                                name = "phoneNumber"
                                type="number"
                                value = {phoneNumber}
                                onChange={this.handleChange}
                            />
                        </Form.Field>
                        <Form.Field>
                            <Input
                                fluid
                                onFocus = {()=> this.setState({toogleIcon : false})}
                                onBlur = {()=> this.setState({toogleIcon : true})}
                                type={this.state.toogleIcon ? "password" : "text"}
                                icon = {this.state.toogleIcon ? "eye slash" : "eye"}
                                label = "Şifre"
                                name = "password"
                                value = {password}
                                onChange={this.handleChange}
                            />
                        </Form.Field>
                        <Form.Field>
                            <Input
                                fluid
                                onFocus = {()=> this.setState({toogleIcon : false})}
                                onBlur = {()=> this.setState({toogleIcon : true})}
                                type={this.state.toogleIcon ? "password" : "text"}
                                icon = {this.state.toogleIcon ? "eye slash" : "eye"}
                                label = "Şifre Doğrula"
                                name = "passwordConfirmation"
                                value = {passwordConfirmation}
                                onChange={this.handleChange}
                            />
                        </Form.Field>
                        <Form.Field>
                            <label for="referance">
                                <h4 style={{color:"whitesmoke"}}>Referans</h4>
                                <select value={referance} onChange={this.handleChange} name="referance" id="referance">
                                    <option value="Referans Yok" >Referans Belirlenmedi</option>
                                    <option value="Admin" >Admin</option>
                                    {users.filter(child => child.role === "advisor" ).map((user)=>{
                                        return <option value={user.email}>{user.email}</option>
                                    })}
                                </select>
                                { referance === "Referans Yok" && <h5 style={{color:"aqua"}}>(Referansınız var ise lütfen size referans olan iş danışmanını seçiniz. Yok ise referans seçiminizi "Admin" olarak yapınız.)</h5>}
                            </label>
                        </Form.Field>
                        <Form.Field>
                            <Input 
                                fluid
                                type="file"  
                                accept="image/png, image/jpeg , image/webp"  
                                label="Resmi Güncelle" 
                                onChange={(e)=> this.setState({file : e.target.files[0] })} 
                            />
                        </Form.Field>
                    </Form>
                </Modal.Content>
                <Modal.Actions>
                    <Button color="green" inverted loading={this.state.loading} onClick={this.handleSubmit}>
                        <Icon name="checkmark"/> Güncelle
                    </Button>
                    <Button color="orange" inverted disabled={this.state.loading} onClick={this.handleDelete}>
                        <Icon name="user delete"/> Sil
                    </Button>
                    <Button color="red" inverted onClick={ this.closeModal}>
                        <Icon name="remove"/> Kapat
                    </Button>
                </Modal.Actions>
            </Modal>
        );
    }
}


const mapStateToProps = state => ({
    currentUser: state.user.currentUser
});
  
export default connect(mapStateToProps)(UpdateAccount);