import React, {useState, useEffect} from  "react";
import { Icon, Button, Modal, Form, TextArea , Progress} from "semantic-ui-react";
import {useHistory, useParams} from "react-router-dom";
import {connect} from "react-redux";
import firebase from "../firebase";
import Recaptcha from "react-recaptcha";

const SendMessage = ({currentUser}) => {
    let {advisorID} = useParams();
    let history = useHistory();
    const [username, setUsername] = useState("");
    const [email, setEmail] = useState("");
    const [phoneNumber, setPhoneNumber] = useState("");
    const [message, setMessage] = useState("");
    const [files, setFiles] = useState([]);
    const [percent , setPercent] = useState(0);
    const [loading, setLoading] = useState(false);
    const [isVerified , setIsVerified] = useState(false);
    let messageId = Math.floor(Math.random() * 1000000000);

    useEffect(()=>{
        if(currentUser.uid !== 1234567890 ){
            setUsername(currentUser.displayName);
            setEmail(currentUser.email);
        }
    },[currentUser.uid , currentUser.displayName, currentUser.email]);
    

    const isFormEmpty = ({username, email, phoneNumber, message }) => {
        return !username.length || !email.length || !message.length || !phoneNumber.length  ;
    }

    const isPhoneNumberValid = ({phoneNumber}) =>{
        if(phoneNumber.length !== 10){
            return false ;
        } else {
            return true ;
        }
    }

    const isFormValid = () => {
        if(isFormEmpty({username, email, phoneNumber, message })){
            alert("Tüm Bilgileri Doldurduğunuza Emin misiniz?");
            return false;
        } else if(!isPhoneNumberValid({phoneNumber})){
            alert("İletişim Numarasını Doğru Yazdığınıza Emin misiniz? (Örn: 5351234567)");
            return false;
        } else if(!isVerified){
            alert("ReCaptcha İle Robot Olmadığınızı Doğrulayınız...");
            return false;
        } else {
            return true;
        }
    }

    const handleSubmit = async event => {
        event.preventDefault();
        if(isFormValid()){
            let messageID = messageId ;
            setLoading(true);
            if(files){
                const photo = [];
                for( let i = 0; i< files.length; i++){
                    const file = files[i];
                    let img_ID = file.lastModified.toString() + file.size.toString() ;
                    let img_name = file.lastModified.toString() + file.size.toString() + "." + file.type.replace("image/" , "") ;
                    let profilPhoto = i === 0 ? true : false ;
                    const metaData = {
                        contentType : "image"
                    };
                    const percentIncrement = 100 / files.length ;
                    await firebase.storage().ref().child('contact/' + advisorID + "/" + messageID + "/" + img_name ).put(file, metaData)
                    .then((snapshot)=>{
                        snapshot.ref.getDownloadURL()
                        .then((url)=>{
                            photo[img_ID] = {photo: url, key: img_ID, profilPhoto: profilPhoto , name: img_name };
                            setPercent( prevPercent => prevPercent + percentIncrement);
                        })
                    })
                }
                setTimeout(() => {
                    firebase.database().ref("users/"+ advisorID + "/messages/" + messageID).set({
                        username : username,
                        email : email , 
                        phoneNumber : phoneNumber , 
                        message : message ,
                        key : messageID,
                        createdAt : new Date().toString(),
                        emailVerified : currentUser.emailVerified,
                        role : currentUser.role ,
                        read : false ,
                        photos: photo
                    })
                    .then(()=>{
                        setUsername("");
                        setPhoneNumber("");
                        setEmail("");
                        setMessage("");
                        setTimeout(()=>{
                            alert("Mesajınız iletilmiştir...");
                            setLoading(false);
                        },1000);
                    })
                    .catch(err =>{
                        console.log(err);
                        setLoading(false);
                        alert("Mesajınız iletilirken bir hata meydana geldi. Lütfen tekrar deneyiniz...");
                    });
                }, 2000);
            } else {
                firebase.database().ref("users/"+ advisorID + "/messages/" + messageID).set({
                    username : username,
                    email : email , 
                    phoneNumber : phoneNumber , 
                    message : message ,
                    key : messageID,
                    createdAt : new Date().toString(),
                    emailVerified : currentUser.emailVerified,
                    role : currentUser.role ,
                    read : false 
                })
                .then(()=>{
                    setUsername("");
                    setPhoneNumber("");
                    setEmail("");
                    setMessage("");
                    setTimeout(()=>{
                        alert("Mesajınız iletilmiştir...");
                        setLoading(false);
                    },1000);
                })
                .catch(err =>{
                    console.log(err);
                    setLoading(false);
                    alert("Mesajınız iletilirken bir hata meydana geldi. Lütfen tekrar deneyiniz...");
                });
            }

            if(currentUser.uid !== 1234567890 ){
                firebase.database().ref("users/" + currentUser.uid).update({
                    phoneNumber: phoneNumber,
                    updatedAt : new Date().toString()
                });
            }
          }
    }

    const closeModal = () => {
        history.push("/advisors");
    }

    const recaptchaLoaded = () => {
        console.log("Recaptcha Loaded successfully...");
    }

    const verifyCallback = (response) => {
        if(response){
            setIsVerified(true);
        }
    }

    return (
        <Modal basic open={true} onClose={ closeModal }>
            <Modal.Header style={{textAlign:"center"}}>
            Mesaj Gönder
            </Modal.Header>
            <Modal.Content>
                <Form onSubmit={handleSubmit} >
                        <Form.Input 
                            fluid 
                            name="username" 
                            icon= "user" 
                            disabled = {currentUser.uid === 1234567890 ? false : true}
                            type="text" 
                            iconPosition="left"
                            placeholder="İsim Soyisim" 
                            value = {username} 
                            onChange={e =>setUsername(e.target.value)}                            
                        />
                        <Form.Input 
                            fluid 
                            name="email"
                            icon="mail"
                            disabled = {currentUser.uid === 1234567890 ? false : true}
                            iconPosition="left"
                            placeholder="E-Posta Adresi" 
                            onChange={e =>setEmail(e.target.value)} 
                            value = {email}
                            type="email"
                        />
                        <Form.Input 
                            fluid 
                            name="phoneNumber"
                            icon="phone"
                            iconPosition="left"
                            placeholder="İletişim Numarası" 
                            onChange={e =>setPhoneNumber(e.target.value)} 
                            value = {phoneNumber} 
                            type="number"
                        />
                        <TextArea
                            fluid
                            type="text"
                            name ="message"
                            placeholder='Mesajınız...' 
                            onChange={e =>setMessage(e.target.value)} 
                            value = {message}
                        />
                        <Form.Input 
                            fluid
                            type="file"  
                            title="Resim Yükle (PDF yollamak için mail atınız.)" 
                            multiple
                            accept="image/png, image/jpeg , image/webp" 
                            style={{marginTop:"13px"}}
                            onChange={(e)=> setFiles(e.target.files) } 
                        />
                        <br/>
                        { percent === 0 || percent === 100 ? "" : <Progress percent={percent} indicating />}
                        { percent === 100 ? <Progress percent={100} success> <b style={{color:"white"}}>Resim Yükleme Başarılı...</b> <Icon name="check" color="green" size="large" /> </Progress> : "" }
                        { percent === 0 ? <Progress percent={percent} indicating style={{visibility: "hidden"}} /> : "" }
                </Form>
                <Recaptcha
                      sitekey="6LfoznkaAAAAABtdD1mF_q8m8mUoV66M0G0owWFj"
                      render="explicit"
                      onloadCallback={recaptchaLoaded}
                      verifyCallback={verifyCallback}
                      hl="tr"
                      theme="dark"
                />
            </Modal.Content>
            <Modal.Actions>
                <Button color="green" loading={loading} disabled={percent < 100 && percent > 0 ? true : false } inverted onClick={handleSubmit}>
                    <Icon name="checkmark"/> Gönder
                </Button>
                <Button color="red" inverted onClick={closeModal}>
                    <Icon name="remove"/> Kapat
                </Button>
            </Modal.Actions>
        </Modal>
    );
}

const mapStateToProps = state => ({
    currentUser: state.user.currentUser
});
  
export default connect(mapStateToProps)(SendMessage);