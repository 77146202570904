import React,{useState, useEffect} from  "react";
import {connect} from "react-redux";
import {useHistory} from "react-router-dom";
import firebase from "../../firebase";
import {Modal, Form, Button, Icon, Input} from "semantic-ui-react";

const UpdateBackgroundImage = ({currentUser}) => {
    const history = useHistory();
    const [backgroundImage, setBackgroundImage] = useState("");
    const [exBackgroundImage, setExBackgroundImage] = useState("");
    const [loading, setLoading] = useState(false);

    useEffect(()=>{
        if( currentUser.role !== "admin" ){
            history.push("/");
        }
    },[ currentUser.role, history ]);

    useEffect(() => {
        firebase.database().ref("backgroundImage/admin").once("value", snapshot => {
            let background = snapshot.val();
            if(background){
                setExBackgroundImage(background.backgroundImage)
            }
        })
    }, []);

    const closeModal = () => {
        history.push("/admin");
    }

    const handleSubmit = event => {
        event.preventDefault();
        if(backgroundImage){
            const metaData = {
                contentType : "image"
            };
            const imageUrl = exBackgroundImage.substring(
                exBackgroundImage.lastIndexOf("F") + 1 ,
                exBackgroundImage.lastIndexOf("?")
            );
            setLoading(true);
            firebase.storage().ref().child("backgroundImage/admin/" + imageUrl).delete()
            .then(()=>{
                firebase.storage().ref().child('backgroundImage/admin/' + backgroundImage.lastModified.toString() + backgroundImage.size.toString() + "." + backgroundImage.type.replace("image/" , "") ).put(backgroundImage, metaData)
                .then(snapshot => {
                    snapshot.ref.getDownloadURL()
                    .then((url)=>{
                        firebase.database().ref("backgroundImage/admin").set({
                            backgroundImage : url
                        })
                        .then(()=>{
                            alert("Arka Plan Resminiz Güncellendi...");
                            setLoading(false);
                        })
                    })
                })
            })
            .catch((err)=>{
                console.log(err);
                firebase.storage().ref().child('backgroundImage/admin/' + backgroundImage.lastModified.toString() + backgroundImage.size.toString() + "." + backgroundImage.type.replace("image/" , "") ).put(backgroundImage, metaData)
                .then(snapshot => {
                    snapshot.ref.getDownloadURL()
                    .then((url)=>{
                        firebase.database().ref("backgroundImage/admin").set({
                            backgroundImage : url
                        })
                        .then(()=>{
                            alert("Arka Plan Resminiz Güncellendi...");
                            setLoading(false);
                        })
                    })
                })
            })
        } else {
            alert("Lütfen Arka Plan Yapmak İstediğiniz Resmi Seçiniz...");
        }
    }

    const handleDelete = event => {
        event.preventDefault();
        if (window.confirm('Mevcut Resmi Silmek İstediğinize Emin misiniz?')){
            const imageUrl = exBackgroundImage.substring(
                exBackgroundImage.lastIndexOf("F") + 1 ,
                exBackgroundImage.lastIndexOf("?")
            );
            firebase.storage().ref().child("backgroundImage/admin/" + imageUrl).delete()
            .then(()=>{
                firebase.database().ref("backgroundImage/admin/").remove()
                .then(()=>{
                    alert("Orjinal Resim Yüklendi. Zevkimize Güvendiğiniz İçin Teşekkür Ederiz...");
                })
            })
        }
    }

    return (
        <Modal basic open={true} onClose={closeModal}>
            <Modal.Header style={{textAlign: "center"}} >Arka Plan Resmini Güncelle</Modal.Header>
            <Modal.Content>
                <Form >
                    <Form.Field>
                        <Input
                            label="Arka Plan Resmi"
                            type="file"
                            accept="image/png, image/jpeg , image/webp"
                            name ="backgroundImage"
                            onChange={e => setBackgroundImage(e.target.files[0])}
                        />
                    </Form.Field>
                </Form>
            </Modal.Content>
            <Modal.Actions>
                <Button loading={loading} disabled={loading} color="green" inverted onClick={handleSubmit}>
                    <Icon name="checkmark"/> Güncelle
                </Button>
                { exBackgroundImage && <Button color="orange" inverted onClick={handleDelete}>
                    <Icon name="image outline"/> Orjinal Resmi Yükle
                </Button>}
                <Button color="red" inverted onClick={closeModal}>
                    <Icon name="remove"/> Kapat
                </Button>
            </Modal.Actions>
        </Modal>
    )
}

const mapStateToProps = state => ({
    currentUser: state.user.currentUser
});
  
export default connect(mapStateToProps)(UpdateBackgroundImage);