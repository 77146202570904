import React, {useState, useEffect} from "react";
import {connect} from "react-redux";
import {
    Grid,
    Segment,
    Form,
    Button,
    TextArea,
    Divider,
    Header,
    Icon ,
    Progress
} from 'semantic-ui-react';
import firebase from "../firebase";
import Map from "./Map";
import Recaptcha from "react-recaptcha";

const Contact = ({currentUser}) => {
        const [message, setMessage] = useState("");
        const [email, setEmail] = useState("");
        const [username, setUsername] = useState("");
        const [phoneNumber, setPhoneNumber] = useState("");
        const [mobile, setMobile] = useState("");
        const [phone, setPhone] = useState("");
        const [percent , setPercent] = useState(0);
        const [files, setFiles] = useState([]);
        const [loading, setLoading] = useState(false);
        const [isVerified , setIsVerified] = useState(false);
        let messageId = Math.floor(Math.random() * 1000000000);

        useEffect(()=>{
            if(currentUser.uid !== 1234567890){
                setUsername(currentUser.displayName);
                setEmail(currentUser.email);
            }
        },[currentUser.uid, currentUser.displayName, currentUser.email]);

        useEffect(()=>{
            firebase.database().ref("contact").on("value", (snapshot)=>{
                if(snapshot.val()){
                    setMobile(snapshot.val().mobile);
                    setPhone(snapshot.val().phone);
                }
            })
        },[]);

        const isFormEmpty = ({username, email, phoneNumber, message }) => {
            return !username.length || !email.length || !message.length || !phoneNumber.length  ;
        }

        const isPhoneNumberValid = ({phoneNumber}) =>{
            if(phoneNumber.length !== 10){
                return false ;
            } else {
                return true ;
            }
        }

        const isFormValid = () => {
            if(isFormEmpty({username, email, phoneNumber, message })){
                alert("Tüm Bilgileri Doldurduğunuza Emin misiniz?");
                return false;
            } else if(!isPhoneNumberValid({phoneNumber})){
                alert("İletişim Numarasını Doğru Yazdığınıza Emin misiniz? (Örn: 5351234567)");
                return false;
            } else if(!isVerified){
                alert("ReCaptcha İle Robot Olmadığınızı Doğrulayınız...");
                return false;
            } else {
                return true;
            }
        }

        const handleSubmit = async event =>{
          event.preventDefault();
          if(isFormValid()){
            const messageID = messageId;
            setLoading(true);
            if(files){
                const photo = [];
                for( let i = 0; i< files.length; i++){
                    const file = files[i];
                    let img_ID = file.lastModified.toString() + file.size.toString();
                    let img_name = file.lastModified.toString() + file.size.toString() + "." + file.type.replace("image/" , "") ;
                    let profilPhoto = i === 0 ? true : false ;
                    const metaData = {
                        contentType : "image"
                    };
                    const percentIncrement = 100 / files.length ;
                    await firebase.storage().ref().child('contact/admin/' + messageID + "/" + img_name ).put(file, metaData)
                    .then((snapshot)=>{
                        snapshot.ref.getDownloadURL()
                        .then((url)=>{
                            photo[img_ID] = {photo: url, key: img_ID, profilPhoto: profilPhoto , name: img_name };
                            setPercent( prevPercent => prevPercent + percentIncrement);
                        })
                    })
                }
                setTimeout(() => {
                    firebase.database().ref("messages/" + messageID).set({
                        username : username,
                        email : email , 
                        phoneNumber : phoneNumber , 
                        message : message ,
                        key : messageID,
                        createdAt : new Date().toString(),
                        emailVerified : currentUser.emailVerified,
                        role : currentUser.role,
                        read : false ,
                        photos: photo
                    })
                    .then(()=>{
                        setUsername("");
                        setPhoneNumber("");
                        setEmail("");
                        setMessage("");
                        setTimeout(()=>{
                            setLoading(false);
                            alert("Mesajınız tarafımıza ulaşmıştır. Size en kısa zamanda geri dönüş yapmaya çalışacağız...");
                        },1000);
                    })
                    .catch(err =>{
                        console.log(err);
                        setLoading(false)
                        alert("Mesajınız iletilirken bir hata meydana geldi. Lütfen tekrar deneyiniz...");
                    });
                }, 2000);
            } else {
                firebase.database().ref("messages/" + messageID).set({
                    username : username,
                    email : email , 
                    phoneNumber : phoneNumber , 
                    message : message ,
                    key : messageID,
                    createdAt : new Date().toString(),
                    emailVerified : currentUser.emailVerified,
                    role : currentUser.role,
                    read : false 
                })
                .then(()=>{
                    setUsername("");
                    setPhoneNumber("");
                    setEmail("");
                    setMessage("");
                    setTimeout(()=>{
                        setLoading(false);
                        alert("Mesajınız tarafımıza ulaşmıştır. Size en kısa zamanda geri dönüş yapmaya çalışacağız...");
                    },1000);
                })
                .catch(err =>{
                    console.log(err);
                    setLoading(false);
                    alert("Mesajınız iletilirken bir hata meydana geldi. Lütfen tekrar deneyiniz...");
                });
            }
            
            if(currentUser.uid !== 1234567890 ){
                firebase.database().ref("users/" + currentUser.uid).update({
                    phoneNumber: phoneNumber,
                    updatedAt : new Date().toString()
                });
            }
          }
        }

        const recaptchaLoaded = () => {
            console.log("Recaptcha Loaded successfully...");
        }

        const verifyCallback = (response) => {
            if(response){
                setIsVerified(true);
            }
        }

        return (
        <div className="height" style={{background: "#eee" , overflowY:"auto", width:"100vw", overflowX:"hidden"}}>
        <Grid textAlign="center" >
          <Grid.Column computer={16} tablet={16} mobile={16} textAlign="center" color="teal"  style={{height:"75px"}}>
            <h1 style={{marginTop:"10px" , textDecoration:"underline", textShadow:"0 0 2px black"}}>İletişim</h1>
          </Grid.Column>
          <Grid.Column computer={10} tablet={16} mobile={16}>
            <Header style={{textAlign: "center", color:"salmon",textDecoration:"underline"}}>İletişim Bilgilerimiz</Header>
            <div style={{textAlign:"left",padding:"20px",width:"80%",margin:"0 auto"}}>
                <Icon name="map marker alternate" color="teal" size="large" /> <p style={{display:"inline"}}>Sanayi Mh, 60018. Cd. No:111, 27110 Şehitkamil/Gaziantep</p><br/>
                <br/>
                <Icon name="mobile alternate" color="teal" size="large" /> <p style={{display:"inline"}}>{mobile}</p><br/>
                <br/>
                <Icon name="phone" color="teal" size="large" /> <p style={{display:"inline"}}>{phone}</p><br/>
                <br/>
                <Icon name="mail outline" color="teal" size="large" /> <p style={{display:"inline"}}>engismailercan@gmail.com</p><br/>
                <br/>
                <Icon name="mail outline" color="teal" size="large" /> <p style={{display:"inline"}}>info@yapsat.org</p><br/>
                <br/>
                <section style={{textAlign:"center", margin:"0 auto"}}>
                    <a href="https://api.whatsapp.com/send?phone=905323083053" rel="noreferrer" target="_blank"><Icon name="whatsapp square" color="green" size="huge" /></a>
                    <a href="https://twitter.com/iemtto" rel="noreferrer" target="_blank"><Icon name="twitter square" color="blue" size="huge" /></a>
                    <a href="https://www.facebook.com/iemtto" rel="noreferrer" target="_blank"><Icon name="facebook" color="blue" size="huge" /></a>
                    <a href="https://www.instagram.com/iemtto" rel="noreferrer" target="_blank"><Icon name="instagram" color="black" size="huge" /></a>
                    <a href="https://www.youtube.com/channel/UCCzkPvR_A5XM_2lGUyICrcA" rel="noreferrer" target="_blank"><Icon name="youtube" color="red" size="huge" /></a>
                </section>
            </div>
            <Map/>
            <Divider/>
            <Header style={{textAlign: "center", color:"salmon",textDecoration:"underline"}}>İletişim Formu</Header>
            <Form size="large" onSubmit={handleSubmit} >
              <Segment stacked >
                  <Form.Input 
                      fluid 
                      name="username" 
                      icon= "user" 
                      disabled = {currentUser.uid === 1234567890 ? false : true}
                      type="text" 
                      iconPosition="left"
                      placeholder="İsim Soyisim" 
                      onChange={e =>setUsername(e.target.value)} 
                      value={username} 
                  />
                  <Form.Input 
                      fluid 
                      name="email"
                      icon="mail"
                      disabled = { currentUser.uid === 1234567890 ? false : true }
                      iconPosition="left"
                      placeholder="E-Posta Adresi" 
                      onChange={e =>setEmail(e.target.value)} 
                      type="email"
                      value={email} 
                  />
                  <Form.Input 
                      fluid 
                      name="phoneNumber"
                      icon="phone"
                      iconPosition="left"
                      placeholder="İletişim Numarası" 
                      onChange={e =>setPhoneNumber(e.target.value)}  
                      type="number"
                      value={phoneNumber} 
                  />
                  <TextArea
                      fluid
                      type="text"
                      name ="message"
                      placeholder='Mesajınız...' 
                      value={message}
                      onChange={e =>setMessage(e.target.value)} 
                  />
                  <Form.Input 
                      fluid
                      type="file"  
                      title="Resim Yükle (PDF yollamak için mail atınız.)" 
                      multiple
                      accept="image/png, image/jpeg , image/webp" 
                      style={{marginTop:"13px"}}
                      onChange={(e)=> setFiles(e.target.files) } 
                  />
                  { percent === 0 || percent === 100 ? "" : <Progress percent={percent} indicating />}
                  { percent === 100 ? <Progress percent={100} success> <b style={{color:"darkgreen"}}>Resim Yükleme Başarılı...</b> <Icon name="check" color="green" size="large" /> </Progress> : "" }
                  { percent === 0 ? <Progress percent={percent} indicating style={{visibility: "hidden"}} /> : "" }
                  <Button color="linkedin" loading={loading} disabled={percent < 100 && percent > 0 ? true : false } size="large" fluid >Gönder</Button>
                  <Recaptcha
                      sitekey="6LfoznkaAAAAABtdD1mF_q8m8mUoV66M0G0owWFj"
                      render="explicit"
                      onloadCallback={recaptchaLoaded}
                      verifyCallback={verifyCallback}
                      hl="tr"
                      elementID="recaptcha"
                  />
              </Segment>
            </Form>
            <br/>
          </Grid.Column>
        </Grid>
        </div>
        )    
}


const mapStateToProps = state => ({
    currentUser: state.user.currentUser
});
  
export default connect(mapStateToProps)(Contact);
