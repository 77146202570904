import * as actionTypes from "../actions/types";
import {combineReducers} from "redux";

const initialUserState = {
    currentUser : {
        displayName : "Girişimci",
        role : "entrepreneur",
        password : "a123456a" ,
        referance : "Referans Yok",
        uid : 1234567890 ,
        email : "user@user.com",
        emailVerified : false ,
        createdAt : new Date().toString(),
        updatedAt : new Date().toString()
    },
    isLoading : true
}

const user_reducer = (state = initialUserState, action) => {
    switch(action.type){
        case actionTypes.SET_USER:
            return {
                currentUser: action.payload.currentUser,
                isLoading: false
            }
        case actionTypes.CLEAR_USER:
            return {
                ...initialUserState,
                isLoading: false
            }
        default:
            return state;
    }
}



const rootReducer = combineReducers({
    user : user_reducer
});

export default rootReducer;