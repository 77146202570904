import React from "react";
import { Grid, Form, Segment, Button, Header, Message, Icon } from "semantic-ui-react";
import { Link } from "react-router-dom";
import firebase from "../../firebase";
import {connect} from "react-redux";

class Login extends React.Component {
    state = {
        email : "",
        password : "" ,
        errors: [],
        loading: false ,
        toogleIcon : true
    }

    componentDidMount(){
        if(this.props.currentUser.uid !== 1234567890){
            this.props.history.push("/");
        }
    }

    handleChange = event => {
        this.setState({ [event.target.name] : event.target.value });
    }

    displayErrors = errors => errors.map((error, i) => <p key={i}>{error.message}</p>);

    handleSubmit = event => {
        event.preventDefault();
        if(this.isFormValid(this.state)) {
            this.setState({errors:[], loading:true});
            firebase.auth().signInWithEmailAndPassword(this.state.email, this.state.password)
            .then(signedInUser => {
                console.log(signedInUser);
                setTimeout(() => {
                    this.props.history.push("/");
                }, 3);
            })
            .catch(err => {
                console.log(err);
                err.message = "E-Posta Adresi ya da Şifre Hatalı...";
                this.setState({
                    errors: this.state.errors.concat(err),
                    loading: false
                });
            });
        }
    }

    isFormValid = ({email, password}) => email && password;

    handleInputError = (errors, inputName) => {
        return errors.some(error => error.message.toLowerCase().includes(inputName)) ? "error" : "" ;
    }

    render(){
        const { email, password, errors, loading } = this.state;

        return (
            <Grid textAlign="center" verticalAlign="middle" className="app">
                <Grid.Column style={{maxWidth: 450}}>
                    <Header as="h1" icon color="violet" textAlign="center">
                        <Icon name="code branch" color="violet"/>
                        YAPSAT İçin Oturum Aç 
                    </Header>
                    <Form size="large" onSubmit={this.handleSubmit}>
                        <Segment stacked>
                            <Form.Input 
                                fluid 
                                name="email"
                                icon="mail"
                                iconPosition="left"
                                placeholder="E-Posta Adresi" 
                                onChange={this.handleChange} 
                                type="email"
                                value={email} 
                                className={this.handleInputError(errors, "e-posta")}
                            />
                            <Form.Input 
                                fluid 
                                name="password" 
                                icon= "lock" 
                                onFocus = {()=> this.setState({toogleIcon : false})}
                                onBlur = {()=> this.setState({toogleIcon : true})}
                                type={this.state.toogleIcon ? "password" : "text"} 
                                iconPosition="left"
                                placeholder="Şifre" 
                                onChange={this.handleChange} 
                                value={password} 
                                className={this.handleInputError(errors, "şifre")}
                            />
                            <Button disabled={loading} className={loading ? "loading" : ""} color="violet" fluid size="large">Gönder</Button>
                        </Segment>
                    </Form>
                    {errors.length > 0 && (
                        <Message error>
                            <h3><u>Hata</u></h3>
                            {this.displayErrors(errors)}
                        </Message>
                    )}
                    <Message>
                        Daha Önce Kayıt Olmadın mı? {" "}
                        <Link to="/register">Kayıt Ol</Link>
                        <br/>
                        <Link to="/">Oturum Açmadan Devam Et.</Link>
                    </Message>
                </Grid.Column>
            </Grid>
        );
    }
}

const mapStateToProps = state => ({
    currentUser: state.user.currentUser
});
  
export default connect(mapStateToProps)(Login);