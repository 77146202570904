import React , {useState, useEffect} from  "react";
import {useHistory} from "react-router-dom";
import {connect} from "react-redux";
import firebase from "../../firebase";
import { Modal , Form , Button , Icon , Image , Card } from "semantic-ui-react";

const AdvisorCard = ({advisor}) => {
    return (
        <Card key={advisor.key} style={{margin:"0 auto", padding:"10px" , textAlign:"center"}}>
            <Image src={advisor.avatar} wrapped ui={false} className="advisorImage" />
            <Card.Content>
                <Card.Description>
                    <strong>Email: </strong>{advisor.email}
                    <br/>
                    <strong>Tel: </strong>{advisor.phoneNumber}
                </Card.Description>
            </Card.Content>
        </Card>
    )
}

const AdvisorsWeb = ({currentUser}) => {
    const history = useHistory();
    const [advisors, setAdvisors] = useState([]);
    const [referance, setReferance] = useState("");
    const [firstLevel , setFirstLevel] = useState("");
    const [secondLevel , setSecondLevel] = useState("");
    const [thirdLevel , setThirdLevel] = useState("");
    const [fourthLevel , setFourthLevel] = useState("");
    const [fifthLevel , setFifthLevel] = useState("");
    const [sixthLevel , setSixthLevel] = useState("");
    const [seventhLevel , setSeventhLevel] = useState("");
    const [eighthLevel , setEighthLevel] = useState("");
    const [ninthLevel , setNinthLevel] = useState("");
    const [tenthLevel , setTenthLevel] = useState("");
    const [eleventhLevel , setEleventhLevel] = useState("");
    const [twelfthLevel , setTwelfthLevel] = useState("");
    const [thirteenthLevel , setThirteenthLevel] = useState("");
    const [fourteenthLevel , setFourteenthLevel] = useState("");
    const [fifteenthLevel , setFifteenthLevel] = useState("");
    const [sixteenthLevel , setSixteenthLevel] = useState("");
    const [seventeenthLevel , setSeventeenthLevel] = useState("");
    const [eighteenthLevel , setEighteenthLevel] = useState("");
    const [nineteenthLevel , setNineteenthLevel] = useState("");
    const [twentiethLevel , setTwentiethLevel] = useState("");

    useEffect(()=>{
        return currentUser.role !== "admin" ? history.push("/") : null
    });

    useEffect(() => {
        firebase.database().ref("users").orderByChild("role").equalTo("advisor").on("value", snapshot => {
            if(snapshot.val()){
                let snap = Object.values(snapshot.val());
                setAdvisors(snap);
            }
        })
    }, []);

    useEffect(() => {
        setFirstLevel("");
    }, [referance]);
    useEffect(() => {
        setSecondLevel("");
    }, [firstLevel]);
    useEffect(() => {
        setThirdLevel("");
    }, [secondLevel]);
    useEffect(() => {
        setFourthLevel("");
    }, [thirdLevel]);
    useEffect(() => {
        setFifthLevel("");
    }, [fourthLevel]);
    useEffect(() => {
        setSixthLevel("");
    }, [fifthLevel]);
    useEffect(() => {
        setSeventhLevel("");
    }, [sixthLevel]);
    useEffect(() => {
        setEighthLevel("");
    }, [seventhLevel]);
    useEffect(() => {
        setNinthLevel("");
    }, [eighthLevel]);
    useEffect(() => {
        setTenthLevel("");
    }, [ninthLevel]);
    useEffect(() => {
        setEleventhLevel("");
    }, [tenthLevel]);
    useEffect(() => {
        setTwelfthLevel("");
    }, [eleventhLevel]);
    useEffect(() => {
        setThirteenthLevel("");
    }, [twelfthLevel]);
    useEffect(() => {
        setFourteenthLevel("");
    }, [thirteenthLevel]);
    useEffect(() => {
        setFifteenthLevel("");
    }, [fourteenthLevel]);
    useEffect(() => {
        setSixteenthLevel("");
    }, [fifteenthLevel]);
    useEffect(() => {
        setSeventeenthLevel("");
    }, [sixteenthLevel]);
    useEffect(() => {
        setEighteenthLevel("");
    }, [seventeenthLevel]);
    useEffect(() => {
        setNineteenthLevel("");
    }, [eighteenthLevel]);
    useEffect(() => {
        setTwentiethLevel("");
    }, [nineteenthLevel]);

    const closeModal = () => {
        history.push("/admin");
    }

    return (
        <Modal basic open={true} onClose={closeModal}>
            <Modal.Header style={{textAlign: "center"}}>İş Danışmanı Ağı</Modal.Header>
            <hr/>
            <Modal.Content>
                <Form autocomplete="off" style={{border:"1px dashed rgba(200, 200, 200, 0.3)",borderRadius:"10px", padding:"20px"}}>
                    <Form.Field>
                        <select value={referance} onChange={ e => setReferance(e.target.value) } name="referance">
                            <option value="" >Ara</option>
                            <option value="Admin" >Tüm Ağı Göster</option>
                            { advisors && advisors.map(child => {
                                return (
                                    <option key={child.key} value={child.email}>{child.name}</option>
                                )
                            })}
                        </select>
                    </Form.Field>
                    { advisors && advisors.filter(child => child.referance === referance )[0] ? <Form.Field>
                        { advisors && advisors.filter(advisor => advisor.email === firstLevel )[0] &&  advisors.filter(advisor => advisor.email === firstLevel ).map(advisor => {
                            return (
                                <AdvisorCard advisor={advisor} />
                            )
                        }) }
                        <select value={firstLevel} onChange={ e => setFirstLevel(e.target.value) } name="firstLevel">
                            <option value="" >Birinci Seviye</option>
                            { advisors && advisors.filter(child => child.referance === referance ).map(child => {
                                return (
                                    <option key={child.key} value={child.email}>{child.name}</option>
                                )
                            })}
                        </select>
                    </Form.Field> : ""}
                    { advisors && advisors.filter(child => child.referance === firstLevel )[0] ? <Form.Field>
                        { advisors && advisors.filter(advisor => advisor.email === secondLevel )[0] &&  advisors.filter(advisor => advisor.email === secondLevel ).map(advisor => {
                            return (
                                <AdvisorCard advisor={advisor} />
                            )
                        }) }
                        <select value={secondLevel} onChange={ e => setSecondLevel(e.target.value) } name="secondLevel">
                            <option value="" >İkinci Seviye</option>
                            { advisors && advisors.filter(child => child.referance === firstLevel )[0] && advisors.filter(child => child.referance === firstLevel ).map(child => {
                                return (
                                    <option key={child.key} value={child.email}>{child.name}</option>
                                )
                            })}
                        </select>
                    </Form.Field> : "" }
                    { advisors && advisors.filter(child => child.referance === secondLevel )[0] ? <Form.Field>
                        { advisors && advisors.filter(advisor => advisor.email === thirdLevel )[0] &&  advisors.filter(advisor => advisor.email === thirdLevel ).map(advisor => {
                            return (
                                <AdvisorCard advisor={advisor} />
                            )
                        }) }
                        <select value={thirdLevel} onChange={ e => setThirdLevel(e.target.value) } name="thirdLevel">
                            <option value="" >Üçüncü Seviye</option>
                            { advisors && advisors.filter(child => child.referance === secondLevel )[0] && advisors.filter(child => child.referance === secondLevel ).map(child => {
                                return (
                                    <option key={child.key} value={child.email}>{child.name}</option>
                                )
                            })}
                        </select>
                    </Form.Field> : "" }
                    { advisors && advisors.filter(child => child.referance === thirdLevel )[0] ? <Form.Field>
                        { advisors && advisors.filter(advisor => advisor.email === fourthLevel )[0] &&  advisors.filter(advisor => advisor.email === fourthLevel ).map(advisor => {
                            return (
                                <AdvisorCard advisor={advisor} />
                            )
                        }) }
                        <select value={fourthLevel} onChange={ e => setFourthLevel(e.target.value) } name="fourthLevel">
                            <option value="" >Dördüncü Seviye</option>
                            { advisors && advisors.filter(child => child.referance === thirdLevel )[0] && advisors.filter(child => child.referance === thirdLevel ).map(child => {
                                return (
                                    <option key={child.key} value={child.email}>{child.name}</option>
                                )
                            })}
                        </select>
                    </Form.Field> : "" }
                    { advisors && advisors.filter(child => child.referance === fourthLevel )[0] ? <Form.Field>
                        { advisors && advisors.filter(advisor => advisor.email === fifthLevel )[0] &&  advisors.filter(advisor => advisor.email === fifthLevel ).map(advisor => {
                            return (
                                <AdvisorCard advisor={advisor} />
                            )
                        }) }
                        <select value={fifthLevel} onChange={ e => setFifthLevel(e.target.value) } name="fifthLevel">
                            <option value="" >Beşinci Seviye</option>
                            { advisors && advisors.filter(child => child.referance === fourthLevel )[0] && advisors.filter(child => child.referance === fourthLevel ).map(child => {
                                return (
                                    <option key={child.key} value={child.email}>{child.name}</option>
                                )
                            })}
                        </select>
                    </Form.Field> : "" }
                    { advisors && advisors.filter(child => child.referance === fifthLevel )[0] ? <Form.Field>
                        { advisors && advisors.filter(advisor => advisor.email === sixthLevel )[0] &&  advisors.filter(advisor => advisor.email === sixthLevel ).map(advisor => {
                            return (
                                <AdvisorCard advisor={advisor} />
                            )
                        }) }
                        <select value={sixthLevel} onChange={ e => setSixthLevel(e.target.value) } name="sixthLevel">
                            <option value="" >Altıncı Seviye</option>
                            { advisors && advisors.filter(child => child.referance === fifthLevel )[0] && advisors.filter(child => child.referance === fifthLevel ).map(child => {
                                return (
                                    <option key={child.key} value={child.email}>{child.name}</option>
                                )
                            })}
                        </select>
                    </Form.Field> : "" }
                    { advisors && advisors.filter(child => child.referance === sixthLevel )[0] ? <Form.Field>
                        { advisors && advisors.filter(advisor => advisor.email === seventhLevel )[0] &&  advisors.filter(advisor => advisor.email === seventhLevel ).map(advisor => {
                            return (
                                <AdvisorCard advisor={advisor} />
                            )
                        }) }
                        <select value={seventhLevel} onChange={ e => setSeventhLevel(e.target.value) } name="seventhLevel">
                            <option value="" >Yedinci Seviye</option>
                            { advisors && advisors.filter(child => child.referance === sixthLevel )[0] && advisors.filter(child => child.referance === sixthLevel ).map(child => {
                                return (
                                    <option key={child.key} value={child.email}>{child.name}</option>
                                )
                            })}
                        </select>
                    </Form.Field> : "" }
                    { advisors && advisors.filter(child => child.referance === seventhLevel )[0] ? <Form.Field>
                        { advisors && advisors.filter(advisor => advisor.email === eighthLevel )[0] &&  advisors.filter(advisor => advisor.email === eighthLevel ).map(advisor => {
                            return (
                                <AdvisorCard advisor={advisor} />
                            )
                        }) }
                        <select value={eighthLevel} onChange={ e => setEighthLevel(e.target.value) } name="eighthLevel">
                            <option value="" >Sekizinci Seviye</option>
                            { advisors && advisors.filter(child => child.referance === seventhLevel )[0] && advisors.filter(child => child.referance === seventhLevel ).map(child => {
                                return (
                                    <option key={child.key} value={child.email}>{child.name}</option>
                                )
                            })}
                        </select>
                    </Form.Field> : "" }
                    { advisors && advisors.filter(child => child.referance === eighthLevel )[0] ? <Form.Field>
                        { advisors && advisors.filter(advisor => advisor.email === ninthLevel )[0] &&  advisors.filter(advisor => advisor.email === ninthLevel ).map(advisor => {
                            return (
                                <AdvisorCard advisor={advisor} />
                            )
                        }) }
                        <select value={ninthLevel} onChange={ e => setNinthLevel(e.target.value) } name="ninthLevel">
                            <option value="" >Dokuzuncu Seviye</option>
                            { advisors && advisors.filter(child => child.referance === eighthLevel )[0] && advisors.filter(child => child.referance === eighthLevel ).map(child => {
                                return (
                                    <option key={child.key} value={child.email}>{child.name}</option>
                                )
                            })}
                        </select>
                    </Form.Field> : "" }
                    { advisors && advisors.filter(child => child.referance === ninthLevel )[0] ? <Form.Field>
                        { advisors && advisors.filter(advisor => advisor.email === tenthLevel )[0] &&  advisors.filter(advisor => advisor.email === tenthLevel ).map(advisor => {
                            return (
                                <AdvisorCard advisor={advisor} />
                            )
                        }) }
                        <select value={tenthLevel} onChange={ e => setTenthLevel(e.target.value) } name="tenthLevel">
                            <option value="" >Onuncu Seviye</option>
                            { advisors && advisors.filter(child => child.referance === ninthLevel )[0] && advisors.filter(child => child.referance === ninthLevel ).map(child => {
                                return (
                                    <option key={child.key} value={child.email}>{child.name}</option>
                                )
                            })}
                        </select>
                    </Form.Field> : "" }
                    { advisors && advisors.filter(child => child.referance === tenthLevel )[0] ? <Form.Field>
                        { advisors && advisors.filter(advisor => advisor.email === eleventhLevel )[0] &&  advisors.filter(advisor => advisor.email === eleventhLevel ).map(advisor => {
                            return (
                                <AdvisorCard advisor={advisor} />
                            )
                        }) }
                        <select value={eleventhLevel} onChange={ e => setEleventhLevel(e.target.value) } name="eleventhLevel">
                            <option value="" >On Birinci Seviye</option>
                            { advisors && advisors.filter(child => child.referance === tenthLevel )[0] && advisors.filter(child => child.referance === tenthLevel ).map(child => {
                                return (
                                    <option key={child.key} value={child.email}>{child.name}</option>
                                )
                            })}
                        </select>
                    </Form.Field> : "" }
                    { advisors && advisors.filter(child => child.referance === eleventhLevel )[0] ? <Form.Field>
                        { advisors && advisors.filter(advisor => advisor.email === twelfthLevel )[0] &&  advisors.filter(advisor => advisor.email === twelfthLevel ).map(advisor => {
                            return (
                                <AdvisorCard advisor={advisor} />
                            )
                        }) }
                        <select value={twelfthLevel} onChange={ e => setTwelfthLevel(e.target.value) } name="twelfthLevel">
                            <option value="" >On İkinci Seviye</option>
                            { advisors && advisors.filter(child => child.referance === eleventhLevel )[0] && advisors.filter(child => child.referance === eleventhLevel ).map(child => {
                                return (
                                    <option key={child.key} value={child.email}>{child.name}</option>
                                )
                            })}
                        </select>
                    </Form.Field> : "" }
                    { advisors && advisors.filter(child => child.referance === twelfthLevel )[0] ? <Form.Field>
                        { advisors && advisors.filter(advisor => advisor.email === thirteenthLevel )[0] &&  advisors.filter(advisor => advisor.email === thirteenthLevel ).map(advisor => {
                            return (
                                <AdvisorCard advisor={advisor} />
                            )
                        }) }
                        <select value={thirteenthLevel} onChange={ e => setThirteenthLevel(e.target.value) } name="thirteenthLevel">
                            <option value="" >On Üçüncü Seviye</option>
                            { advisors && advisors.filter(child => child.referance === twelfthLevel )[0] && advisors.filter(child => child.referance === twelfthLevel ).map(child => {
                                return (
                                    <option key={child.key} value={child.email}>{child.name}</option>
                                )
                            })}
                        </select>
                    </Form.Field> : "" }
                    { advisors && advisors.filter(child => child.referance === thirteenthLevel )[0] ? <Form.Field>
                        { advisors && advisors.filter(advisor => advisor.email === fourteenthLevel )[0] &&  advisors.filter(advisor => advisor.email === fourteenthLevel ).map(advisor => {
                            return (
                                <AdvisorCard advisor={advisor} />
                            )
                        }) }
                        <select value={fourteenthLevel} onChange={ e => setFourteenthLevel(e.target.value) } name="fourteenthLevel">
                            <option value="" >On Dördüncü Seviye</option>
                            { advisors && advisors.filter(child => child.referance === thirteenthLevel )[0] && advisors.filter(child => child.referance === thirteenthLevel ).map(child => {
                                return (
                                    <option key={child.key} value={child.email}>{child.name}</option>
                                )
                            })}
                        </select>
                    </Form.Field> : "" }
                    { advisors && advisors.filter(child => child.referance === fourteenthLevel )[0] ? <Form.Field>
                        { advisors && advisors.filter(advisor => advisor.email === fifteenthLevel )[0] &&  advisors.filter(advisor => advisor.email === fifteenthLevel ).map(advisor => {
                            return (
                                <AdvisorCard advisor={advisor} />
                            )
                        }) }
                        <select value={fifteenthLevel} onChange={ e => setFifteenthLevel(e.target.value) } name="fifteenthLevel">
                            <option value="" >On Beşinci Seviye</option>
                            { advisors && advisors.filter(child => child.referance === fourteenthLevel )[0] && advisors.filter(child => child.referance === fourteenthLevel ).map(child => {
                                return (
                                    <option key={child.key} value={child.email}>{child.name}</option>
                                )
                            })}
                        </select>
                    </Form.Field> : "" }
                    { advisors && advisors.filter(child => child.referance === fifteenthLevel )[0] ? <Form.Field>
                        { advisors && advisors.filter(advisor => advisor.email === sixteenthLevel )[0] &&  advisors.filter(advisor => advisor.email === sixteenthLevel ).map(advisor => {
                            return (
                                <AdvisorCard advisor={advisor} />
                            )
                        }) }
                        <select value={sixteenthLevel} onChange={ e => setSixteenthLevel(e.target.value) } name="sixteenthLevel">
                            <option value="" >On Altıncı Seviye</option>
                            { advisors && advisors.filter(child => child.referance === fifteenthLevel )[0] && advisors.filter(child => child.referance === fifteenthLevel ).map(child => {
                                return (
                                    <option key={child.key} value={child.email}>{child.name}</option>
                                )
                            })}
                        </select>
                    </Form.Field> : "" }
                    { advisors && advisors.filter(child => child.referance === sixteenthLevel )[0] ? <Form.Field>
                        { advisors && advisors.filter(advisor => advisor.email === seventeenthLevel )[0] &&  advisors.filter(advisor => advisor.email === seventeenthLevel ).map(advisor => {
                            return (
                                <AdvisorCard advisor={advisor} />
                            )
                        }) }
                        <select value={seventeenthLevel} onChange={ e => setSeventeenthLevel(e.target.value) } name="seventeenthLevel">
                            <option value="" >On Yedinci Seviye</option>
                            { advisors && advisors.filter(child => child.referance === sixteenthLevel )[0] && advisors.filter(child => child.referance === sixteenthLevel ).map(child => {
                                return (
                                    <option key={child.key} value={child.email}>{child.name}</option>
                                )
                            })}
                        </select>
                    </Form.Field> : "" }
                    { advisors && advisors.filter(child => child.referance === seventeenthLevel )[0] ? <Form.Field>
                        { advisors && advisors.filter(advisor => advisor.email === eighteenthLevel )[0] &&  advisors.filter(advisor => advisor.email === eighteenthLevel ).map(advisor => {
                            return (
                                <AdvisorCard advisor={advisor} />
                            )
                        }) }
                        <select value={eighteenthLevel} onChange={ e => setEighteenthLevel(e.target.value) } name="eighteenthLevel">
                            <option value="" >On Sekizinci Seviye</option>
                            { advisors && advisors.filter(child => child.referance === seventeenthLevel )[0] && advisors.filter(child => child.referance === seventeenthLevel ).map(child => {
                                return (
                                    <option key={child.key} value={child.email}>{child.name}</option>
                                )
                            })}
                        </select>
                    </Form.Field> : "" }
                    { advisors && advisors.filter(child => child.referance === eighteenthLevel )[0] ? <Form.Field>
                        { advisors && advisors.filter(advisor => advisor.email === nineteenthLevel )[0] &&  advisors.filter(advisor => advisor.email === nineteenthLevel ).map(advisor => {
                            return (
                                <AdvisorCard advisor={advisor} />
                            )
                        }) }
                        <select value={nineteenthLevel} onChange={ e => setNineteenthLevel(e.target.value) } name="nineteenthLevel">
                            <option value="" >On Dokuzuncu Seviye</option>
                            { advisors && advisors.filter(child => child.referance === eighteenthLevel )[0] && advisors.filter(child => child.referance === eighteenthLevel ).map(child => {
                                return (
                                    <option key={child.key} value={child.email}>{child.name}</option>
                                )
                            })}
                        </select>
                    </Form.Field> : "" }
                    { advisors && advisors.filter(child => child.referance === nineteenthLevel )[0] ? <Form.Field>
                        { advisors && advisors.filter(advisor => advisor.email === twentiethLevel )[0] &&  advisors.filter(advisor => advisor.email === twentiethLevel ).map(advisor => {
                            return (
                                <AdvisorCard advisor={advisor} />
                            )
                        }) }
                        <select value={twentiethLevel} onChange={ e => setTwentiethLevel(e.target.value) } name="twentiethLevel">
                            <option value="" >Yirminci Seviye</option>
                            { advisors && advisors.filter(child => child.referance === nineteenthLevel )[0] && advisors.filter(child => child.referance === nineteenthLevel ).map(child => {
                                return (
                                    <option key={child.key} value={child.email}>{child.name}</option>
                                )
                            })}
                        </select>
                    </Form.Field> : "" }
                </Form>
            </Modal.Content>
            <Modal.Actions>
                <Button color="red" inverted onClick={closeModal}>
                    <Icon name="remove"/> Kapat
                </Button>
            </Modal.Actions>
        </Modal>
    )
}

const mapStateToProps = state => ({
    currentUser: state.user.currentUser
});
  
export default connect(mapStateToProps)(AdvisorsWeb);