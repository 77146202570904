import React,{useState,useEffect} from "react";
import {useParams , Link} from "react-router-dom";
import firebase from "../firebase";
import { Grid ,Header, Image, Modal, Button, Icon, Form , Input} from "semantic-ui-react";
import InfiniteCarousel from "react-leaf-carousel";
import {connect} from "react-redux";
import md5 from "md5";
import Spinner from "./Spinner";

const Project = ({currentUser}) => {
    let {project_id} = useParams();
    const [project, setProject] = useState([]);
    const [modal, setModal] = useState(false);
    const [email, setEmail] = useState("");
    const [username, setUsername] = useState("");
    const [phoneNumber, setPhoneNumber] = useState("");
    const [country, setCountry] = useState("");
    const [advisors, setAdvisors] = useState([]);
    const emptyImage = "http://gravatar.com/avatar/" + md5(currentUser.email) + "?d=identicon" ;

    useEffect(()=>{
        firebase.database().ref("projects/" + project_id).on("value", (snapshot)=>{
            let snap = snapshot.val();
            setProject(snap);
        })
    },[project_id]);

    useEffect(()=>{
        firebase.database().ref("users/").orderByChild("role").equalTo("advisor").on("value", (snapshot)=>{
            if(snapshot.val()){
                let snap = Object.values(snapshot.val());
                setAdvisors(snap);
            }
        })
    },[]);

    useEffect(()=>{
        if(currentUser.uid !== 1234567890 ){
            setUsername(currentUser.displayName);
            setEmail(currentUser.email);
        }
    },[currentUser.uid , currentUser.displayName, currentUser.email]);

    const isFormEmpty = ({username, email, phoneNumber, country }) => {
        return !username.length || !email.length || !country.length || !phoneNumber.length  ;
    }

    const isPhoneNumberValid = ({phoneNumber}) =>{
        if(phoneNumber.length !== 10){
            return false ;
        } else {
            return true ;
        }
    }

    const isFormValid = () => {
        if(isFormEmpty({username, email, phoneNumber, country })){
            alert("Tüm Bilgileri Doldurduğunuza Emin misiniz?");
            return false;
        } else if(!isPhoneNumberValid({phoneNumber})){
            alert("İletişim Numarasını Doğru Yazdığınıza Emin misiniz? (Örn: 5351234567)");
            return false;
        } else {
            return true;
        }
    }
    

    const openModal = () => {
        setModal(true);
    }

    const closeModal = () => {
        setModal(false);
    }

    const handleSubmit = event => {
        event.preventDefault();
        if(isFormValid()){
            if(country === "Other"){
                alert( project.name + " Projesi Yalnızca Listelenen Ülkeler İçin Aktiftir...");
            } else {
                let childId = Math.floor(Math.random() * 1000000000);
                firebase.database().ref("preapplications/" + childId).set({
                    username : username,
                    email : email , 
                    phoneNumber : phoneNumber ,
                    appliedProjectName : project.name ,
                    appliedProjectID : project.key ,
                    country : country ,
                    key : childId,
                    createdAt : new Date().toString() ,
                    emailVerified : currentUser.emailVerified,
                    role : currentUser.role ,
                    read : false
                })
                .then(()=>{
                    setUsername("");
                    setPhoneNumber("");
                    setEmail("");
                    setCountry("");
                    setTimeout(()=>{
                        alert("Ön başvurunuz tarafımıza ulaşmıştır. Size en kısa zamanda geri dönüş yapmaya çalışacağız...");
                    },1000);
                })
                .catch(err =>{
                    console.log(err);
                    alert("Ön başvurunuz iletilirken bir hata meydana geldi. Lütfen tekrar deneyiniz...");
                });
                if(currentUser.uid !== 1234567890 ){
                    firebase.database().ref("users/" + currentUser.uid).update({
                        phoneNumber: phoneNumber,
                        updatedAt : new Date().toString()
                    });
                }
            }
        }
    }

    return (
    <div className="height" style={{background: "#eee", overflowY:"auto", width:"100vw", overflowX:"hidden" }}>
        <Grid textAlign="center" >
            <Grid.Column computer={16} tablet={16} mobile={16} color="teal" style={{minHeight:"75px"}} >
                <h1 style={{marginTop:"7px", color:"white", textDecoration:"underline", textShadow:"0 0 2px black"}}>
                   {project.name}
                </h1> 
            </Grid.Column>
            <Grid.Column computer={5} tablet={5} mobile={16} >
                <div style={{width:"100%",paddingTop:"100%",position:"relative"}}>
                    <Image src={ project.photos && Object.values(project.photos).filter(photo => photo.profilPhoto === true)[0] ? Object.values(project.photos).filter(photo => photo.profilPhoto === true)[0].photo : emptyImage} style={{position:"absolute",width:"100%",height:"100%",top:0, borderRadius:"10%", boxShadow:"0 0 10px teal", padding:"10px", margin:"5px"}} />
                </div>
                <br/>
                <div style={{position:"relative"}} >
                { project.photos ? <InfiniteCarousel
                    breakpoints={[
                    {
                        breakpoint: 500,
                        settings: {
                        slidesToShow: 2,
                        slidesToScroll: 2,
                        },
                    },
                    {
                        breakpoint: 768,
                        settings: {
                        slidesToShow: 3,
                        slidesToScroll: 3,
                        },
                    },
                    ]}
                    dots={false}
                    showSides={true}
                    sidesOpacity={1}
                    sideSize={.1}
                    slidesToScroll={4}
                    slidesToShow={4}
                    scrollOnDevice={true}
                    autoCycle={true}
                    cycleInterval={2000}
                    animationDuration={300}
                >
                    { Object.values(project.photos).map((photo)=>{
                        return (
                            <div key={photo.key} >                                
                                <img
                                    alt={photo.key}
                                    src={photo.photo}
                                    onClick={openModal}
                                    style={{cursor:"pointer"}}
                                />
                            </div>
                        )
                    }) }
                </InfiniteCarousel> : <Spinner/> }
            </div><br/>
            { project.projectLink ? <a href={project.projectLink} target="_blank" rel="noreferrer">
               <h3>Proje Linki: {project.projectLink}</h3>
            </a> : ""}
            </Grid.Column>
            <Grid.Column computer={6} tablet={6} mobile={16} textAlign="left" >
                { currentUser.role !== "advisor" ? <Form autocomplete="off" onSubmit={handleSubmit} style={{border:"3px double rgba(150, 250, 20, 0.8)",borderRadius:"10px", padding:"20px"}}>
                    <Form.Field style={{textAlign:"center"}}>
                        <Header color="orange">Ön Başvuru Formu</Header>
                    </Form.Field>
                    <Form.Field>
                        <Input 
                            fluid 
                            name="username" 
                            icon= "user" 
                            disabled = {currentUser.uid === 1234567890 ? false : true}
                            type="text" 
                            iconPosition="left"
                            placeholder="İsim Soyisim" 
                            onChange={e =>setUsername(e.target.value)} 
                            value={username} 
                        />
                    </Form.Field>
                    <Form.Field>
                        <Input 
                            fluid 
                            name="email"
                            icon="mail"
                            disabled = {currentUser.uid === 1234567890 ? false : true}
                            iconPosition="left"
                            placeholder="E-Posta Adresi" 
                            onChange={e =>setEmail(e.target.value)} 
                            type="email"
                            value={email} 
                        />
                    </Form.Field>
                    <Form.Field>
                        <Input 
                            fluid 
                            name="phoneNumber"
                            icon="phone"
                            iconPosition="left"
                            placeholder="İletişim Numarası" 
                            onChange={e =>setPhoneNumber(e.target.value)}  
                            type="number"
                            value={phoneNumber} 
                        />
                    </Form.Field>
                    <Form.Field>
                        <select value={country} onChange={e => setCountry(e.target.value)} name="country">
                            <option value="" >Ülke</option>
                            {project.validCityCountry ? Object.values(project.validCityCountry).map((child)=>{
                                return (<option key={child.key} value={child.countryValue}>{child.countryValue}</option>)
                            }) : ""}
                            <option value="Other" >Diğer</option>
                        </select>
                    </Form.Field>
                    <Form.Field>
                        <Button color="linkedin" size="mini" fluid >Gönder</Button>
                    </Form.Field>
                </Form> : ""}
                <h3 style={{margin:"0 0 5px"}}><u style={{color:"teal"}}>Proje ID:</u> {project.key}</h3>
                <h3 style={{margin:"0 0 5px"}}><u style={{color:"teal"}}>Proje Bedeli:</u> {project.projectPrice} {project.projectCurrency}</h3>
                { project.projectPoint && project.projectPointCurrency && <h3 style={{margin:"0 0 5px"}}><u style={{color:"teal"}}>Proje Puanı:</u> {project.projectPoint} {project.projectPointCurrency}</h3>}
                { project.projectNote && <h3 style={{margin:"0 0 5px"}}><u style={{color:"teal"}}>Proje Notu:</u> {project.projectNote} </h3>}
                <h3 style={{margin:"0 0 5px"}}><u style={{color:"teal"}}>Proje Statüsü:</u> {project.projectStatus ? Object.keys(project.projectStatus).join(", ") : ""}</h3>
                { currentUser.email === project.advisor ? <h3 style={{margin:"0 0 5px"}}><u style={{color:"teal"}}>Geçerli Ülkeler:</u> {project.validCityCountry ? Object.keys(project.validCityCountry).join(", ") : ""}</h3> : ""}
                <h3 style={{margin:"0 0 5px"}}><u style={{color:"teal"}}>İş Danışmanı:</u> {project.advisor !== "Belirsiz" ? <Link to="/advisors">{advisors && advisors.filter(child => child.email === project.advisor)[0] && advisors.filter(child => child.email === project.advisor)[0].name}</Link> : "Belirsiz"}</h3>
                { project.projectProgress ? <div><h3 style={{margin:"0 0 5px", color:"teal", textDecoration:"underline"}}>Gelişmeler: </h3><div style={{fontSize:"18px", fontWeight:"bold", height:"100px", overflowY:"auto" }}>{project.projectProgress}</div></div> : ""}
            </Grid.Column>     
            <Modal basic open={modal} onClose={closeModal}>
                <Modal.Content>
                { project.photos ? Object.values(project.photos).map((child) => {
                    return (
                        <div key={child.key} style={{width:"32%",position:"relative", float:"left" ,paddingTop:"32%",marginRight:"1%", marginBottom:"1%"}}>
                            <Image style={{position:"absolute", top:0,height:"100%",width:"100%",borderRadius:"10px",boxShadow:"0 0 3px teal"}} src={child.photo} alt="projectPhoto" ></Image>
                        </div>
                    )
                }) : <Spinner/> }
                </Modal.Content>   
                <Modal.Actions>
                    <Button color="red" inverted onClick={closeModal}>
                        <Icon name="remove"/> Kapat
                    </Button>
                </Modal.Actions>
            </Modal>        
        </Grid>
    </div>
    )
}

const mapStateToProps = state => ({
    currentUser: state.user.currentUser
});
  
export default connect(mapStateToProps)(Project);