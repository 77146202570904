import React from  "react";
import {connect} from "react-redux";
import firebase from "../../firebase";
import {Modal, Form, Input, Button, Icon } from "semantic-ui-react";


class UpdateEntrepreneur extends React.Component {
    constructor(props){
        super(props);
        this.state = { loading: false , phoneNumber : "" , username : "" , email : "" }
    }

    componentDidMount(){
        firebase.database().ref("/users/" + this.props.match.params.user_id + "/entrepreneurs/" + this.props.match.params.entrepreneur_id).once("value").then((snapshot)=>{
            let person = snapshot.val();
            if(person){
                this.setState({ phoneNumber: person.phoneNumber , username: person.name , email : person.email });
            }
        });

        if(this.props.currentUser.uid !== this.props.match.params.user_id){
            this.props.history.push("/");
        }
    }

    handleChange = event => this.setState({ [event.target.name] : event.target.value} );

    isFormEmpty = ({username, email, phoneNumber }) => {
        return !username.length || !email.length || !phoneNumber.length ;
    }

    isPhoneNumberValid = ({phoneNumber}) =>{
        if(phoneNumber.length !== 10){
            return false ;
        } else {
            return true ;
        }
    }

    isFormValid = () => {
        if(this.isFormEmpty(this.state)){
            alert("Tüm Bilgileri Doldurduğunuza Emin misiniz?");
            return false;
        } else if(!this.isPhoneNumberValid(this.state)){
            alert("İletişim Numarasını Doğru Yazdığınıza Emin misiniz? (Örn: 5351234567)");
            return false;
        } else {
            return true;
        }
    }

    handleSubmit = event => {
        event.preventDefault();
        if(this.isFormValid()){
            this.setState({loading: true});
            firebase.database().ref("/users/" + this.props.match.params.user_id + "/entrepreneurs/" + this.props.match.params.entrepreneur_id).update({
                name: this.state.username,
                email: this.state.email,
                phoneNumber : this.state.phoneNumber
            })
            .then(()=>{
                this.closeModal();
                alert("Girişimci Bilgileri Güncellendi...")
            })
        }
    }

    handleDelete = () => {
        if (window.confirm('Girişimciyi Silmek İstediğinize Emin misiniz?')){
            firebase.database().ref("/users/" + this.props.match.params.user_id + "/entrepreneurs/" + this.props.match.params.entrepreneur_id).remove()
            .then(()=>{
                this.closeModal();
                alert("Girişimci Silindi...")
            })
        }
    }


    closeModal = () => {
        this.props.history.goBack();
    }

    render(){
        const { username, email , phoneNumber} = this.state;

        return (
            <Modal basic open={true} onClose={ this.closeModal }>
                <Modal.Header style={{textAlign: "center"}} >{username + " İsimli Girişimciyi Güncelle"}</Modal.Header>
                <Modal.Content>
                    <Form >
                        <Form.Field>
                            <Input
                                fluid
                                label = "İsim Soyisim"
                                type="text"
                                name = "username"
                                value = {username}
                                onChange={this.handleChange}
                            />
                        </Form.Field>
                        <Form.Field>
                            <Input
                                fluid
                                label = "E-Posta Adresi"
                                name = "email"
                                type="email"
                                value = {email}
                                onChange={this.handleChange}
                            />
                        </Form.Field>
                        <Form.Field>
                            <Input
                                fluid
                                placeholder ="10 Haneli İletişim Numarasını Giriniz..."
                                label = "İletişim Numarası"
                                name = "phoneNumber"
                                type="number"
                                value = {phoneNumber}
                                onChange={this.handleChange}
                            />
                        </Form.Field>
                    </Form>
                </Modal.Content>
                <Modal.Actions>
                    <Button color="green" disabled={this.state.loading} loading={this.state.loading} inverted onClick={this.handleSubmit}>
                        <Icon name="checkmark"/> Güncelle
                    </Button>
                    <Button color="orange" disabled={this.state.loading} inverted onClick={this.handleDelete}>
                        <Icon name="user delete"/> Sil
                    </Button>
                    <Button color="red" inverted onClick={ this.closeModal}>
                        <Icon name="remove"/> Kapat
                    </Button>
                </Modal.Actions>
            </Modal>
        );
    }
}


const mapStateToProps = state => ({
    currentUser: state.user.currentUser
});
  
export default connect(mapStateToProps)(UpdateEntrepreneur);