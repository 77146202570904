import React from "react";
import { Grid, Form, Segment, Button, Header, Message, Icon, Checkbox } from "semantic-ui-react";
import { Link } from "react-router-dom";
import firebase from "../../firebase";
import md5 from "md5";
import {connect} from "react-redux";

class Register extends React.Component {
    state = {
        username : "",
        email : "",
        password : "" ,
        passwordConfirmation : "",
        errors: [],
        isChecked: false ,
        toogleIcon : true,
        loading: false,
        usersRef : firebase.database().ref("users")
    }

    componentDidMount(){
        if(this.props.currentUser.uid !== 1234567890){
            this.props.history.push("/");
        }
    }

    handleChange = event => {
        this.setState({ [event.target.name] : event.target.value });
    }

    isFormValid = () => {
        let errors = [];
        let error;

        if(this.isFormEmpty(this.state)){
            error = {message: "Tüm Bilgileri Doldurduğunuza Emin Olun..."};
            this.setState({errors : errors.concat(error)});
            return false;
        } else if(!this.isPasswordValid(this.state)){
            error = {message: "Şifre Geçersiz..."};
            this.setState({errors : errors.concat(error)});
            return false;
        } else {
            return true;
        }
    }

    isFormEmpty = ({username, email, password, passwordConfirmation}) => {
        return !username.length || !email.length || !password.length || !passwordConfirmation.length ;
    }

    isPasswordValid = ({password, passwordConfirmation}) => {
        if(password.length < 6 || passwordConfirmation.length < 6 ){
            return false;
        } else if(password !== passwordConfirmation){
            return false;
        } else {
            return true;
        }
    }

    displayErrors = errors => errors.map((error, i) => <p key={i}>{error.message}</p>);

    handleSubmit = event => {
        event.preventDefault();
        if(this.isFormValid()) {
            this.setState({errors:[], loading:true});
            firebase.auth().createUserWithEmailAndPassword(this.state.email, this.state.password)
            .then(createduser => {
                console.log(createduser);
                createduser.user.updateProfile({
                    displayName : this.state.username
                })
                .then(() => {
                    this.saveUser(createduser).then(()=>{
                        console.log("User Saved...");
                        this.setState({loading: false});
                        firebase.auth().currentUser.sendEmailVerification()
                        .then(()=>{
                            alert(createduser.user.email + " adresine doğrulama mesajı gönderildi...");
                        })
                        .catch(err =>{
                            alert(createduser.user.email + " adresine doğrulama mesajı gönderilemedi...");
                        });
                        setTimeout(() => {
                            this.props.history.push("/");
                        }, 3);
                    });
                })
                .catch(err => {
                    console.log(err);
                    this.setState({ errors: this.state.errors.concat(err), loading: false });
                })
                
            })
            .catch(err => {
                console.error(err);
                err.message = "E-posta Adresi Zaten Kullanılıyor...";
                this.setState({errors: this.state.errors.concat(err), loading: false});
            });
        }
    }

    saveUser = createdUser => {
        return this.state.usersRef.child(createdUser.user.uid).set({
            name: createdUser.user.displayName,
            emailVerified : createdUser.user.emailVerified,
            avatar: "http://gravatar.com/avatar/" + md5(createdUser.user.email) + "?d=identicon",
            role: this.state.isChecked ? "advisorCandidate" : "entrepreneur" ,
            password: this.state.password + md5(createdUser.user.email),
            key: createdUser.user.uid,
            email : createdUser.user.email,
            createdAt : new Date().toString(),
            updatedAt : new Date().toString(),
            phoneNumber : "",
            referance: "Referans Yok" ,
            seen: false
        });
    }

    handleInputError = (errors, inputName) => {
        return errors.some(error => error.message.toLowerCase().includes(inputName)) ? "error" : "" ;
    }

    render(){
        const { username, email, password, passwordConfirmation, errors, loading, isChecked } = this.state;

        return (
            <Grid textAlign="center" verticalAlign="middle" className="app">
                <Grid.Column style={{maxWidth: 450}}>
                    <Header as="h1" icon color="orange" textAlign="center">
                        <Icon name="puzzle piece" color="orange"/>
                        YAPSAT'a Kayıt Ol
                    </Header>
                    <Form size="large" onSubmit={this.handleSubmit}>
                        <Segment stacked>
                            <Form.Input 
                                fluid 
                                name="username" 
                                icon="user" 
                                iconPosition="left"
                                placeholder="Kullanıcı Adı" 
                                onChange={this.handleChange} 
                                type="text" 
                                value={username}
                            />
                            <Form.Input 
                                fluid 
                                name="email" 
                                icon="mail" 
                                iconPosition="left"
                                placeholder="E-Posta Adresi" 
                                onChange={this.handleChange} 
                                type="email"
                                value={email} 
                                className={this.handleInputError(errors, "e-posta")}
                            />
                            <Form.Input 
                                fluid 
                                name="password" 
                                icon="lock" 
                                onFocus = {()=> this.setState({toogleIcon : false})}
                                onBlur = {()=> this.setState({toogleIcon : true})}
                                type={this.state.toogleIcon ? "password" : "text"}
                                iconPosition="left"
                                placeholder="Şifre" 
                                onChange={this.handleChange} 
                                value={password} 
                                className={this.handleInputError(errors, "şifre")}
                            />
                            <Form.Input 
                                fluid 
                                name="passwordConfirmation" 
                                icon="repeat" 
                                iconPosition="left"
                                placeholder="Şifre Doğrulama" 
                                onChange={this.handleChange} 
                                onFocus = {()=> this.setState({toogleIcon : false})}
                                onBlur = {()=> this.setState({toogleIcon : true})}
                                type={this.state.toogleIcon ? "password" : "text"} 
                                value={passwordConfirmation} 
                                className={this.handleInputError(errors, "şifre")}
                            />
                            <Button 
                                disabled={loading} 
                                className={loading ? "loading" : ""} 
                                color="orange" 
                                fluid 
                                size="large"
                                style={{marginBottom:"10px"}}
                            >
                                Gönder
                            </Button>
                            <Form.Field>
                                <Checkbox
                                    label='İş Danışmanımız Olmak İster misiniz?'
                                    onChange={() => {this.setState({isChecked: !isChecked })}}
                                    checked={this.state.isChecked} 
                                />
                            </Form.Field>                            
                        </Segment>
                    </Form>
                    {errors.length > 0 && (
                        <Message error>
                            <h3><u>Hata</u></h3>
                            {this.displayErrors(errors)}
                        </Message>
                    )}
                    <Message>Daha Önce Kayıt Oldun mu? <Link to="/login">Oturum Aç</Link></Message>
                </Grid.Column>
            </Grid>
        );
    }
}

const mapStateToProps = state => ({
    currentUser: state.user.currentUser
});
  
export default connect(mapStateToProps)(Register);