import React, {useState, useEffect} from  "react";
import Slider from "../Slider";
import firebase from "../../firebase";
import {connect} from "react-redux";
import {useHistory, useParams} from "react-router-dom";
import { Grid } from "semantic-ui-react";

const DoneProjects = ({currentUser}) => {
    const [project,setProject] = useState([]);
    const [width, setWidth] = useState("");
    const [maxWidth, setMaxWidth] = useState("");
    const [height, setHeight] = useState("");
    const history = useHistory();
    let {user_id} = useParams();

    useEffect(() => {
        firebase.database().ref("projects").orderByChild("done").equalTo(true).on("value", snapshot => {
            if(snapshot.val()){
                let snap = Object.values(snapshot.val());
                setProject(snap);
            }
        })
    }, []);

    useEffect(() => {
        setTimeout(() => {
            firebase.database().ref("projects").orderByChild("advisor").equalTo(currentUser.email).once("value" , (snapshot) => {
                snapshot.forEach(child => {
                    if(child.val().done === true){
                        child.ref.update({
                            seen : true
                        })
                    }
                })
            });
        }, 10000);
    }, [currentUser.email]);

    useEffect(()=>{
        project.filter(child => child.advisor === currentUser.email).length === 1 ? setWidth("80vw") : setWidth("100%");
        project.filter(child => child.advisor === currentUser.email).length === 1 ? setMaxWidth("80vw") : setMaxWidth("200px");
        project.filter(child => child.advisor === currentUser.email).length === 1 ? setHeight("80vh") : setHeight("150px");
    },[project , currentUser.email])

    useEffect(()=>{
        return currentUser.uid !== user_id ? history.push("/") : null
    });

    return (
        <Grid >
            <Grid.Column computer={10} tablet={10} mobile={16} style={{margin:"0 auto"}}>
                <Slider maxWidth={maxWidth} width={width} height={height} projects={project.filter(child => child.advisor === currentUser.email)} />
            </Grid.Column>
        </Grid>        
    )
}

const mapStateToProps = state => ({
    currentUser: state.user.currentUser
});
  
export default connect(mapStateToProps)(DoneProjects);