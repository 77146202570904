import React,{useState, useEffect} from  "react";
import {connect} from "react-redux";
import {useHistory} from "react-router-dom";
import firebase from "../../firebase";
import {Modal, Form, Button, Icon, Input} from "semantic-ui-react";

const AboutUsEdit = ({currentUser}) => {
    const history = useHistory();
    const [aboutUs, setAboutUs] = useState("");
    const [videoID, setVideoID] = useState("");

    useEffect(()=>{
        if( currentUser.role !== "admin" ){
            history.push("/");
        }
    },[ currentUser.role, history ]);

    useEffect(()=>{
        firebase.database().ref("aboutus").on("value", (snapshot)=>{
            if(snapshot.val()){
                setAboutUs(snapshot.val().content);
                setVideoID(snapshot.val().videoID);
            }
        })
    },[]);

    useEffect(()=>{
        document.getElementById('textarea').addEventListener('keydown', function(e) {
            if (e.key === 'Tab') {
              e.preventDefault();
              var start = this.selectionStart;
              var end = this.selectionEnd;
              this.value = this.value.substring(0, start) + "\t" + this.value.substring(end);
              this.selectionStart = this.selectionEnd = start + 1;
            }
          });
    },[]);

    const closeModal = () => {
        history.push("/admin");
    }

    const handleSubmit = event => {
        event.preventDefault();
        firebase.database().ref("aboutus").update({content : aboutUs, videoID: videoID})
        .then(()=>{
            alert("Hakkımızda Sayfası İçeriği Güncellendi...");
        })
    }

    return (
        <Modal basic open={true} onClose={closeModal}>
            <Modal.Header style={{textAlign: "center"}} >Hakkımızda</Modal.Header>
            <Modal.Content>
                <Form autocomplete="off" onSubmit={handleSubmit}>
                    <Form.Field>
                        <Input
                            label="Video ID"
                            type="text"
                            name ="videoID"
                            placeholder='Video ID' 
                            onChange={e => setVideoID(e.target.value)}
                            value = {videoID}
                        />
                    </Form.Field>
                    <Form.Field>
                        <textarea 
                            id="textarea"
                            type="text"
                            name ="aboutUs"
                            placeholder='Hakkımızda...' 
                            onChange={e => setAboutUs(e.target.value)}
                            value = {aboutUs}
                            style = {{height:"50vh", resize:"none", overflow:"auto"}}
                        >
                        </textarea>  
                    </Form.Field>
                </Form>
            </Modal.Content>
            <Modal.Actions>
                <Button color="green" inverted onClick={handleSubmit}>
                    <Icon name="checkmark"/> Güncelle
                </Button>
                <Button color="red" inverted onClick={closeModal}>
                    <Icon name="remove"/> Kapat
                </Button>
            </Modal.Actions>
        </Modal>
    )
}

const mapStateToProps = state => ({
    currentUser: state.user.currentUser
});
  
export default connect(mapStateToProps)(AboutUsEdit);