import React, {useState , useEffect} from "react";
import './App.css';
import {connect} from "react-redux";
import firebase from "../firebase";
import {
    Grid,
    Icon,
    Menu,
    Sidebar,
    Segment,
    Image,
    Dropdown
} from 'semantic-ui-react';
import {Link ,Route, Switch, useLocation, useHistory} from "react-router-dom";
import UserPanel from "./UserPanel";
import AboutUs from "./AboutUs";
import Contact from "./Contact";
import FAQ from "./FAQ";
import Advisors from  "./Advisors";
import Project from  "./Project";
import UpdateProject from "./UpdateProject";
import MainPageContent from "./MainPageContent";
import UpdateFAQ from "./UpdateFAQ";
import tawkTo from "tawkto-react";

const MainPage = ({currentUser}) => {
        const [visible,setVisible] = useState(false);
        const [menuItem, setMenuItem] = useState(false);
        const [username, setUsername] = useState("");
        const [photo, setPhoto] = useState("");
        let history = useHistory();
        const location = useLocation();

        useEffect(() => {
          setTimeout(()=>{
            setMenuItem(true);
          },2000);
        }, [currentUser]);

        useEffect(() => {
          tawkTo("6048daab385de407571eacf5", "1f0e9cc0a");
        }, []);

        useEffect(() => {
          if(currentUser.uid === 1234567890 ){
            setUsername("Girişimci");
            setPhoto("entrepreneurPhotoURL");
          } else {
              firebase.database().ref("/users/" + currentUser.uid).once("value").then((snapshot)=>{
                  let person = snapshot.val();
                  if(person){
                      setPhoto(person.avatar);
                      setUsername(person.name);
                  }
              });
          }
        }, [currentUser.uid]);

        const dropdownOptions = () => [
          {
              key: "user",
              text: <span><u>Oturum Açan Kullanıcı</u><br/><br/><strong>{username}</strong></span>,
              disabled: true
          },
          {
              key: "signout",
              text: <span onClick={handleSignout}>Çıkış Yap</span>
          }
        ];

        const handleSignout = () => {
          window["role"] = "";
          firebase.auth().signOut()
          .then(()=>{
              console.log("Signed Out");
              setTimeout(() => {
                  history.push("/login")
              }, 500);
          });
        }

        return (
            <Grid columns={1} style={{background: "#eee"}}>
              <Icon
                    name={visible ? "angle double left" : "angle double right"} 
                    color={visible ? "blue" : "black"}
                    style={{position:"fixed",float:"left", marginTop:"50vh",zIndex:1000,fontSize:"30px", marginLeft:"10px", cursor:"pointer"}}
                    onClick ={()=> setVisible(!visible)}
                    id="mobileMenu"
              />

              <Grid.Column>
                <Sidebar.Pushable >                
                  <Sidebar
                    as={Menu}
                    animation='overlay'
                    direction='left'
                    icon='labeled'
                    inverted
                    onClick = {()=>setMenuItem(!menuItem)}
                    vertical
                    visible={visible}
                    width='large'
                    style={{background:"rgba(20,20,20,0.8)", height:"97.5vh"}}
                  >
                    <UserPanel currentUser={currentUser}/>
                    { currentUser.role === "admin" ? <Menu.Item as='a'>
                      <Link to="/admin">
                        <Icon name='user' color="purple" />
                        Yönetici Paneli
                      </Link>
                    </Menu.Item> : ""}
                    { currentUser.role === "advisor" ? <Menu.Item as='a'>
                      <Link to={"/user/" + currentUser.uid}>
                        <Icon name='user' color="purple" />
                        Hesabım
                      </Link>
                    </Menu.Item> : ""}
                    { currentUser.uid === 1234567890 ? <Menu.Item as='a'>
                      <Link to="/login">
                        <Icon name='sign-in' color="orange" />
                        Oturum Aç
                      </Link>
                    </Menu.Item> : ""}
                    {location.pathname !== "/" ? <Menu.Item as='a'>
                      <Link to="/">
                        <Icon name='home' color="teal" />
                        Ana Sayfa
                      </Link>
                    </Menu.Item> : ""}
                    <Menu.Item as='a'>
                      <Link to="/aboutUs">
                        <Icon name='chess queen' color="yellow" />
                        Hakkımızda
                      </Link>
                    </Menu.Item>
                    <Menu.Item as='a'>
                      <Link to="/advisors">
                        <Icon name='users' color="violet" />
                        İş Danışmanlarımız
                      </Link>
                    </Menu.Item>
                    <Menu.Item as='a'>
                      <Link to="/faq">
                        <Icon name='question' color="red" />
                        FAQ
                      </Link>
                    </Menu.Item>
                    <Menu.Item as='a'>
                      <Link to="/contact">
                        <Icon name='phone' color="olive" />
                        İletişim
                      </Link>
                    </Menu.Item>
                  </Sidebar>
                  <Sidebar.Pusher style={{background: "#eee", height:"97.5vh", overflow:"hidden"}}>
                    <Segment inverted id="topMenu" >
                      <Menu inverted secondary>
                        <Menu.Item>
                          <Image  src="logo.jpg" rounded style={{boxShadow:"0 0 5px white", width:"30px"}}/>
                        </Menu.Item>
                        { currentUser.role === "admin" ? <Menu.Item
                          name='Yönetici Paneli'
                          onClick={() => history.push("/admin")}
                        /> : "" }
                        { currentUser.role === "advisor" ? <Menu.Item
                          name='Hesabım'
                          onClick={() => history.push("/user/" + currentUser.uid)}
                        /> : "" }
                        <Menu.Item
                          name='Ana Sayfa'
                          active={location.pathname === "/"}
                          onClick={() => history.push("/") }
                        />
                        <Menu.Item
                          name='Hakkımızda'
                          active={location.pathname === "/aboutUs"}
                          onClick={() => history.push("/aboutUs") }
                        />
                        <Menu.Item
                          name='İş Danışmanlarımız'
                          active={location.pathname === "/advisors"}
                          onClick={() => history.push("/advisors") }
                        />
                        <Menu.Item
                          name='FAQ'
                          active={location.pathname === "/faq"}
                          onClick={() => history.push("/faq") }
                        />
                        <Menu.Item
                          name='İletişim'
                          active={location.pathname === "/contact"}
                          onClick={() => history.push("/contact") }
                        />
                        <Menu.Menu position="right" style={{marginRight:"50px"}} >
                          { currentUser.uid === 1234567890 ? <Menu.Item
                            name='Oturum Aç'
                            onClick={() => history.push("/login")}
                          /> : "" }
                          { currentUser.uid === 1234567890 ? <Menu.Item
                            name='Kayıt Ol'
                            onClick={() => history.push("/register")}
                          /> : "" }
                          { currentUser.uid !== 1234567890 ? <Menu.Item>
                            <Dropdown trigger={
                              <span>
                                  <Image src={photo} spaced="right" avatar/>
                                  {username}
                              </span>} 
                              options={dropdownOptions()}
                            />
                          </Menu.Item> : "" }
                        </Menu.Menu>
                      </Menu>
                    </Segment>

                    <Switch>
                      <Route exact path="/" children={<MainPageContent/>}/>
                      <Route path="/aboutUs" children={<AboutUs/>}/>
                      <Route path="/contact" children={<Contact/>} />
                      <Route path="/faq/:faq_id/update" children={<UpdateFAQ/>} />
                      <Route path="/faq" children={<FAQ/>} />
                      <Route path="/advisors" children={<Advisors/>} />
                      <Route path="/project/:project_id/update" children={<UpdateProject/>} />
                      <Route path="/project/:project_id" children={<Project/>} />
                    </Switch>                    
                  </Sidebar.Pusher>
                </Sidebar.Pushable>
              </Grid.Column>
            </Grid>
          )
    
}

const mapStateToProps = state => ({
    currentUser: state.user.currentUser
});
  
export default connect(mapStateToProps)(MainPage);
