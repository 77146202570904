import React, {useState, useEffect} from "react";
import {connect} from "react-redux";
import { Grid, Container, Header, Divider, Icon } from 'semantic-ui-react';
import firebase from "../firebase";
import {useHistory} from "react-router-dom";

const FAQ = ({currentUser}) => {
    const history = useHistory();
    const [ faq, setFaq ] = useState([]);

    useEffect(() => {
      firebase.database().ref("faq").on("value", (snapshot) => {
        if(snapshot.val()){
          let snap = Object.values(snapshot.val());
          setFaq(snap);
        }
      })
    }, []);

        return (
          <div className="height" style={{background: "#eee" , overflowY:"auto", width:"100vw", overflowX:"hidden"}}>
            <Grid >
              <Grid.Column computer={16} tablet={16} mobile={16} textAlign="center" color="teal" style={{height:"75px"}}>
                <h1 style={{marginTop:"10px" , textDecoration:"underline", textShadow:"0 0 2px black"}}>Sıkça Sorulan Sorular</h1>
              </Grid.Column>
              <Grid.Column computer={12} tablet={12} mobile={16} style={{margin:"0 auto"}}>
                {faq.map(child => {
                  let r = Math.floor(Math.random() * 256);
                  let g = Math.floor(Math.random() * 256);
                  let b = Math.floor(Math.random() * 256);
                  return (
                    <Container key={child.key} style={{border:"3px double rgb(" + r + "," + g + "," + b + ")",borderRadius:"10px", padding:"20px", marginTop:"20px", background:"white"}}>
                      <Header>{child.question} {currentUser.role === "admin" ? <Icon name="edit" color="green" style={{float:"right", cursor:"pointer"}} onClick={()=> history.push("/faq/" + child.key + "/update") } /> : ""} </Header>
                      <Divider/>
                      <p>{child.answer}</p>
                    </Container>
                  )
                })}
              </Grid.Column>
            </Grid>
          </div>
        )
}


const mapStateToProps = state => ({
    currentUser: state.user.currentUser
});
  
export default connect(mapStateToProps)(FAQ);
