import React , {useState , useEffect} from "react";
import firebase from "../../firebase";
import { Icon, Table, Header ,Grid , Image } from 'semantic-ui-react';
import {useHistory, Route} from "react-router-dom";
import {connect} from "react-redux";
import MessageImages from "./MessageImages";


const db = firebase.database();


const Messages = ({currentUser}) => {
    const [messages, setMessages] = useState([]);
    const [roles, setRoles] = useState([]);
    const history = useHistory();

    useEffect(()=>{
        db.ref("messages").on("value" , (snapshot) => {
            if(snapshot.val()){
                setMessages(Object.values( snapshot.val() ));
            }
        })
    },[]);

    useEffect(() => {
        db.ref("roles").on("value", (snapshot) => {
            if(snapshot.val()){
                let snap = Object.values( snapshot.val() );
                setRoles(snap);
            }
        })
    },[]);

    useEffect(()=>{
        return currentUser.role !== "admin" ? history.push("/") : null
    });


    const handleDelete = (message) => {
        if (window.confirm( message.username + ' Kişisinin Mesajını Silmek İstediğinize Emin misiniz?')){
            message.photos && Object.values(message.photos).map(child => {
                return firebase.storage().ref().child("contact/admin/" + message.key + "/" + child.name).delete();
            })

            firebase.database().ref('messages/' + message.key).remove()
            .then(()=>{
                console.log("Message Deleted...");
                setTimeout(() => {
                    history.push("/admin");
                    history.push("/admin/messages");
                }, 1000);
            })
        }
    }

    const handleRead = (messageKey) => {
        db.ref("messages/" + messageKey).update({
            read: true
        });
    }

        return (
            <Grid textAlign="center" style={{marginTop:"20px"}}>
                <Grid.Column computer={16} tablet={16} mobile={16} style={{overflow:"auto"}}>
                    <Table  celled >
                        <Table.Header>
                            <Table.Row style={{textAlign:"center"}}>
                                <Table.HeaderCell></Table.HeaderCell>
                                <Table.HeaderCell>Kullanıcı</Table.HeaderCell>
                                <Table.HeaderCell>Tarih</Table.HeaderCell>
                                <Table.HeaderCell>Email Adresi</Table.HeaderCell>
                                <Table.HeaderCell>İletişim Numarası</Table.HeaderCell>
                                <Table.HeaderCell>Email <br/>Doğrulanma</Table.HeaderCell>
                                <Table.HeaderCell>Mesaj</Table.HeaderCell>
                                <Table.HeaderCell>Resim</Table.HeaderCell>
                                <Table.HeaderCell>Okundu</Table.HeaderCell>
                            </Table.Row>
                        </Table.Header>
                        <Table.Body>
                        { messages ? messages.sort((b,a)=>{return a.createdAt.localeCompare(b.createdAt)}).map(message => {
                            return (
                                <Table.Row key={message.key} style={{textAlign:"center", background: message.read ? "white" : "salmon"}}>
                                    <Table.Cell>
                                        <Icon name="trash alternate" color="red" style={{fontSize:"25px",cursor:"pointer"}} onClick={()=>handleDelete(message)} />
                                    </Table.Cell>
                                    <Table.Cell>
                                        <Header as='h4'>
                                            <Header.Content>
                                                {message.username}
                                                <Header.Subheader> { roles && roles.filter(child => child.role === message.role )[0] ? roles.filter(child => child.role === message.role )[0].displayName : ""}</Header.Subheader>
                                            </Header.Content>
                                        </Header>
                                    </Table.Cell>
                                    <Table.Cell>{new Date(message.createdAt).toLocaleDateString()} {new Date(message.createdAt).toLocaleTimeString()}</Table.Cell>
                                    <Table.Cell>{message.email}</Table.Cell>
                                    <Table.Cell>{message.phoneNumber}</Table.Cell>
                                    <Table.Cell style={{textAlign:"center"}} >{message.emailVerified ? <Icon name='check' color="green"/> : <Icon name='close' color="red"/>}</Table.Cell>
                                    <Table.Cell className="messageUser" >{message.message}</Table.Cell>
                                    <Table.Cell >
                                        { message.photos ? <Image 
                                                src={Object.values(message.photos).filter(child => child.profilPhoto === true)[0].photo}
                                                rounded 
                                                size='mini'
                                                style={{cursor:"pointer"}}
                                                onClick={() => history.push("/admin/messages/" + message.key + "/images")}
                                            /> : "" }
                                    </Table.Cell>
                                    <Table.Cell style={{cursor:"pointer"}}>
                                        <Icon name={message.read ? "check" : "close"} color={message.read ? "green" : "red"} onClick={ () => handleRead(message.key)} />
                                    </Table.Cell>
                                    <Route path={"/admin/messages/" + message.key + "/images"} children={<MessageImages photos={message.photos}/>}/>
                                </Table.Row>
                            )
                        }) : "" }
                        </Table.Body>
                        <Table.Footer fullWidth>
                            <Table.Row>
                                <Table.HeaderCell colSpan='9' style={{textAlign:"center", color:"maroon", fontWeight:"bolder"}}>
                                    {messages.length + " Mesaj Alındı..."}                            
                                </Table.HeaderCell>
                            </Table.Row>
                        </Table.Footer>
                    </Table>
                </Grid.Column>
            </Grid>
        );    
}

const mapStateToProps = state => ({
    currentUser: state.user.currentUser
});
  
export default connect(mapStateToProps)(Messages);