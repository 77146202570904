import React from "react";
import firebase from "../firebase";
import {Grid, Header, Dropdown, Image} from "semantic-ui-react";
import {withRouter} from "react-router-dom";

class UserPanel extends React.Component {

    state = {
        user: this.props.currentUser,
        photo : "",
        username : ""
    }

    dropdownOptions = () => [
        {
            key: "user",
            text: <span><u>Oturum Açan Kullanıcı</u><br/><br/><strong>{this.state.username}</strong></span>,
            disabled: true
        },
        {
            key: "signout",
            text: <span onClick={this.handleSignout}>Çıkış Yap</span>
        }
    ]

    handleSignout = () => {
        window["role"] = "";
        firebase.auth().signOut()
        .then(()=>{
            console.log("Signed Out");
            setTimeout(() => {
                this.props.history.push("/login")
            }, 500);
        });
    }

    componentDidMount(){
        if(this.state.user.uid === 1234567890 ){
            this.setState({ photo: "entrepreneurPhotoURL" , username: "Girişimci"});
        } else {
            firebase.database().ref("/users/" + this.state.user.uid).once("value").then((snapshot)=>{
                let person = snapshot.val();
                if(person){
                    this.setState({ photo: person.avatar, username: person.name});
                }
            });
        }
    }

    render(){
        const {user , photo , username} = this.state;
        
        return (
            <Grid style={{ background:"rgba(0,0,0,0)", width:"100%", marginBottom: "5vh"}}>
                <Grid.Column>
                    <Grid.Row style={{padding:"1.2em", margin: 0}}>
                        <Header inverted floated="left" as="h2" style={{paddingLeft:"20%"}}>
                            <Image  src="logo.jpg" size="medium" rounded style={{boxShadow:"0 0 5px white"}} />
                            <Header.Content>
                                YAPSAT
                            </Header.Content>
                        </Header>
                        
                        {user.uid !== 1234567890 ? <Header style={{padding:"0.25em"}} as="h4" inverted>
                            <Dropdown trigger={
                                <span>
                                    <Image src={photo} spaced="right" avatar/>
                                    {username}
                                </span>} 
                                options={this.dropdownOptions()}/>
                        </Header> : ""}

                    </Grid.Row>
                </Grid.Column>
            </Grid>
        )
    }
}



export default withRouter(UserPanel);