import React, {useState, useEffect} from "react";
import {connect} from "react-redux";
import { Grid , Form , Embed} from 'semantic-ui-react';
import firebase from "../firebase";

const AboutUs = () => {
  const [aboutUs, setAboutUs] = useState("");
  const [videoID, setVideoID] = useState("");

  useEffect(()=>{
    firebase.database().ref("aboutus").on("value", (snapshot)=>{
        if(snapshot.val()){
            setAboutUs(snapshot.val().content);
            setVideoID(snapshot.val().videoID);
        }
    })
  },[]);

        return (
          <div className="height" style={{background: "#eee", overflowY:"auto", width:"100vw", overflowX:"hidden" }}>
            <Grid >
              <Grid.Column computer={16} tablet={16} mobile={16} textAlign="center" color="teal" style={{height:"75px"}}>
                  <h1 style={{marginTop:"10px", textDecoration:"underline", textShadow:"0 0 2px black"}}>Hakkımızda</h1>
              </Grid.Column>
              <Grid.Column computer={12} tablet={12} mobile={16} style={{margin:"0 auto"}}>
                  <Embed
                    active={true}
                    hd={true}
                    autoplay={true}
                    id={videoID}
                    source='youtube'
                    iframe={{
                      allowFullScreen: true,
                      style: {
                        padding: 10,
                      }
                    }}
                  />
                  <Form autocomplete="off">
                    <Form.Field>
                      <textarea
                          id="textarea"
                          type="text"
                          name ="aboutUs"
                          value = {aboutUs}
                          style={{height:"2000px", maxHeight:"2000px" , resize:"none", border:0, cursor:"default", fontWeight:550, fontSize:"16px", textAlign:"justify", background: "#eee"}}
                          onFocus={() => document.getElementById("textarea").blur()}
                      />
                    </Form.Field>
                  </Form>
              </Grid.Column>
            </Grid>
          </div>
          )
    
}

const mapStateToProps = state => ({
    currentUser: state.user.currentUser
});
  
export default connect(mapStateToProps)(AboutUs);
