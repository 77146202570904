import React , {useState , useEffect} from "react";
import firebase from "../../firebase";
import { Icon, Table ,Grid , Image} from 'semantic-ui-react';
import {useHistory, useParams , Route} from "react-router-dom";
import {connect} from "react-redux";
import NotificationImages from "./NotificationImages";

const db = firebase.database();


const Notifications = ({currentUser}) => {
    const [messages, setMessages] = useState([]);
    const history = useHistory();
    let {user_id} = useParams();

    useEffect(() => {
        setTimeout(() => {
            db.ref("users/" + user_id + "/messages").orderByChild("role").equalTo("admin").once("value" , (snapshot) => {
                snapshot.forEach(child => {
                    child.ref.update({
                        read : true
                    })
                })
            });
        }, 10000);
    }, [user_id]);

    useEffect(()=>{
        db.ref("users/" + user_id + "/messages").orderByChild("role").equalTo("admin").on("value" , (snapshot) => {
            if(snapshot.val()){
                setMessages(Object.values( snapshot.val() ));
            }
        })
    },[user_id]);

    useEffect(()=>{
        return currentUser.uid !== user_id ? history.push("/") : null
    });


    const handleDelete = (message) => {
        if (window.confirm( 'Bildirimi Silmek İstediğinize Emin misiniz?')){
            message.photos && Object.values(message.photos).map(child => {
                return firebase.storage().ref().child("contact/" + user_id + "/" + message.key + "/" + child.name).delete();
            })

            firebase.database().ref('users/'+ user_id + "/messages/" + message.key).remove()
            .then(()=>{
                console.log("Notification Deleted...");
                history.push("/user/"+ user_id );
                history.push("/user/"+ user_id +"/notifications"); 
            })
        }
    }

        return (
            <Grid textAlign="center" style={{marginTop:"20px"}}>
                <Grid.Column computer={16} tablet={16} mobile={16} style={{overflow:"auto"}}>
                    <Table  celled >
                        <Table.Header>
                        <Table.Row style={{textAlign:"center"}}>
                            <Table.HeaderCell></Table.HeaderCell>                            
                            <Table.HeaderCell>Tarih</Table.HeaderCell>
                            <Table.HeaderCell>Bildirim</Table.HeaderCell>
                            <Table.HeaderCell>Resim</Table.HeaderCell>
                        </Table.Row>
                        </Table.Header>
                        <Table.Body>
                        { messages.sort((b,a)=>{return a.createdAt.localeCompare(b.createdAt)}).map(message => {
                            return (
                                <Table.Row key={message.key} style={{textAlign:"center", background: message.read ? "white" : "salmon"}}>
                                    <Table.Cell>
                                        <Icon name="trash alternate" color="red" style={{fontSize:"25px",cursor:"pointer"}} onClick={()=>handleDelete(message)} />
                                    </Table.Cell>
                                    <Table.Cell>{new Date(message.createdAt).toLocaleDateString()} {new Date(message.createdAt).toLocaleTimeString()}</Table.Cell>
                                    <Table.Cell className="notificationUser"  >{message.message}</Table.Cell>
                                    <Table.Cell >
                                        { message.photos ? <Image 
                                                src={Object.values(message.photos).filter(child => child.profilPhoto === true)[0].photo}
                                                rounded 
                                                size='mini'
                                                style={{cursor:"pointer"}}
                                                onClick={() => history.push("/user/" + user_id + "/notifications/" + message.key + "/photos" )}
                                            /> : "" }
                                    </Table.Cell>
                                    <Route path={"/user/" + user_id + "/notifications/" + message.key + "/photos"} children={<NotificationImages photos={message.photos} back={"/user/" + user_id + "/notifications/"} />}/>
                                </Table.Row>
                            )
                        }) }
                        </Table.Body>
                        <Table.Footer fullWidth>
                            <Table.Row>
                                <Table.HeaderCell colSpan='4' style={{textAlign:"center", color:"maroon", fontWeight:"bolder"}}>
                                    {messages.length + " Bildirim..."}                            
                                </Table.HeaderCell>
                            </Table.Row>
                        </Table.Footer>
                    </Table>
                </Grid.Column>
            </Grid>
        );    
}

const mapStateToProps = state => ({
    currentUser: state.user.currentUser
});
  
export default connect(mapStateToProps)(Notifications);

