import firebase from 'firebase/app';
import "firebase/auth";
import "firebase/database";
import "firebase/storage";



var firebaseConfig = {
  apiKey: "AIzaSyCP_p4C7N4H1MqE-yU0bZelbSTkElK4kdg",
  authDomain: "iemtto-c2c25.firebaseapp.com",
  databaseURL: "https://iemtto-c2c25-default-rtdb.firebaseio.com",
  projectId: "iemtto-c2c25",
  storageBucket: "iemtto-c2c25.appspot.com",
  messagingSenderId: "705170762470",
  appId: "1:705170762470:web:35fda6e1349b1b82c12927",
  measurementId: "G-R7TH4854XF"
};

firebase.initializeApp(firebaseConfig);

export default firebase;