import React from "react";
import firebase from "../../firebase";
import { Button, Icon, Table, Header, Image ,Grid} from 'semantic-ui-react';
import AddUser from "./AddUser";
import UpdateUser from "./UpdateUser";
import {Route, Link} from "react-router-dom";
const db = firebase.database();


class Users extends React.Component {

    constructor(props){
        super(props);
        this.state = { users: [], toogle : true , modal: false, roles: []}
    }

    componentDidMount(){
        db.ref("users").on("value" , (snapshot) => {
            if(snapshot.val()){
                this.setState({ users: Object.values( snapshot.val() ) });
            }
        });

        db.ref("roles").on("value", (snapshot) => {
            if(snapshot.val()){
                let snap = Object.values( snapshot.val() );
                this.setState({ roles: snap });
            }
        })
    }

    closeModal = () => {
        this.setState({modal: false});
    };

    openModal = () => {
        this.setState({modal: true});
    };

    handleRead = (userKey) => {
        db.ref("users/" + userKey).update({
            seen: true
        });
    }

    render(){
        const {users, toogle, modal, roles} = this.state;
        return (
            <Grid textAlign="center" style={{marginTop:"20px"}}>
                <Grid.Column computer={14} tablet={16} mobile={16}>
                    <Table  celled >
                        <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell>Kullanıcı</Table.HeaderCell>
                            <Table.HeaderCell>Kayıt Tarihi</Table.HeaderCell>
                            <Table.HeaderCell>Email Adresi</Table.HeaderCell>
                            <Table.HeaderCell>İletişim Numarası</Table.HeaderCell>
                            <Table.HeaderCell>Referans</Table.HeaderCell>
                            <Table.HeaderCell>Kazanç <br/> Puan</Table.HeaderCell>
                            <Table.HeaderCell>Email Doğrulanma</Table.HeaderCell>
                            <Table.HeaderCell>Görüldü</Table.HeaderCell>
                        </Table.Row>
                        </Table.Header>
                        <Table.Body>
                        { toogle ? users.sort((a,b)=>{return a.role.localeCompare(b.role)}).slice(0,10).map(user => {
                            return (
                                <Table.Row key={user.key} style={{background: user.seen ? "white" : "salmon"}}>
                                    <Table.Cell>
                                        <Link to={"/admin/users/" + user.key}>
                                            <Header as='h4' image >
                                                <Image src={user.avatar} rounded size='mini' />
                                                <Header.Content>
                                                    {user.name}
                                                    <Header.Subheader> { roles && roles.filter(child => child.role === user.role )[0] ? roles.filter(child => child.role === user.role )[0].displayName : ""}</Header.Subheader>
                                                </Header.Content>
                                            </Header>
                                        </Link>
                                    </Table.Cell>
                                    <Table.Cell>{new Date(user.createdAt).toLocaleDateString()} {new Date(user.createdAt).toLocaleTimeString()}</Table.Cell>
                                    <Table.Cell>{user.email}</Table.Cell>
                                    <Table.Cell>{user.phoneNumber}</Table.Cell>
                                    <Table.Cell>{user.referance}</Table.Cell>
                                    <Table.Cell>{user.price} { user.price && user.point && <hr/>} {user.point} </Table.Cell>
                                    <Table.Cell style={{textAlign:"center"}} >{user.emailVerified ? <Icon name='check' color="green"/> : <Icon name='close' color="red"/>}</Table.Cell>
                                    <Table.Cell style={{cursor:"pointer", textAlign:"center"}}>
                                        <Icon name={user.seen ? "check" : "close"} color={user.seen ? "green" : "red"} onClick={ () => this.handleRead(user.key)} />
                                    </Table.Cell>
                                </Table.Row>
                            )
                        }) : users.sort((a,b)=>{return a.role.localeCompare(b.role)}).map(user => {
                            return (
                            
                                <Table.Row key={user.key} style={{background: user.seen ? "white" : "salmon"}}>
                                    <Table.Cell>
                                        <Link to={"/admin/users/" + user.key} >
                                            <Header as='h4' image >
                                                <Image src={user.avatar} rounded size='mini' />
                                                <Header.Content>
                                                    {user.name}
                                                    <Header.Subheader>{ roles && roles.filter(child => child.role === user.role )[0] ? roles.filter(child => child.role === user.role )[0].displayName : ""}</Header.Subheader>
                                                </Header.Content>
                                            </Header>
                                        </Link>
                                    </Table.Cell>
                                    <Table.Cell>{new Date(user.createdAt).toLocaleDateString()} {new Date(user.createdAt).toLocaleTimeString()}</Table.Cell>
                                    <Table.Cell>{user.email}</Table.Cell>
                                    <Table.Cell>{user.phoneNumber}</Table.Cell>
                                    <Table.Cell>{user.referance}</Table.Cell>
                                    <Table.Cell>{user.price} { user.price && user.point && <hr/>} {user.point} </Table.Cell>
                                    <Table.Cell style={{textAlign:"center"}} >{user.emailVerified ? <Icon name='check' color="green"/> : <Icon name='close' color="red"/>}</Table.Cell>
                                    <Table.Cell style={{cursor:"pointer", textAlign:"center"}}>
                                        <Icon name={user.seen ? "check" : "close"} color={user.seen ? "green" : "red"} onClick={ () => this.handleRead(user.key)} />
                                    </Table.Cell>
                                </Table.Row>
                            
                            )
                        }) }
                        </Table.Body>
                        <Table.Footer fullWidth>
                        <Table.Row>
                            <Table.HeaderCell colSpan='8'>
                            <Button
                                floated='right'
                                icon
                                labelPosition='left'
                                primary
                                size='small'
                                onClick={ this.openModal }
                            >
                                <Icon name='user' /> Kullanıcı Ekle
                            </Button>
                            {users.length >= 11 ? <Button size='small' onClick={()=> this.setState({toogle : !toogle})}>{toogle ? "Tamamını Göster" : "Gizle"}</Button> : ""}
                            </Table.HeaderCell>
                        </Table.Row>
                        </Table.Footer>
                    </Table>
                    <AddUser modal={modal} closeModal={this.closeModal}/>
                    <Route path="/admin/users/:updated_user_id" component={UpdateUser}/>
                </Grid.Column>
            </Grid>
        );
    }
}

export default Users;