import React from "react";
import {Modal, Button, Icon, Image} from 'semantic-ui-react';
import {useHistory} from "react-router-dom";
import Spinner from "../Spinner";

const MessageImages = ({photos}) => {
    let history = useHistory();

    const closeModal = () => {
        history.push("/admin/messages");
    }

    return (
        <Modal basic open={true} onClose={closeModal}>
            <Modal.Content>
                { photos ? Object.values(photos).map((child) => {
                    return (
                        <div key={child.key} style={{width:"32%",position:"relative", float:"left" ,paddingTop:"32%",marginRight:"1%", marginBottom:"1%"}}>
                            <Image style={{position:"absolute", top:0,height:"100%",width:"100%",borderRadius:"10px",boxShadow:"0 0 3px teal"}} src={child.photo} alt="messagePhoto" ></Image>
                        </div>
                    )
                }) : <Spinner/> }
            </Modal.Content>   
            <Modal.Actions>
                <Button color="red" inverted onClick={closeModal}>
                    <Icon name="remove"/> Kapat
                </Button>
            </Modal.Actions>
        </Modal>  
    )
}

export default MessageImages;