import React, {useState , useEffect} from "react";
import {connect} from "react-redux";
import { Grid , Sidebar ,Icon ,Menu, Input, Header, Button, Form, List, Image, Modal, Card } from 'semantic-ui-react';
import Slider from "./Slider";
import firebase from "../firebase";
import {Link , useHistory} from "react-router-dom";
import md5 from "md5";


const MainPageContent = ({currentUser}) => {
    const [projects,setProjects] = useState([]);
    const [project,setProject] = useState([]);
    const [visible,setVisible] = useState(false);
    const [search, setSearch] = useState("");
    const [filteredMainName, setFilteredMainName] = useState("");
    const [filteredAuxiliaryName, setFilteredAuxiliaryName] = useState("");
    const [filteredSubAuxiliaryName, setFilteredSubAuxiliaryName] = useState("");
    const [filteredStatus, setFilteredStatus] = useState("");
    const [filteredCategory, setFilteredCategory] = useState("");
    const [projectNames, setProjectNames] = useState([]);
    const [status, setStatus] = useState([]);
    const [category, setCategory] = useState([]);
    const [width, setWidth] = useState("");
    const [maxWidth, setMaxWidth] = useState("");
    const [height, setHeight] = useState("");
    const [modal, setModal] = useState(false);
    const emptyImage = "http://gravatar.com/avatar/" + md5(currentUser.email) + "?d=identicon" ;
    const history = useHistory();

    useEffect(()=>{
      firebase.database().ref("projects").orderByChild("done").equalTo(false).on("value", (snapshot)=>{
        if(snapshot.val()){
            let time = new Date().getTime();
            let offset = new Date().getTimezoneOffset();
            offset = offset * 60000;
            offset = offset + 10800000;
            let now = time - offset;
            let snap = Object.values(snapshot.val());
            currentUser.role === "admin" ? setProjects(snap) : setProjects(snap.filter(project => project.projectPublishTime < now));
            currentUser.role === "admin" ? setProject(snap) : setProject(snap.filter(project => project.projectPublishTime < now));
        }
      })
    },[ currentUser.role ]);

    useEffect(()=>{
      let time = new Date().getTime();
      let offset = new Date().getTimezoneOffset();
      offset = offset * 60000;
      offset = offset + 10800000;
      let now = time - offset;

      project.filter(child =>{
         let difference = now - child.projectPublishTime;
         return difference >= 864000000
      }).map(child => {
        return (
          firebase.database().ref("projects/" + child.key).update({
            upToDate : "Güncel Değil"
          })
        )
      });

      project.filter(child =>{
        let difference = now - child.projectPublishTime;
        return difference <= 864000000
        }).map(child => {
          return (
            firebase.database().ref("projects/" + child.key).update({
              upToDate : "Güncel"
            })
          )
        });
    },[project]);

    useEffect(()=>{
      firebase.database().ref("/projectNames").once("value", snapshot => {
          setProjectNames(snapshot.val());
      });
    },[filteredMainName, filteredAuxiliaryName, filteredSubAuxiliaryName]);

    useEffect(()=>{
      firebase.database().ref("/status").once("value", (snapshot)=>{
          setStatus(snapshot.val());
      });
    },[ filteredStatus ]);

    useEffect(()=>{
      firebase.database().ref("/category").once("value", (snapshot)=>{
          setCategory(snapshot.val());
      });
    },[ filteredCategory ]);

    useEffect(()=>{
      setFilteredAuxiliaryName("");
      setFilteredSubAuxiliaryName("");
    },[filteredMainName]);

    useEffect(()=>{
      setFilteredSubAuxiliaryName("");
    },[filteredAuxiliaryName]);

    useEffect(()=>{
      if(search){
        setFilteredMainName("");
        setFilteredAuxiliaryName("");
        setFilteredSubAuxiliaryName("");
        setFilteredStatus("");
        setFilteredCategory("");
      } 
    },[search, filteredMainName, filteredAuxiliaryName, filteredSubAuxiliaryName, filteredStatus, filteredCategory])

    useEffect(()=>{
      project.length === 1 ? setWidth("100vw") : setWidth("100%");
      project.length === 1 ? setMaxWidth("100vw") : setMaxWidth("200px");
      project.length === 1 ? setHeight("80vh") : setHeight("150px");
    },[project]);

    const handleResetFilter = () => {
      setProjects(project);
      setFilteredMainName("");
      setFilteredAuxiliaryName("");
      setFilteredSubAuxiliaryName("");
      setFilteredStatus("");
      setFilteredCategory("");
      setSearch("");
    }

    const openModal = () => {
      setModal(true);
    }

    const closeModal = () => {
      setModal(false);
      handleResetFilter();
    }

    const handleFilter = () => {
      openModal();
      if(search) {
        if(project.filter(child => child.projectMainName.toUpperCase() === search.toUpperCase())[0]){
          setProjects(project.filter(child => child.projectMainName.toUpperCase() === search.toUpperCase()))
        } else if(project.filter(child => child.projectAuxiliaryName.toUpperCase() === search.toUpperCase())[0]) {
          setProjects(project.filter(child => child.projectAuxiliaryName.toUpperCase() === search.toUpperCase()))
        } else if(project.filter(child => child.projectSubAuxiliaryName.toUpperCase() === search.toUpperCase())[0]) {
          setProjects(project.filter(child => child.projectSubAuxiliaryName.toUpperCase() === search.toUpperCase()))
        } else if(project.filter(child => child.name.toUpperCase() === search.toUpperCase())[0]) {
          setProjects(project.filter(child => child.name.toUpperCase() === search.toUpperCase()))
        } else {
          setProjects([]);
        }
      } else {
        if(filteredMainName && !filteredAuxiliaryName && !filteredSubAuxiliaryName && !filteredStatus && !filteredCategory){
          setProjects(project.filter(child => child.projectMainName === filteredMainName))
        } else if(filteredMainName && filteredAuxiliaryName && !filteredSubAuxiliaryName && !filteredStatus && !filteredCategory) {
          setProjects(project.filter(child => child.projectMainName === filteredMainName && child.projectAuxiliaryName === filteredAuxiliaryName))
        } else if(filteredMainName && filteredAuxiliaryName && filteredSubAuxiliaryName && !filteredStatus && !filteredCategory) {
          setProjects(project.filter(child => child.projectMainName === filteredMainName && child.projectAuxiliaryName === filteredAuxiliaryName && child.projectSubAuxiliaryName === filteredSubAuxiliaryName))
        } else if(filteredMainName && filteredAuxiliaryName && filteredSubAuxiliaryName && filteredStatus && !filteredCategory) {
          setProjects(project.filter(child => child.projectMainName === filteredMainName && child.projectAuxiliaryName === filteredAuxiliaryName && child.projectSubAuxiliaryName === filteredSubAuxiliaryName && Object.keys(child.projectStatus).some(statu => statu === filteredStatus)))
        } else if(filteredMainName && filteredAuxiliaryName && filteredSubAuxiliaryName && !filteredStatus && filteredCategory) {
          setProjects(project.filter(child => child.projectMainName === filteredMainName && child.projectAuxiliaryName === filteredAuxiliaryName && child.projectSubAuxiliaryName === filteredSubAuxiliaryName && child.projectCategory === filteredCategory))
        } else if(filteredMainName && filteredAuxiliaryName && filteredSubAuxiliaryName && filteredStatus && filteredCategory) {
          setProjects(project.filter(child => child.projectMainName === filteredMainName && child.projectAuxiliaryName === filteredAuxiliaryName && child.projectSubAuxiliaryName === filteredSubAuxiliaryName && child.projectCategory === filteredCategory && Object.keys(child.projectStatus).some(statu => statu === filteredStatus)))
        } else if(filteredMainName && filteredAuxiliaryName && !filteredSubAuxiliaryName && filteredStatus && filteredCategory) {
          setProjects(project.filter(child => child.projectMainName === filteredMainName && child.projectAuxiliaryName === filteredAuxiliaryName && child.projectCategory === filteredCategory && Object.keys(child.projectStatus).some(statu => statu === filteredStatus)))
        } else if(filteredMainName && filteredAuxiliaryName && !filteredSubAuxiliaryName && !filteredStatus && filteredCategory) {
          setProjects(project.filter(child => child.projectMainName === filteredMainName && child.projectAuxiliaryName === filteredAuxiliaryName && child.projectCategory === filteredCategory ))
        } else if(filteredMainName && filteredAuxiliaryName && !filteredSubAuxiliaryName && filteredStatus && !filteredCategory) {
          setProjects(project.filter(child => child.projectMainName === filteredMainName && child.projectAuxiliaryName === filteredAuxiliaryName && Object.keys(child.projectStatus).some(statu => statu === filteredStatus)))
        } else if(filteredMainName && !filteredAuxiliaryName && !filteredSubAuxiliaryName && filteredStatus && filteredCategory) {
          setProjects(project.filter(child => child.projectMainName === filteredMainName && child.projectCategory === filteredCategory && Object.keys(child.projectStatus).some(statu => statu === filteredStatus)))
        } else if(filteredMainName && !filteredAuxiliaryName && !filteredSubAuxiliaryName && !filteredStatus && filteredCategory) {
          setProjects(project.filter(child => child.projectMainName === filteredMainName && child.projectCategory === filteredCategory))
        } else if(filteredMainName && !filteredAuxiliaryName && !filteredSubAuxiliaryName && filteredStatus && !filteredCategory) {
          setProjects(project.filter(child => child.projectMainName === filteredMainName && Object.keys(child.projectStatus).some(statu => statu === filteredStatus)))
        } else if(!filteredMainName && !filteredAuxiliaryName && !filteredSubAuxiliaryName && filteredStatus && filteredCategory) {
          setProjects(project.filter(child => child.projectCategory === filteredCategory && Object.keys(child.projectStatus).some(statu => statu === filteredStatus)))
        } else if(!filteredMainName && !filteredAuxiliaryName && !filteredSubAuxiliaryName && !filteredStatus && filteredCategory) {
          setProjects(project.filter(child => child.projectCategory === filteredCategory ))
        } else if(!filteredMainName && !filteredAuxiliaryName && !filteredSubAuxiliaryName && filteredStatus && !filteredCategory) {
          setProjects(project.filter(child => Object.keys(child.projectStatus).some(statu => statu === filteredStatus)))
        } else if(!filteredMainName && !filteredAuxiliaryName && !filteredSubAuxiliaryName && !filteredStatus && !filteredCategory) {
          setProjects(project)
        }
      }
    }

    const categories = filteredMainName && projectNames && Object.values(projectNames).filter(projectNames=> projectNames.projectMainName === filteredMainName)[0] ? Object.values(Object.values(projectNames).filter(projectNames=> projectNames.projectMainName === filteredMainName)[0].categories) : "" ;
    const subCategories = filteredMainName && projectNames && filteredAuxiliaryName && Object.values(projectNames).filter(projectNames=> projectNames.projectMainName === filteredMainName)[0] && Object.values(Object.values(projectNames).filter(projectNames=> projectNames.projectMainName === filteredMainName)[0].categories).filter(child => child.projectAuxiliaryName === filteredAuxiliaryName)[0] ? Object.values(Object.values(Object.values(projectNames).filter(projectNames=> projectNames.projectMainName === filteredMainName)[0].categories).filter(child => child.projectAuxiliaryName === filteredAuxiliaryName)[0].subCategories) : "" ;

    return (
      <Grid style={{background: "#eee"}}>
        <Grid.Column >
          <Icon
              name={visible ? "angle double right" : "search"} 
              size = "large"
              color={visible ? "red" : "blue"}
              style={{position:"fixed",right:"10px", top:"15px", zIndex:1000, cursor:"pointer"}}
              onClick ={()=> setVisible(!visible)}
          />
          <Sidebar.Pushable style={{margin:"0 0"}}>
            <Sidebar
              as={Menu}
              animation='overlay'
              direction='right'
              inverted
              vertical
              visible={visible}
              style={{background:"rgba(20,20,20,0.8)"}}
              className="height"
            >
              <Menu.Item >
                <Header textAlign="center" color="teal">Projeleri Filtrele</Header>
              </Menu.Item>
              <Menu.Item >
                <Form autocomplete="off">
                  <Input
                    fluid
                    type="text"
                    placeholder="Ara"
                    name = "search"
                    value = {search}
                    onChange={e => setSearch(e.target.value)}
                    id="search"
                  />
                </Form>
              </Menu.Item>
              <Menu.Item >
                <Form autocomplete="off">
                  <Input
                    className="filterProject"
                    fluid
                    type="text"
                    placeholder="Ana Ada Göre Filtrele"
                    name = "filteredMainName"
                    value = {filteredMainName}
                    onChange={e => setFilteredMainName(e.target.value)}
                    list="projectMainNames"
                  />
                  <datalist id='projectMainNames'>
                    {projectNames ? Object.values(projectNames).map((child)=>{
                      return (<option key={child.key} value={child.projectMainName}>{child.projectMainName}</option>)
                    }) : ""}
                  </datalist>
                </Form>
              </Menu.Item>
              {filteredMainName ? <Menu.Item >
                <Form autocomplete="off">
                  <Input
                    className="filterProject"
                    fluid
                    type="text"
                    placeholder="Yardımcı Ada Göre Filtrele"
                    name = "filteredAuxiliaryName"
                    value = {filteredAuxiliaryName}
                    onChange={e => setFilteredAuxiliaryName(e.target.value)}
                    list="projectAuxiliaryNames"
                  />
                  <datalist id='projectAuxiliaryNames'>
                    {projectNames && filteredMainName && Object.values(projectNames).filter(projectNames=> projectNames.projectMainName === filteredMainName)[0] ? categories.map((child)=>{
                      return (<option key={child.key} value={child.projectAuxiliaryName}>{child.projectAuxiliaryName}</option>)
                    }) : ""}
                  </datalist>
                </Form>
              </Menu.Item> : ""}
              { filteredMainName && filteredAuxiliaryName ? <Menu.Item >
                <Form autocomplete="off">
                  <Input
                    className="filterProject"
                    fluid
                    type="text"
                    placeholder="Alt Yardımcı Ada Göre Filtrele"
                    name = "filteredSubAuxiliaryName"
                    value = {filteredSubAuxiliaryName}
                    onChange={e => setFilteredSubAuxiliaryName(e.target.value)}
                    list="projectSubAuxiliaryNames"
                  />
                  <datalist id='projectSubAuxiliaryNames'>
                    {projectNames && filteredMainName && filteredAuxiliaryName && Object.values(projectNames).filter(projectNames=> projectNames.projectMainName === filteredMainName)[0] && Object.values(Object.values(projectNames).filter(projectNames=> projectNames.projectMainName === filteredMainName)[0].categories).filter(child => child.projectAuxiliaryName === filteredAuxiliaryName)[0] ? subCategories.map((child)=>{
                      return (<option key={child.key} value={child.projectSubAuxiliaryName}>{child.projectSubAuxiliaryName}</option>)
                    }) : ""}
                  </datalist>
                </Form>
              </Menu.Item> : ""}
              <Menu.Item >
                <Form autocomplete="off">
                  <Input
                    className="filterProject"
                    fluid
                    type="text"
                    placeholder="Statüye Göre Filtrele"
                    name = "filteredStatus"
                    value = {filteredStatus}
                    onChange={e => setFilteredStatus(e.target.value)}
                    list="projectStatus"
                  />
                  <datalist id='projectStatus'>
                    {status ? Object.values(status).map((child)=>{
                      return (
                        <option key={child.key} value={child.value} >{child.text}</option>
                      )
                    }) : ""}
                  </datalist>
                </Form>
              </Menu.Item>
              <Menu.Item >
                <Form autocomplete="off">
                  <Input
                    className="filterProject"
                    fluid
                    type="text"
                    placeholder="Kategoriye Göre Filtrele"
                    name = "filteredCategory"
                    value = {filteredCategory}
                    onChange={e => setFilteredCategory(e.target.value)}
                    list="projectCategory"
                  />
                  <datalist id='projectCategory'>
                    {category ? Object.values(category).map((child)=>{
                      return (
                        <option key={child.key} value={child.value} >{child.value}</option>
                      )
                    }) : ""}
                  </datalist>
                </Form>
              </Menu.Item>
              { filteredMainName || filteredAuxiliaryName || filteredSubAuxiliaryName || filteredStatus || filteredCategory || search ? <Menu.Item >
                <Button color="green" fluid size="medium" onClick={handleFilter} ><Icon name="filter" /> Filtrele</Button>
              </Menu.Item> : ""}
              { filteredMainName || filteredAuxiliaryName || filteredSubAuxiliaryName || filteredStatus || filteredCategory || search ? <Menu.Item >
                <Button color="red" fluid size="medium" onClick={handleResetFilter} ><Icon name="filter" /> Filtreyi Sıfırla</Button>
              </Menu.Item> : ""}
            </Sidebar>
            <Sidebar.Pusher className="height" style={{paddingTop:"10px", background: "#eee", overflowY:"auto"}}>
              <Grid style={{background: "#eee"}}>
                <Grid.Column computer={3} tablet={3} mobile={0} style={{textAlign:"center"}} id="leftSide">
                  <Header color="#314152">Tüm Projeler</Header><hr/>
                  <List divided >
                    {project.map(child =>{
                      return (
                          <List.Item key={child.key}>
                            <Link to={"/project/" + child.key}>
                              <Image avatar src={ child.photos && Object.values(child.photos).filter(photo => photo.profilPhoto === true)[0] ? Object.values(child.photos).filter(photo => photo.profilPhoto === true)[0].photo : emptyImage} />
                            </Link>
                            <List.Content style={{display:"inline-grid"}}>
                              <List.Header as='a' style={{cursor:"default"}}>{child.name}</List.Header>
                              <List.Description style={{fontSize:"15px", color:"grey"}}>
                                {child.projectMainName}
                              </List.Description>
                            </List.Content>
                          </List.Item>
                      )
                    })}
                  </List>
                </Grid.Column>
                <Grid.Column computer={10} tablet={10} mobile={16}>
                  <Header id="textSliderContainer">
                   <b id="textSlider1">Senin Üretimin, Senin Geleceğin...</b><b id="textSlider2"> Your Manufacturing, Your Future...</b>
                  </Header>
                  {projectNames ? Object.keys(projectNames).map(child => {
                    return (
                      <div>
                        { project.filter(proje => proje.projectMainName === child)[0] ? <Header style={{textAlign:"center", backgroundColor:"#03A6AB80", color:"white" , textShadow:"0 0 2px black" }}><u>{child}</u></Header> : ""}
                        { project.filter(proje => proje.projectMainName === child)[0] ? <Slider maxWidth={maxWidth} width={width} height={height} projects={project.filter(proje => proje.projectMainName === child)}/> : ""}
                      </div>
                    )
                  }) : null}
                  <Modal basic open={modal} onClose={closeModal}>
                      <Modal.Content>
                      { projects.length > 0 ? projects.map(child => {
                        return (
                          <Card key={child.key} className="projectCart" style={{ padding:"10px", float:"left", marginBottom:"10px" , marginTop:0, marginRight:"10px" }}>
                            <Image style={{cursor:"pointer"}} onClick={()=> history.push("/project/" + child.key)} className="advisorImage" src={child.photos && Object.values(child.photos).filter(photo => photo.profilPhoto === true)[0] ? Object.values(child.photos).filter(photo => photo.profilPhoto === true)[0].photo : emptyImage} wrapped ui={false}  />
                            <Card.Content>
                            <Card.Header style={{height:"50px", overflowY:"auto"}}>{child.name}</Card.Header>
                            <Card.Description style={{height:"150px", overflowY:"auto"}}>
                                <strong>Proje Ana Adı: </strong>{child.projectMainName}
                                <br/>
                                <strong>Proje Yardımcı Adı: </strong>{child.projectAuxiliaryName}
                                <br/>
                                <strong>Proje Alt Yardımcı Adı: </strong>{child.projectSubAuxiliaryName}
                            </Card.Description>
                            </Card.Content>
                            { currentUser.role === "admin" || currentUser.email === child.advisor ? <Card.Content extra>
                                <Link to={"/project/" + child.key + "/update"}>
                                    <Button floated="right" size="mini" color="olive">Düzenle</Button>
                                </Link>
                            </Card.Content> : ""}
                          </Card>
                        )
                      }) : <h1 style={{textAlign:"center"}}>Sonuç Bulunamadı...</h1> }
                      </Modal.Content>   
                      <Modal.Actions>
                          <Button color="red" inverted onClick={closeModal}>
                              <Icon name="remove"/> Kapat
                          </Button>
                      </Modal.Actions>
                  </Modal>  
                </Grid.Column>
                <Grid.Column computer={3} tablet={3} mobile={16} style={{textAlign:"center"}}>
                  <Header color="#314152">Güncel Projeler</Header><hr/>
                  <List divided >
                    {project.filter( child => child.upToDate === "Güncel" ).map(child =>{
                      return (
                          <List.Item style={{direction: "ltr"}} key={child.key}>
                            <Link to={"/project/" + child.key}>
                              <Image avatar src={ child.photos && Object.values(child.photos).filter(photo => photo.profilPhoto === true)[0] ? Object.values(child.photos).filter(photo => photo.profilPhoto === true)[0].photo : emptyImage} />
                            </Link>
                            <List.Content style={{display:"inline-grid"}}>
                              <List.Header as='a' style={{cursor:"default"}}>{child.name}</List.Header>
                              <List.Description style={{fontSize:"15px", color:"grey"}}>
                                {child.projectMainName}
                              </List.Description>
                            </List.Content>
                          </List.Item>
                      )
                    })}
                  </List>
                </Grid.Column> 
              </Grid>             
            </Sidebar.Pusher>
          </Sidebar.Pushable>
        </Grid.Column>
      </Grid>
    );
}

const mapStateToProps = state => ({
    currentUser: state.user.currentUser
});
  
export default connect(mapStateToProps)(MainPageContent);