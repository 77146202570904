import React , {useState , useEffect} from "react";
import firebase from "../../firebase";
import { Icon, Table, Header ,Grid , Image , Button} from 'semantic-ui-react';
import {useHistory, useParams , Route , Link} from "react-router-dom";
import {connect} from "react-redux";
import UpdateEntrepreneur from "./UpdateEntrepreneur";
import AddEntrepreneur from "./AddEntrepreneur";

const db = firebase.database();


const Entrepreneurs = ({currentUser}) => {
    const [entrepreneurs, setEntrepreneurs] = useState([]);
    const [sentEntrepreneurs, setSentEntrepreneurs] = useState([]);
    const [toogle , setToogle] = useState(true);
    const [modal , setModal] = useState(false);
    const history = useHistory();
    let {user_id} = useParams();

    useEffect(()=>{
        db.ref("users/" + user_id + "/entrepreneurs").on("value" , (snapshot) => {
            if(snapshot.val()){
                let snap = Object.values( snapshot.val() );
                setEntrepreneurs(snap);
            }
        })
    },[user_id]);

    useEffect(() => {
        db.ref("users").orderByChild("role").equalTo("entrepreneur").on("value", snapshot=>{
            if(snapshot.val()){
                let snap = Object.values(snapshot.val());
                let myEntrepreneurs = snap.filter(child=> child.referance === currentUser.email);
                setSentEntrepreneurs(myEntrepreneurs);
            }
        })
    }, [ currentUser.email ]);

    useEffect(()=>{
        return currentUser.uid !== user_id ? history.push("/") : null
    });

    const closeModal = () => {
        setModal(false);
    };

    const openModal = () => {
        setModal(true);
    };

        return (
            <Grid textAlign="center" style={{marginTop:"20px"}}>
                <Grid.Column computer={12} tablet={12} mobile={16}>
                    <Table  celled >
                        <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell>Girişimci</Table.HeaderCell>
                            <Table.HeaderCell>Email Adresi</Table.HeaderCell>
                            <Table.HeaderCell>İletişim Numarası</Table.HeaderCell>
                        </Table.Row>
                        </Table.Header>
                        <Table.Body>
                        {sentEntrepreneurs ? sentEntrepreneurs.map(user => {
                            return (
                                <Table.Row key={user.key} style={{background:"aqua"}} title="Yönetici Tarafından Atanan Girişimci..." >
                                    <Table.Cell>
                                        <Header as='h4' image >
                                            <Image src={user.avatar} rounded size='mini' />
                                            <Header.Content>
                                                {user.name}
                                            </Header.Content>
                                        </Header>
                                    </Table.Cell>
                                    <Table.Cell>{user.email}</Table.Cell>
                                    <Table.Cell>{user.phoneNumber}</Table.Cell>
                                </Table.Row>
                            )
                        }) : "" }
                        { toogle ? entrepreneurs.slice(0,10).map(user => {
                            return (
                                <Table.Row key={user.key}>
                                    <Table.Cell>
                                        <Link to={"/user/" + user_id + "/entrepreneurs/" + user.key}>
                                            <Header as='h4' image >
                                                <Image src={user.avatar} rounded size='mini' />
                                                <Header.Content>
                                                    {user.name}
                                                </Header.Content>
                                            </Header>
                                        </Link>
                                    </Table.Cell>
                                    <Table.Cell>{user.email}</Table.Cell>
                                    <Table.Cell>{user.phoneNumber}</Table.Cell>
                                </Table.Row>
                            )
                        }) : entrepreneurs.map(user => {
                            return (
                            
                                <Table.Row key={user.key}>
                                    <Table.Cell>
                                        <Link to={"/user/" + user_id + "/entrepreneurs/" + user.key} >
                                            <Header as='h4' image >
                                                <Image src={user.avatar} rounded size='mini' />
                                                <Header.Content>
                                                    {user.name}
                                                </Header.Content>
                                            </Header>
                                        </Link>
                                    </Table.Cell>
                                    <Table.Cell>{user.email}</Table.Cell>
                                    <Table.Cell>{user.phoneNumber}</Table.Cell>
                                </Table.Row>
                            )
                        }) }
                        </Table.Body>
                        <Table.Footer fullWidth>
                        <Table.Row>
                            <Table.HeaderCell colSpan='3'>
                            <Button
                                floated='right'
                                icon
                                labelPosition='left'
                                primary
                                size='small'
                                onClick={openModal }
                            >
                                <Icon name='user' /> Girişimci Ekle
                            </Button>
                            {entrepreneurs.length >= 11 ? <Button size='small' onClick={()=> setToogle(!toogle)}>{toogle ? "Tamamını Göster" : "Gizle"}</Button> : ""}
                            </Table.HeaderCell>
                        </Table.Row>
                        </Table.Footer>
                    </Table>
                    <AddEntrepreneur modal={modal} closeModal={closeModal}/>
                    <Route path="/user/:user_id/entrepreneurs/:entrepreneur_id" component={UpdateEntrepreneur}/>
                </Grid.Column>
            </Grid>
        );    
}

const mapStateToProps = state => ({
    currentUser: state.user.currentUser
});
  
export default connect(mapStateToProps)(Entrepreneurs);

