import React ,{useState, useEffect} from "react";
import {connect} from "react-redux";
import {
    Grid,
    Icon,
    Menu,
    Sidebar
} from 'semantic-ui-react';
import {Link, Route, useLocation ,useHistory} from "react-router-dom";
import UserPanel from "../UserPanel";
import Users from "./Users";
import Messages from "./Messages";
import AddProject from "./AddProject";
import DataTyping from "./DataTyping";
import PreApplications from "./PreApplications";
import AboutUsEdit from "./AboutUsEdit";
import FAQEdit from "./FAQEdit";
import ContactEdit from "./ContactEdit";
import SendNotification from "./SendNotification";
import DoneProjects from "./DoneProjects";
import AdvisorsWeb from "./AdvisorsWeb";
import UpdateBackgroundImage from "./UpdateBackgroundImage";
import firebase from "../../firebase";

const AdminPage = ({currentUser}) => {
    const location = useLocation();
    const history = useHistory();
    const [visible, setVisible] = useState(true);
    const [messages, setMessages] = useState([]);
    const [preApplications, setPreApplications] = useState([]);
    const [users, setUsers] = useState([]);
    const [doneProjects, setDoneProjects] = useState([]);
    const [backgroundImage, setBackgroundImage] = useState("");

    useEffect(()=>{
      if(currentUser.role !== "admin"){
        history.push("/");
      }
    });

    useEffect(() => {
      firebase.database().ref("backgroundImage/admin").once("value", snapshot => {
          let background = snapshot.val();
          if(background){
              setBackgroundImage(background.backgroundImage)
          }
      })
    }, [location]);

    useEffect(() => {
      firebase.database().ref("projects").orderByChild("done").equalTo(true).on("value", snapshot => {
        if(snapshot.val()){
          setDoneProjects(Object.values( snapshot.val() ))
        }
      })
    }, []);

    useEffect(()=>{
      firebase.database().ref("messages").on("value" , (snapshot) => {
        if(snapshot.val()){
            setMessages(Object.values( snapshot.val() ));
        }
      })
    }, []);

    useEffect(()=>{
      firebase.database().ref("preapplications").on("value" , (snapshot) => {
          if(snapshot.val()){
              setPreApplications(Object.values( snapshot.val() ));
          }
      })
    }, []);

    useEffect(() => {
      firebase.database().ref("users").on("value" , (snapshot) => {
          if(snapshot.val()){
              setUsers(Object.values( snapshot.val() )) 
          }
      })
    }, []);

    const unReadMessagesLength = messages.filter(child => child.read === false).length ;
    const unReadPreApplicationsLength = preApplications.filter(child => child.read === false).length ;
    const unSeenUsersLength = users.filter(child => child.seen === false).length ;
    const unSeenDoneProjectsLength = doneProjects.filter(child => child.seen === false ).length ;

        return (
            <Grid columns={1} style={{background:"rgb(200,200,200)"}} >
              <Icon
                    name={visible ? "angle double left" : "angle double right"} 
                    color={visible ? "blue" : "black"}
                    style={{position:"fixed",float:"left", marginTop:"50vh",zIndex:1000,fontSize:"30px", marginLeft:"10px"}}
                    onClick ={()=> setVisible(!visible)}
              />

              <Grid.Column>
                <Sidebar.Pushable>
                
                  <Sidebar
                    as={Menu}
                    animation='overlay'
                    direction='left'
                    icon='labeled'
                    inverted
                    vertical
                    visible={visible}
                    width='large'
                    style={{background:"rgba(20,20,20,0.8)", height:"97.5vh"}}
                  >
                    <UserPanel currentUser={currentUser}/>
                    <Menu.Item as='a'>
                      <Link to="/">
                        <Icon name='home' color="olive" />
                        Ana Sayfa
                      </Link>
                    </Menu.Item>
                    {location.pathname !== "/admin" ? <Menu.Item as='a'>
                      <Link to="/admin">
                        <Icon name='user' color="purple" />
                        Yönetici Paneli
                      </Link>
                    </Menu.Item> : ""}
                    <Menu.Item as='a'>
                      <Link to="/admin/users">
                        <Icon name='list alternate outline' color="orange" />
                        {unSeenUsersLength !== 0 ? <b style={{color:"red"}}> ( {unSeenUsersLength} ) </b> : ""} Kullanıcılar
                      </Link>
                    </Menu.Item>
                    <Menu.Item as='a'>
                      <Link to="/admin/messages">
                        <Icon name='envelope' color="blue" />
                        {unReadMessagesLength !== 0 ? <b style={{color:"red"}}> ( {unReadMessagesLength} ) </b> : ""} Gelen Mesajlar
                      </Link>
                    </Menu.Item>
                    <Menu.Item as='a'>
                      <Link to="/admin/preapplications">
                        <Icon name='gem' color="pink" />
                        {unReadPreApplicationsLength !== 0 ? <b style={{color:"red"}}> ( {unReadPreApplicationsLength} ) </b> : ""} Ön Başvurular
                      </Link>
                    </Menu.Item>
                    <Menu.Item as='a'>
                      <Link to="/admin/addproject">
                        <Icon name='plus circle' color="yellow" />
                        Proje Ekle
                      </Link>
                    </Menu.Item>
                    <Menu.Item as='a'>
                      <Link to="/admin/doneprojects">
                        <Icon name="check circle outline" color="olive" />
                        {unSeenDoneProjectsLength !== 0 ? <b style={{color:"red"}}> ( {unSeenDoneProjectsLength} ) </b> : ""} Biten Projeler
                      </Link>
                    </Menu.Item>
                    <Menu.Item as='a'>
                      <Link to="/admin/datatyping">
                        <Icon name='database' color="teal" />
                        Veri Girişi
                      </Link>
                    </Menu.Item>
                    <Menu.Item as='a'>
                      <Link to="/admin/advisorsweb">
                        <Icon name='sitemap' color="orange" />
                        İş Danışmanı Ağı
                      </Link>
                    </Menu.Item>
                    <Menu.Item as='a'>
                      <Link to="/admin/aboutusedit">
                        <Icon name='hand peace outline' color="green" />
                        Hakkımızda
                      </Link>
                    </Menu.Item>
                    <Menu.Item as='a'>
                      <Link to="/admin/faqedit">
                        <Icon name='question circle outline' color="violet" />
                        FAQ
                      </Link>
                    </Menu.Item>
                    <Menu.Item as='a'>
                      <Link to="/admin/contactedit">
                        <Icon name='phone' color="grey" />
                        İletişim
                      </Link>
                    </Menu.Item>
                    <Menu.Item as='a'>
                      <Link to="/admin/sendnotification">
                        <Icon name='send' color="brown" />
                        Bildirim Gönder
                      </Link>
                    </Menu.Item>
                    <Menu.Item as='a'>
                      <Link to="/admin/updatebackgroundimage">
                        <Icon name='image outline' color="blue" />
                        Arka Plan Resmi
                      </Link>
                    </Menu.Item>
                  </Sidebar>
                  <Sidebar.Pusher style={{textAlign:"center", height:"97.5vh", overflowY:"auto" , backgroundImage: backgroundImage ? "url(" + backgroundImage + ")" : "url(/AdminBackgroundImage.jpg)", backgroundRepeat:"no-repeat" , backgroundSize:"100% 100%" }}>
                    <Link to="/admin"><h1 style={{color:"white" , textShadow:"0 0 2px black"}}><u>Yönetici Paneli</u></h1></Link>
                    <Route path="/admin/users" component={Users}/>  
                    <Route path="/admin/messages" component={Messages}/>   
                    <Route path="/admin/addproject" component={AddProject}/> 
                    <Route path="/admin/datatyping" component={DataTyping}/>  
                    <Route path="/admin/preapplications" component={PreApplications}/>    
                    <Route path="/admin/aboutusedit" component={AboutUsEdit}/>  
                    <Route path="/admin/faqedit" component={FAQEdit}/>   
                    <Route path="/admin/contactedit" component={ContactEdit}/> 
                    <Route path="/admin/sendnotification" component={SendNotification}/>   
                    <Route path="/admin/doneprojects" component={DoneProjects}/>     
                    <Route path="/admin/advisorsweb" component={AdvisorsWeb}/>   
                    <Route path="/admin/updatebackgroundimage" component={UpdateBackgroundImage}/>       
                  </Sidebar.Pusher>
                </Sidebar.Pushable>
              </Grid.Column>
            </Grid>
          )
}

const mapStateToProps = state => ({
    currentUser: state.user.currentUser
});
  
export default connect(mapStateToProps)(AdminPage);
