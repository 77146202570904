import React,{useState, useEffect} from  "react";
import {connect} from "react-redux";
import {useHistory} from "react-router-dom";
import firebase from "../../firebase";
import {Modal, Form, Button, Icon,  TextArea , Progress} from "semantic-ui-react";

const SendNotification = ({currentUser}) => {
    const history = useHistory();
    const [notification, setNotification] = useState("");
    const [files, setFiles] = useState([]);
    const [percent , setPercent] = useState(0);
    const [advisors, setAdvisors] = useState([]);
    const [loading, setLoading] = useState(false);
    let messageId = Math.floor(Math.random() * 1000000000);

    useEffect(()=>{
        if( currentUser.role !== "admin" ){
            history.push("/");
        }
    },[ currentUser.role, history ]);

    useEffect(() => {
        firebase.database().ref("users").orderByChild("role").equalTo("advisor").on("value", (snapshot) => {
            if(snapshot.val()){
                let snap = Object.keys(snapshot.val());
                setAdvisors(snap);
            }
        } )        
    }, []);

    const closeModal = () => {
        history.push("/admin");
    }

    const isFormEmpty = ({ notification }) => {
        return !notification ;
    }


    const isFormValid = () => {
        if(isFormEmpty({ notification })){
            alert("Gerekli Alanı Doldurduğunuza Emin misiniz?");
            return false;
        } else {
            return true;
        }
    }

    const handleSubmit = async event => {
        event.preventDefault();
        if(isFormValid()){
            setLoading(true);
            let messageID = messageId;
            if(files){
                const photo = [];
                for ( let i = 0; i< files.length; i++){
                    const file = files[i];
                    let img_ID = file.lastModified.toString() + file.size.toString();
                    let img_name = file.lastModified.toString() + file.size.toString() + "." + file.type.replace("image/" , "") ;
                    let profilPhoto = i === 0 ? true : false ;
                    const metaData = {
                        contentType : "image"
                    };
                    let percentIncrement = 100 / files.length ;
                    const percentIncrementByOneBy = percentIncrement / advisors.length ;
                    await Promise.all(advisors.map(child => {
                        return (firebase.storage().ref().child('contact/' + child + "/" + messageID + "/" + img_name ).put(file, metaData)
                        .then((snapshot)=>{
                            snapshot.ref.getDownloadURL()
                            .then((url)=>{
                                photo[img_ID] = {photo: url, key: img_ID, profilPhoto: profilPhoto , name: img_name };
                                setPercent( prevPercent => prevPercent + percentIncrementByOneBy);
                            })
                        }))
                    }))
                }
                setTimeout(() => {
                    firebase.database().ref("users/").orderByChild("role").equalTo("advisor").once("value", snap => {
                        snap.forEach(child =>{
                            child.ref.child("messages/" + messageID).set({
                                username : currentUser.displayName ,
                                email : currentUser.email , 
                                phoneNumber : "-" , 
                                message : notification ,
                                key : messageID ,
                                createdAt : new Date().toString(),
                                emailVerified : currentUser.emailVerified,
                                role : currentUser.role ,
                                read : false ,
                                photos: photo
                            })
                            .then(()=>{
                                setNotification("");
                            })
                            .catch(err =>{
                                console.log(err);
                                setLoading(false);
                                alert("Bildiriminiz iletilirken bir hata meydana geldi. Lütfen tekrar deneyiniz...");
                            });
                        })
                    });
                    setTimeout(()=>{
                        alert("Bildiriminiz Tüm İş Danışmanlarına iletilmiştir...");
                        setLoading(false);
                    },1000); 
                }, 2000);
            } else {
                firebase.database().ref("users/").orderByChild("role").equalTo("advisor").once("value", snap => {
                    snap.forEach(child =>{
                        child.ref.child("messages/" + messageID).set({
                            username : currentUser.displayName ,
                            email : currentUser.email , 
                            phoneNumber : "-" , 
                            message : notification ,
                            key : messageID ,
                            createdAt : new Date().toString(),
                            emailVerified : currentUser.emailVerified,
                            role : currentUser.role ,
                            read : false 
                        })
                        .then(()=>{
                            setNotification("");
                        })
                        .catch(err =>{
                            console.log(err);
                            setLoading(false);
                            alert("Bildiriminiz iletilirken bir hata meydana geldi. Lütfen tekrar deneyiniz...");
                        });
                    })
                });
                setTimeout(()=>{
                    alert("Bildiriminiz Tüm İş Danışmanlarına iletilmiştir...");
                    setLoading(false);
                },1000); 
            }
        }
    }

    return (
        <Modal basic open={true} onClose={closeModal}>
            <Modal.Header style={{textAlign: "center"}} >İş Danışmanlarına Bildirim Gönder</Modal.Header>
            <Modal.Content>
                <Form autocomplete="off" onSubmit={handleSubmit}>
                    <Form.Field>
                        <TextArea 
                            type="text"
                            name ="notification"
                            placeholder='Bildirim' 
                            onChange={e => setNotification(e.target.value)}
                            value = {notification}
                            style = {{height:"50vh", resize:"none", overflow:"auto"}}
                        >
                        </TextArea>  
                    </Form.Field>
                    <Form.Input 
                            fluid
                            type="file"  
                            title="Resim Yükle (PDF yollamak için mail atınız.)" 
                            multiple
                            accept="image/png, image/jpeg , image/webp" 
                            style={{marginTop:"13px"}}
                            onChange={(e)=> setFiles(e.target.files) } 
                    />
                    { percent === 0 || percent === 100 ? "" : <Progress percent={percent} indicating />}
                    { percent === 100 ? <Progress percent={100} success> <b style={{color:"white"}}>Resim Yükleme Başarılı...</b> <Icon name="check" color="green" size="large" /> </Progress> : "" }
                    { percent === 0 ? <Progress percent={percent} indicating style={{visibility: "hidden"}} /> : "" }
                </Form>
            </Modal.Content>
            <Modal.Actions>
                <Button loading={loading} disabled={percent < 100 && percent > 0 ? true : false } color="green" inverted onClick={handleSubmit}>
                    <Icon name="checkmark"/> Gönder
                </Button>
                <Button color="red" inverted onClick={closeModal}>
                    <Icon name="remove"/> Kapat
                </Button>
            </Modal.Actions>
        </Modal>
    )
}

const mapStateToProps = state => ({
    currentUser: state.user.currentUser
});
  
export default connect(mapStateToProps)(SendNotification);