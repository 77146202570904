import React, {useState, useEffect} from "react";
import {useParams, useHistory} from "react-router-dom";
import firebase from "../firebase";
import {Modal, Form, Input, Button, Icon ,Image, Header , Dropdown , Checkbox , Progress} from "semantic-ui-react";
import {connect} from "react-redux";
import DateTimePicker from 'react-datetime-picker';

const UpdateProject = ({currentUser}) => {
    const [projectMainName, setProjectMainName] = useState("");
    const [projectAuxiliaryName, setProjectAuxiliaryName] = useState("");
    const [projectSubAuxiliaryName, setProjectSubAuxiliaryName] = useState("");
    const [projectStatus, setProjectStatus] = useState([]);
    const [projectLink, setProjectLink] = useState("");
    const [validCityCountry, setValidCityCountry] = useState([]);
    const [validCityCountryValue, setValidCityCountryValue] = useState([]);
    const [projectPrice, setProjectPrice] = useState("");
    const [projectCurrency, setProjectCurrency] = useState("");
    const [projectPointCurrency, setProjectPointCurrency] = useState("");
    const [projectPoint, setProjectPoint] = useState("");
    const [projectCategory, setProjectCategory] = useState("");
    const [projectPublishTime, setProjectPublishTime] = useState("");
    const [upToDate, setUpToDate] = useState("");
    const [projectProgress, setProjectProgress] = useState("");
    const [photos, setPhotos] = useState([]);
    const [category, setCategory] = useState([]);
    const [projectKey,setProjectKey] = useState("");
    const [status, setStatus] = useState([]);
    const [projectStatusValue, setProjectStatusValue] = useState([]);
    const [projectNames, setProjectNames] = useState([]);
    const [name, setName] = useState("");
    const [countries, setCountries] = useState([]);
    const [isChecked, setIsChecked] = useState(false);
    const [currency, setCurrency] = useState([]);
    const [pointCurrency, setPointCurrency] = useState([]);
    const [projectNote, setProjectNote] = useState("");
    const [users, setUsers] = useState([]);
    const [advisor, setAdvisor] = useState("");
    const [percent , setPercent] = useState(0);
    const [files, setFiles] = useState([]);
    const [loading, setLoading] = useState(false);
    const [done, setDone] = useState(false);
    let {project_id} = useParams();
    let history = useHistory();

    useEffect(()=>{
        firebase.database().ref("projects/" + project_id).on("value", (snapshot) => {
            let snap = snapshot.val();
            const statusVal = [];
            const countryVal = [];
            if(snap){
                setName(snap.name);
                setProjectMainName(snap.projectMainName);
                setProjectAuxiliaryName(snap.projectAuxiliaryName);
                setProjectSubAuxiliaryName(snap.projectSubAuxiliaryName);
                setProjectStatus(snap.projectStatus);
                setProjectLink(snap.projectLink);
                setValidCityCountry(snap.validCityCountry);
                setProjectPrice(snap.projectPrice);
                setProjectNote(snap.projectNote);
                setAdvisor(snap.advisor);
                setDone(snap.done);
                setProjectCurrency(snap.projectCurrency);
                setProjectPointCurrency(snap.projectPointCurrency);
                setProjectPoint(snap.projectPoint);
                setProjectCategory(snap.projectCategory);
                setProjectPublishTime(new Date(snap.projectPublishTime));
                setUpToDate(snap.upToDate);
                setProjectProgress(snap.projectProgress);
                setPhotos(snap.photos);
                setProjectKey(snap.key);
                if(snap.projectStatus){
                    for (let i = 0; i < Object.values(snap.projectStatus).length; i++) {
                        statusVal[i] = Object.values(snap.projectStatus)[i].statusValue ;                    
                    }
                    setProjectStatusValue(statusVal);
                }
                if(snap.validCityCountry){
                    for (let i = 0; i < Object.values(snap.validCityCountry).length; i++) {
                        countryVal[i] = Object.values(snap.validCityCountry)[i].countryValue ;                    
                    }
                    setValidCityCountryValue(countryVal);
                }
            }
        })
    },[ project_id ]);

    useEffect(()=>{
        firebase.database().ref("/projectNames").once("value", snapshot => {
            setProjectNames(snapshot.val());
        });
    },[projectMainName, projectAuxiliaryName, projectSubAuxiliaryName]);

    useEffect(()=>{
        firebase.database().ref("/category").once("value", (snapshot)=>{
            setCategory(snapshot.val());
        });
    },[ projectCategory ]);

    useEffect(()=>{
        firebase.database().ref("/status").once("value", (snapshot)=>{
            setStatus(snapshot.val());
        });
    },[ projectStatus ]);

    useEffect(()=>{
        firebase.database().ref("/currency").once("value", (snapshot)=>{
            setCurrency(snapshot.val());
        });
    },[ projectCurrency ]);

    useEffect(()=>{
        firebase.database().ref("/pointCurrency").once("value", (snapshot)=>{
            setPointCurrency(snapshot.val());
        });
    },[ projectPointCurrency ]);

    useEffect(()=>{
        firebase.database().ref("/countries").once("value", (snapshot)=>{
            setCountries(snapshot.val());
        });
    },[ validCityCountry ]);

    useEffect(()=>{
        firebase.database().ref("/users").once("value", (snapshot)=>{
            if(snapshot.val()){
                let snap = Object.values(snapshot.val());
                setUsers(snap);
            }
        });
    },[ advisor ]);

    useEffect(()=>{
        if(isChecked && countries){
            let i;
            const countryValues = [];
            const countryValue = [];
            for(i=0; i< Object.values(countries).length; i++){
                let country_ID = Math.floor(Math.random() * 1000000000);
                countryValues[Object.values(countries)[i].value] = { countryValue: Object.values(countries)[i].value , key: country_ID };
                countryValue[i] = Object.values(countries)[i].value ;  
            }
            setValidCityCountry(countryValues);
            setValidCityCountryValue(countryValue);  
        }
    },[isChecked , countries]);

    const pictures = photos ? Object.values(photos) : null;

    useEffect(()=>{
        if(advisor !== ""){
            return currentUser.role === "admin" || currentUser.email === advisor ? null : history.push("/")
        }
    },[currentUser.role, history, currentUser.email, advisor]);

    const isFormEmpty = ({ projectMainName, projectStatusValue, projectPublishTime , validCityCountryValue, projectPrice , projectCategory, upToDate , name , projectCurrency , advisor }) => {
        return !projectMainName.length || !projectStatusValue.length || !validCityCountryValue.length || !projectPrice.length || !projectCategory.length || !upToDate.length || !name.length || !projectCurrency.length || !projectPublishTime || !advisor.length ;
    }


    const isFormValid = () => {
        if(isFormEmpty({ projectMainName, projectStatusValue, projectPublishTime , validCityCountryValue, projectPrice , projectCategory, upToDate , name , projectCurrency , advisor })){
            alert("Tüm Gerekli Bilgileri Doldurduğunuza Emin misiniz?");
            return false;
        } else {
            return true;
        }
    }

    const handleChangeStatus = (data) => {
        let statusValues = [];
        let statusValue = [];
        for(let i=0; i< data.length; i++){
            let status_ID = Math.floor(Math.random() * 1000000000);
            statusValues[data[i]] = { statusValue: data[i] , key: status_ID };
            statusValue[i] = data[i] ;  
        }
        setProjectStatus(statusValues);
        setProjectStatusValue(statusValue);        
    }

    const handleChangeCountries = (data) => {
        let countryValues = [];
        let countryValue = [];
        for(let i=0; i< data.length; i++){
            let country_ID = Math.floor(Math.random() * 1000000000);
            countryValues[data[i]] = { countryValue: data[i] , key: country_ID };
            countryValue[i] = data[i] ;  
        }
        setValidCityCountry(countryValues);
        setValidCityCountryValue(countryValue);        
    }

    const handleSubmit = async event => {
        event.preventDefault();
        if(isFormValid()){
            let pStatus = {};
            let vcCountry = {};
            for (let i = 0; i < projectStatusValue.length; i++) {
                pStatus[projectStatusValue[i]] = projectStatus[projectStatusValue[i]]
            }
            for (let i = 0; i < validCityCountryValue.length; i++) {
                vcCountry[validCityCountryValue[i]] = validCityCountry[validCityCountryValue[i]]
            }
            let time = projectPublishTime.getTime();
            let offset = projectPublishTime.getTimezoneOffset();
            offset = offset * 60000;
            offset = offset + 10800000;
            let seconds = time - offset;
            setLoading(true);
            if(files){
                let photo = [];
                for( let i = 0; i< files.length; i++){
                    const file = files[i];
                    let img_ID = file.lastModified.toString() + file.size.toString() ;
                    let img_name = file.lastModified.toString() + file.size.toString() + "." + file.type.replace("image/" , "") ;
                    console.log(file);
                    const metaData = {
                        contentType : "image"
                    };
                    const percentIncrement = 100 / files.length ;
                    await firebase.storage().ref().child('projects/' + projectKey + "/" + img_name ).put(file, metaData)
                    .then((snapshot)=>{
                        snapshot.ref.getDownloadURL()
                        .then((url)=>{
                            photo[img_ID] = {photo: url,  profilPhoto: false , key: img_ID , name: img_name };
                            setPercent( prevPercent => prevPercent + percentIncrement);
                        })
                    })
                }
                setTimeout(() => {
                    firebase.database().ref("projects/" + projectKey).update({
                        projectAuxiliaryName ,
                        name ,
                        projectCategory ,
                        projectLink ,
                        projectMainName ,
                        projectPrice ,
                        projectCurrency ,
                        projectPointCurrency ,
                        projectPoint ,
                        projectNote ,
                        advisor ,
                        projectProgress ,
                        projectStatus : pStatus ,
                        projectSubAuxiliaryName ,
                        upToDate ,
                        done ,
                        validCityCountry : vcCountry,
                        projectPublishTime : seconds ,
                        updatedAt: new Date().toString() ,
                        seen : false
                    })
                    .then(()=>{
                        Object.values(photo).forEach(child => {
                            firebase.database().ref("projects/" + projectKey + "/photos/" + child.key ).set({
                                photo: child.photo,
                                profilPhoto: child.profilPhoto,
                                key: child.key ,
                                name : child.name
                            })
                        });
                        setTimeout(() => {
                            closeModal();
                            alert("Proje Güncellendi...");
                        }, 1000);
                    });  
                }, 2000);
            } else {
                firebase.database().ref("projects/" + projectKey).update({
                    projectAuxiliaryName ,
                    name ,
                    projectCategory ,
                    projectLink ,
                    projectMainName ,
                    projectPrice ,
                    projectCurrency ,
                    projectPointCurrency ,
                    projectPoint ,
                    projectNote ,
                    advisor ,
                    projectProgress ,
                    projectStatus : pStatus ,
                    projectSubAuxiliaryName ,
                    upToDate ,
                    done ,
                    validCityCountry : vcCountry ,
                    projectPublishTime : seconds ,
                    updatedAt: new Date().toString() ,
                    seen :false
                })
                .then(()=>{
                    setTimeout(() => {
                        closeModal();
                        alert("Proje Güncellendi...");
                    }, 1000);
                })
            }       
        }
    }

    const closeModal = () => {
        history.goBack();
    }

    const handleDeletePhoto = photo => {
        if (window.confirm('Resmi Silmek İstediğinize Emin misiniz?')){
            firebase.storage().ref().child('projects/' + projectKey + "/" + photo.name).delete();
            firebase.database().ref("projects/" + projectKey + "/photos/" + photo.key).remove();
        }
    }

    const handleMainPicture = photoKey => {
        if (window.confirm('Ana Resim Yapmak İstediğinize Emin misiniz?')){
            firebase.database().ref("projects/" + projectKey + "/photos/").once("value", (snapshot) => {
                snapshot.forEach(child => {
                    child.ref.update({
                        profilPhoto: false
                    })
                })
            })
            .then(()=>{
                firebase.database().ref("projects/" + projectKey + "/photos/" + photoKey).update({
                    profilPhoto: true
                });
            });
        }
    }

    const handleDelete = () => {
        if (window.confirm('Projeyi Silmek İstediğinize Emin misiniz?')){
            pictures && pictures.map(child => {
                return firebase.storage().ref().child('projects/' + projectKey + "/" + child.name).delete()
            });
            firebase.database().ref("projects/" + projectKey).remove()
            .then(()=>{
                closeModal();
                setTimeout(() => {
                    alert("Proje Silindi...")
                }, 1000);
            });
        }
    }

    useEffect(()=>{
        document.getElementById('textarea').addEventListener('keydown', function(e) {
            if (e.key === 'Tab') {
              e.preventDefault();
              var start = this.selectionStart;
              var end = this.selectionEnd;
              this.value = this.value.substring(0, start) + "\t" + this.value.substring(end);
              this.selectionStart = this.selectionEnd = start + 1;
            }
          });
    },[]);

    const advisors = users ? users.filter(user => user.role === "advisor") : [] ;
    const categories = projectMainName && projectNames && Object.values(projectNames).filter(projectNames=> projectNames.projectMainName === projectMainName)[0] ? Object.values(Object.values(projectNames).filter(projectNames=> projectNames.projectMainName === projectMainName)[0].categories) : "" ;
    const subCategories = projectMainName && projectNames && projectAuxiliaryName && Object.values(projectNames).filter(projectNames=> projectNames.projectMainName === projectMainName)[0] && Object.values(Object.values(projectNames).filter(projectNames=> projectNames.projectMainName === projectMainName)[0].categories).filter(child => child.projectAuxiliaryName === projectAuxiliaryName)[0] ? Object.values(Object.values(Object.values(projectNames).filter(projectNames=> projectNames.projectMainName === projectMainName)[0].categories).filter(child => child.projectAuxiliaryName === projectAuxiliaryName)[0].subCategories) : "" ;  

    return (
        <Modal basic open={true} onClose={closeModal}>
                <Modal.Header style={{textAlign: "center"}} >Projeyi Düzenle</Modal.Header>
                <Modal.Content>
                    <Form autocomplete="off" >
                        <Form.Field>
                            <select disabled={currentUser.role === "admin" ? false : true} value={projectMainName} onChange={e => setProjectMainName(e.target.value)} name="projectMainName">
                                <option value="" >Proje Ana Adı</option>
                                {projectNames ? Object.values(projectNames).map((child)=>{
                                    return (<option key={child.key} value={child.projectMainName}>{child.projectMainName}</option>)
                                }) : ""}
                            </select>
                        </Form.Field>
                        <Form.Field>
                            <select disabled={currentUser.role === "admin" ? false : true} value={projectAuxiliaryName} onChange={e => setProjectAuxiliaryName(e.target.value)} name="projectAuxiliaryName">
                                <option value="" >Proje Yardımcı Adı</option>
                                {projectNames && projectMainName && Object.values(projectNames).filter(projectNames=> projectNames.projectMainName === projectMainName)[0] ? categories.map((child)=>{
                                    return (<option key={child.key} value={child.projectAuxiliaryName}>{child.projectAuxiliaryName}</option>)
                                }) : ""}
                            </select>
                        </Form.Field>
                        <Form.Field>
                            <select disabled={currentUser.role === "admin" ? false : true} value={projectSubAuxiliaryName} onChange={e => setProjectSubAuxiliaryName(e.target.value)} name="projectSubAuxiliaryName">
                                <option value="" >Proje Alt Yardımcı Adı</option>
                                {projectNames && projectMainName && projectAuxiliaryName && Object.values(projectNames).filter(projectNames=> projectNames.projectMainName === projectMainName)[0] && Object.values(Object.values(projectNames).filter(projectNames=> projectNames.projectMainName === projectMainName)[0].categories).filter(child => child.projectAuxiliaryName === projectAuxiliaryName)[0] ? subCategories.map((child)=>{
                                    return (<option key={child.key} value={child.projectSubAuxiliaryName}>{child.projectSubAuxiliaryName}</option>)
                                }) : ""}
                            </select>
                        </Form.Field>
                        <Form.Field>
                            <Input
                                fluid
                                type="text"
                                disabled={currentUser.role === "admin" ? false : true}
                                label = "Proje Adı"
                                name = "name"
                                value = {name}
                                onChange={e => setName(e.target.value)}
                            />
                        </Form.Field>
                        <Form.Field>
                            <select disabled={currentUser.role === "admin" ? false : true} value={projectCategory} onChange={e => setProjectCategory(e.target.value)} name="projectCategory">
                                <option value="" >Kategori Seçiniz</option>
                                {category ? Object.values(category).map((child)=>{
                                    return (<option key={child.key} value={child.value}>{child.value}</option>)
                                }) : ""}
                            </select>
                        </Form.Field>
                        <Form.Field>
                            <Dropdown 
                                placeholder='Proje Statüsü' 
                                fluid 
                                multiple 
                                selection 
                                disabled={currentUser.role === "admin" ? false : true}
                                value={projectStatusValue}
                                options={ status ? Object.values(status) : null} 
                                onChange={ (event,data)=> handleChangeStatus(data.value) }
                            />
                        </Form.Field>
                        <Form.Field>
                            <Input
                                fluid
                                type="text"
                                disabled={currentUser.role === "admin" ? false : true}
                                label = "Link Ekle"
                                name = "projectLink"
                                value = {projectLink}
                                onChange={e => setProjectLink(e.target.value)}
                            />
                        </Form.Field>
                        <Form.Field>
                            <Input
                                fluid
                                type="text"
                                label = "Proje Notu"
                                name = "projectNote"
                                value = {projectNote}
                                onChange={e => setProjectNote(e.target.value)}
                            />
                        </Form.Field>
                        <Form.Field>
                            <select disabled={currentUser.role === "admin" ? false : true} value={advisor} onChange={e => setAdvisor(e.target.value)} name="advisor">
                                <option value="" >İş Danışmanı Seçiniz</option>
                                <option value="Belirsiz" >Belirsiz</option>
                                {advisors.map((child)=>{
                                    return (<option key={child.key} value={child.email}>{child.email}</option>)
                                })}
                            </select>
                        </Form.Field>
                        <Form.Field>
                            <Dropdown 
                                placeholder='Projenin Geçerli Olduğu Ülkeler' 
                                fluid 
                                multiple 
                                selection 
                                disabled={currentUser.role === "admin" ? false : true}
                                value={validCityCountryValue}
                                options={ countries ? Object.values(countries) : null} 
                                onChange={ (event,data)=> handleChangeCountries(data.value) }
                            />
                            <Checkbox
                                label="Tüm Ülkeleri Seç"
                                toggle
                                disabled={currentUser.role === "admin" ? false : true}
                                checked={isChecked} 
                                onChange={()=> setIsChecked(!isChecked)}
                            />
                        </Form.Field>
                        <Form.Field>
                            <Input
                                fluid
                                type="number"
                                disabled={currentUser.role === "admin" ? false : true}
                                label = "Proje Bedeli"
                                name = "projectPrice"
                                value = {projectPrice}
                                onChange={e => setProjectPrice(e.target.value)}
                            />
                        </Form.Field>
                        <Form.Field>
                            <select disabled={currentUser.role === "admin" ? false : true} value={projectCurrency} onChange={e => setProjectCurrency(e.target.value)} name="projectCurrency">
                                <option value="" >Para Birimi</option>
                                {currency ? Object.values(currency).map((child)=>{
                                    return (<option key={child.key} value={child.value}>{child.value}</option>)
                                }) : ""}
                            </select>
                        </Form.Field>
                        <Form.Field>
                            <Input
                                fluid
                                type="number"
                                disabled={currentUser.role === "admin" ? false : true}
                                label = "Proje Puanı"
                                name = "projectPoint"
                                value = {projectPoint}
                                onChange={e => setProjectPoint(e.target.value)}
                            />
                        </Form.Field>
                        <Form.Field>
                            <select disabled={currentUser.role === "admin" ? false : true} value={projectPointCurrency} onChange={e => setProjectPointCurrency(e.target.value)} name="projectPointCurrency">
                                <option value="" >Puan Birimi</option>
                                {pointCurrency ? Object.values(pointCurrency).map((child)=>{
                                    return (<option key={child.key} value={child.value}>{child.value}</option>)
                                }) : ""}
                            </select>
                        </Form.Field>
                        <Form.Field>
                            <textarea 
                                id="textarea"
                                type="text"
                                name ="projectProgress"
                                placeholder='Gelişmeler...' 
                                onChange={e => setProjectProgress(e.target.value)}
                                value = {projectProgress}
                                style = {{height:"30vh", resize:"none", overflow:"auto"}}
                            >
                            </textarea> 
                        </Form.Field>
                        <Form.Field>
                            <select disabled value={upToDate} onChange={e => setUpToDate(e.target.value)} name="upToDate" >
                                <option value="Güncel" >Güncel</option>
                                <option value="Güncel Değil" >Güncel Değil</option>
                            </select>
                        </Form.Field>
                        {currentUser.role === "admin" ? <Form.Field>                        
                            <DateTimePicker
                                className="timepicker"
                                onChange={ setProjectPublishTime }
                                value={projectPublishTime}
                                name = "projectPublishTime"
                                autoFocus = {false}
                                showLeadingZeros = {false}                                    
                                closeWidgets = {false}
                                dayPlaceholder = "dd"                                    
                                format = "dd-MM-y / h:mm:ss a"
                                hourPlaceholder = "hh"                                    
                                minDetail = "decade"
                                minutePlaceholder = "mm"
                                monthPlaceholder = "mm"
                                secondPlaceholder = "ss"                                    
                                yearPlaceholder = "yyyy"
                                clearIcon = {<Icon name="repeat" color="red" />}
                                calendarIcon = {<Icon name="calendar alternate" color="olive"/>}
                            />    
                            <p style={{color:"aqua"}}>Proje Yayınlanma Tarihi. (Gün-Ay-Yıl/Saat:Dakika:Saniye AM/PM)</p>                        
                        </Form.Field> : ""}
                        <Form.Field>
                            <Checkbox
                                label={done ? 'Proje Tamamlandı...' : 'Proje Tamamlanmadı...' }
                                disabled={currentUser.role === "admin" ? false : true}
                                checked={done}
                                onChange={()=> setDone(!done) }
                                className="doneCheckbox"
                            />
                        </Form.Field>
                        <Form.Field>
                            <Input 
                                fluid
                                type="file"
                                disabled={currentUser.role === "admin" ? false : true}
                                multiple
                                accept="image/png, image/jpeg , image/webp" 
                                label="Resim Ekle" 
                                onChange={(e)=> setFiles(e.target.files) } 
                            />
                            <br/>
                            { percent === 0 || percent === 100 ? "" : <Progress percent={percent} indicating />}
                            { percent === 100 ? <Progress percent={100} success> <b style={{color:"white"}}>Resim Yükleme Başarılı...</b> <Icon name="check" color="green" size="large" /> </Progress> : "" }
                            { percent === 0 ? <Progress percent={percent} indicating style={{visibility: "hidden"}} /> : "" }
                            {pictures ? <Header style={{color:"white"}} textAlign="center">Mevcut Resimler</Header> : <Header style={{color:"white"}} textAlign="center">Lütfen Resim Yükleyiniz...</Header>}
                            <Image.Group >
                            { pictures ? pictures.map((child, i) => {
                                return (
                                    <div style={{width:"32%",position:"relative", float:"left" ,paddingTop:"32%",marginRight:"1%", marginBottom:"1%"}}>
                                    {currentUser.role === "admin" ? <Button onClick={() => handleDeletePhoto(child)} icon="trash alternate" color="red" size="mini" style={{position:"absolute",top:"2px",right:"2px",zIndex:10000000}}/> : ""}
                                    {child.profilPhoto || currentUser.role !== "admin" ? null : <Button onClick={() => handleMainPicture(child.key)} color="green" size="mini" icon style={{position:"absolute",top:"2px",left:"10px",zIndex:10000000}}><Icon name="image outline"/></Button>}
                                    <Image style={{position:"absolute", top:0,height:"100%",width:"100%",borderRadius:"10px",boxShadow:"0 0 3px teal"}} src={child.photo} alt="projectPhoto" key={i}></Image>
                                    </div>
                                )
                            }) : null}
                            </Image.Group>
                        </Form.Field>
                    </Form>
                </Modal.Content>
                <Modal.Actions >
                    <Button color="green" loading={loading} disabled={percent < 100 && percent > 0 ? true : false } inverted onClick={handleSubmit}>
                        <Icon name="checkmark"/> Güncelle
                    </Button>
                    {currentUser.role === "admin" ? <Button color="orange" disabled={ loading } inverted onClick={handleDelete}>
                        <Icon name="trash"/> Sil
                    </Button> : ""}
                    <Button color="red" inverted onClick={closeModal}>
                        <Icon name="remove"/> Kapat
                    </Button>
                </Modal.Actions>
            </Modal>
    )
}

const mapStateToProps = state => ({
    currentUser: state.user.currentUser
});
  
export default connect(mapStateToProps)(UpdateProject);