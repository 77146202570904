import React from "react";

const Map = () => {
    return (
        <a target="_blank" rel="noreferrer" href="https://www.google.com.tr/maps/place/Sanayi,+60018.+Cd.+No:111,+27110+%C5%9Eehitkamil%2FGaziantep/@37.0718711,37.4342551,18z/data=!4m5!3m4!1s0x1531e5c8bce95245:0x4ea49ae628fd902f!8m2!3d37.07187!4d37.4353494">
            <img alt="map" src="iemttoMap.jpg" width="100%" />
        </a>
    )
}

export default Map;