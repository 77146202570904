import React , {useState , useEffect} from "react";
import firebase from "../../firebase";
import { Icon, Table, Header ,Grid , Image} from 'semantic-ui-react';
import {useHistory, useParams , Route} from "react-router-dom";
import {connect} from "react-redux";
import MessageImages from "./MessageImages";

const db = firebase.database();


const AdvisorMessages = ({currentUser}) => {
    const [messages, setMessages] = useState([]);
    const [roles, setRoles] = useState([]);
    const history = useHistory();
    let {user_id} = useParams();

    useEffect(()=>{
        setTimeout(() => {
            db.ref("users/" + user_id + "/messages").orderByChild("role").equalTo("advisor").once("value" , (snapshot) => {
                snapshot.forEach(child => {
                    child.ref.update({
                        read : true
                    })
                })
            });
            db.ref("users/" + user_id + "/messages").orderByChild("role").equalTo("advisorCandidate").once("value" , (snapshot) => {
                snapshot.forEach(child => {
                    child.ref.update({
                        read : true
                    })
                })
            });
            db.ref("users/" + user_id + "/messages").orderByChild("role").equalTo("entrepreneur").once("value" , (snapshot) => {
                snapshot.forEach(child => {
                    child.ref.update({
                        read : true
                    })
                })
            });
        }, 10000);
    },[user_id]);

    useEffect(()=>{
        db.ref("users/" + user_id + "/messages").on("value" , (snapshot) => {
            if(snapshot.val()){
                let snap = Object.values( snapshot.val() );
                let message = snap.filter(child => child.role !== "admin");
                setMessages(message);
            }
        })
    },[user_id]);

    useEffect(() => {
        db.ref("roles").on("value", (snapshot) => {
            if(snapshot.val()){
                let snap = Object.values( snapshot.val() );
                setRoles(snap);
            }
        })
    },[]);

    useEffect(()=>{
        return currentUser.uid !== user_id ? history.push("/") : null
    });


    const handleDelete = (message) => {
        if (window.confirm( message.username + ' Kişisinin Mesajını Silmek İstediğinize Emin misiniz?')){
            message.photos && Object.values(message.photos).map(child => {
                return firebase.storage().ref().child("contact/" + user_id + "/" + message.key + "/" + child.name).delete();
            })

            firebase.database().ref('users/'+ user_id + "/messages/" + message.key).remove()
            .then(()=>{
                console.log("Message Deleted...");
                history.push("/user/"+ user_id );
                history.push("/user/"+ user_id +"/messages");
            })
        }
    }

        return (
            <Grid textAlign="center" style={{marginTop:"20px"}}>
                <Grid.Column computer={16} tablet={16} mobile={16} style={{overflow:"auto"}}>
                    <Table  celled >
                        <Table.Header>
                        <Table.Row style={{textAlign:"center"}}>
                            <Table.HeaderCell></Table.HeaderCell>
                            <Table.HeaderCell>Kullanıcı</Table.HeaderCell>
                            <Table.HeaderCell>Tarih</Table.HeaderCell>
                            <Table.HeaderCell>Email <br/>Adresi</Table.HeaderCell>
                            <Table.HeaderCell>İletişim <br/>Adresi</Table.HeaderCell>
                            <Table.HeaderCell>Email <br/>Doğrulanma</Table.HeaderCell>
                            <Table.HeaderCell>Mesaj</Table.HeaderCell>
                            <Table.HeaderCell>Resim</Table.HeaderCell>
                        </Table.Row>
                        </Table.Header>
                        <Table.Body>
                        { messages.sort((b,a)=>{return a.createdAt.localeCompare(b.createdAt)}).map(message => {
                            return (
                                <Table.Row key={message.key} style={{textAlign:"center", background: message.read ? "white" : "salmon"}}>
                                    <Table.Cell>
                                        <Icon name="trash alternate" color="red" style={{fontSize:"25px",cursor:"pointer"}} onClick={()=>handleDelete(message)} />
                                    </Table.Cell>
                                    <Table.Cell>
                                        <Header as='h4'>
                                            <Header.Content>
                                                {message.username}
                                                <Header.Subheader> { roles && roles.filter(child => child.role === message.role )[0] ? roles.filter(child => child.role === message.role )[0].displayName : ""}</Header.Subheader>
                                            </Header.Content>
                                        </Header>
                                    </Table.Cell>
                                    <Table.Cell>{new Date(message.createdAt).toLocaleDateString()} {new Date(message.createdAt).toLocaleTimeString()}</Table.Cell>
                                    <Table.Cell>{message.email}</Table.Cell>
                                    <Table.Cell>{message.phoneNumber}</Table.Cell>
                                    <Table.Cell style={{textAlign:"center"}} >{message.emailVerified ? <Icon name='check' color="green"/> : <Icon name='close' color="red"/>}</Table.Cell>
                                    <Table.Cell className="messageUser" >{message.message}</Table.Cell>
                                    <Table.Cell >
                                        { message.photos ? <Image 
                                                src={Object.values(message.photos).filter(child => child.profilPhoto === true)[0].photo}
                                                rounded 
                                                size='mini'
                                                style={{cursor:"pointer"}}
                                                onClick={() => history.push("/user/" + user_id + "/messages/" + message.key + "/photos" )}
                                            /> : "" }
                                    </Table.Cell>
                                    <Route path={"/user/" + user_id + "/messages/" + message.key + "/photos"} children={<MessageImages photos={message.photos} back={"/user/" + user_id + "/messages/"} />}/>
                                </Table.Row>
                            )
                        }) }
                        </Table.Body>
                        <Table.Footer fullWidth>
                            <Table.Row>
                                <Table.HeaderCell colSpan='8' style={{textAlign:"center", color:"maroon", fontWeight:"bolder"}}>
                                    {messages.length + " Mesaj Alındı..."}                            
                                </Table.HeaderCell>
                            </Table.Row>
                        </Table.Footer>
                    </Table>
                </Grid.Column>
            </Grid>
        );    
}

const mapStateToProps = state => ({
    currentUser: state.user.currentUser
});
  
export default connect(mapStateToProps)(AdvisorMessages);

