import React from  "react";
import Spinner from "./Spinner";
import {useHistory , useLocation} from "react-router-dom";
import {Button} from "semantic-ui-react";
import {connect} from "react-redux";
import md5 from "md5";
import Carousel from 'react-elastic-carousel';

const Slider = ({projects, currentUser, width, height, maxWidth}) => {
    const history = useHistory();
    const emptyImage = "http://gravatar.com/avatar/" + md5(currentUser.email) + "?d=identicon" ;
    const location = useLocation();

    if(!projects){
        return <Spinner/>
    }

    const breakPoints = [
        { width: 550, itemsToShow: 2, itemsToScroll: 2 },
        { width: 700, itemsToShow: 4, itemsToScroll: 2 }
    ];

    return (
        <Carousel
            breakPoints={breakPoints}
            disableArrowsOnEnd={true}
            pagination={false}
            autoTabIndexVisibleItems={true}
            enableAutoPlay={true}
            autoPlaySpeed={3000}
            showArrows={projects.length > 4 ? true : false}
        >
            {projects.map(project => {
                let profilPhoto = project.photos ? Object.values(project.photos).filter(photo => photo.profilPhoto === true) : [{photo: emptyImage}];
                return (
                    <div key={project.key}>
                        <figure style={{width:"100%", padding:0, margin: "1em 4px"}}>
                            <img
                                src={ profilPhoto[0] ? profilPhoto[0].photo : emptyImage}
                                alt={project.key}
                                style={{cursor:"pointer", width:width, height:height, maxWidth:maxWidth}}
                                onClick={()=> history.push("/project/" + project.key)}
                            />
                            <figcaption style={{textAlign:"center", background: project.seen || location.pathname === "/" ? null : "salmon" }}><b>{project.name}</b> { currentUser.role === "admin" || currentUser.email === project.advisor ? <Button icon="edit" size="mini" onClick={()=> history.push("/project/" + project.key + "/update") } style={{background:"green",color:"white"}}/> : null}</figcaption>
                        </figure>
                    </div>
                )
            })}
        </Carousel>
    );
}

const mapStateToProps = state => ({
    currentUser: state.user.currentUser
});
  
export default connect(mapStateToProps)(Slider);